// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from 'app/store';
import 'app/style/index.scss';
import 'app/aspHooks';
import 'focus-visible/dist/focus-visible.min';
import { createWebComponent } from 'app/utils/web-component';
import Routes from 'app/routes';
import { AuthProvider } from 'app/context/auth-context';
import { EventProvider } from 'app/context/event-context';
import { SubscriptionEventProvider } from 'app/context/subscription-event-context';
import { FeaturesProvider } from 'app/context/features-context';
import { SPA_ERROR } from 'app/utils/error';
import { ACTION_TYPES } from 'app/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: error => {
      if (error.name === SPA_ERROR) {
        store.dispatch({
          type: ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_GENERATE,
          data: error,
        });
      }
    },
  }),
});

const renderWebComponent = () => {
  const App = () => (
    <Provider store={store}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <FeaturesProvider>
            <EventProvider>
              <SubscriptionEventProvider>
                <Routes />
                <ReactQueryDevtools />
              </SubscriptionEventProvider>
            </EventProvider>
          </FeaturesProvider>
        </QueryClientProvider>
      </AuthProvider>
    </Provider>
  );
  createWebComponent('room-app', App);
};

export const render = () => {
  renderWebComponent();
};

render();
