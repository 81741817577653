// @flow

import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  actionButtons?: React.Node,
};

export class ShellActionHeader extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.wrapper = document.createElement('span');
    this.wrapper.id = 'actionMenuButtons';
    this.wrapper.classList.add('dr-spa');
    this.wrapper.classList.add('dr-spa-header');
    this.headerContainer = document.querySelector('#header .ace-header-navigation');
  }

  componentDidMount() {
    if (this.headerContainer) {
      this.headerContainer.insertBefore(this.wrapper, this.headerContainer.firstChild);
    }
  }

  componentWillUnmount() {
    if (this.headerContainer) {
      this.headerContainer.removeChild(this.wrapper);
    }
  }

  headerContainer: ?HTMLElement;

  wrapper: HTMLSpanElement;

  render() {
    const { actionButtons } = this.props;

    return createPortal(actionButtons, this.wrapper);
  }
}

export default ShellActionHeader;
