// @flow

import { Button, Link } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'app/components/typography/Typography';
import RoomLayout from 'app/layout/roomLayout';
import {
  fetchSetting,
  saveSetting,
  disableQnaNotification,
  enableQnaNotification,
  showDocumentSelector,
  changeDocumentSetting,
  enableDocumentNotification,
  disableDocumentNotification,
} from './notificationActions';
import DocumentNotification from './documentNotification/DocumentNotification';
import QnaNotification from './qnaNotification/QnaNotification';
import type { OptionalIntervalSetting, NotificationState } from './notificationTypes';
import styles from './notificationPage.scss';

type StateProps = NotificationState & {
  indexLink: string,
};
type NotificationPageType = {
  dispatch: AppDispatch,
} & StateProps;
class NotificationPage extends React.Component<NotificationPageType> {
  componentDidMount() {
    this.props.dispatch(fetchSetting());
  }

  onShowSelector = () => {
    this.props.dispatch(showDocumentSelector());
  };

  onDocumentSettingChange = (setting: OptionalIntervalSetting) => {
    this.props.dispatch(changeDocumentSetting(setting));
  };

  onEnableDocumentNotification = () => {
    this.props.dispatch(enableDocumentNotification());
  };

  onDisableDocumentNotification = () => {
    this.props.dispatch(disableDocumentNotification());
  };

  onEnableQnaNotification = () => {
    this.props.dispatch(enableQnaNotification());
  };

  onDisableQnaNotification = () => {
    this.props.dispatch(disableQnaNotification());
  };

  onSaveSetting = () => {
    const { dispatch, documentSetting, qnaSetting } = this.props;
    dispatch(
      saveSetting({
        documentSetting,
        qnaSetting,
      }),
    );
  };

  render() {
    const { hasChanged, viewOnly, documentSetting, qnaSetting, indexLink, isLoaded } = this.props;
    return (
      <RoomLayout
        actionButtons={<div />}
        title={translate('Notifications')}
        data-test-id="notificationPage"
        loadingContent={!isLoaded}
      >
        <div className={styles.page}>
          <PageTitle>{translate('Notifications')}</PageTitle>
          <DocumentNotification
            {...documentSetting}
            viewOnly={viewOnly}
            onShowSelector={this.onShowSelector}
            onChange={this.onDocumentSettingChange}
            onEnabled={this.onEnableDocumentNotification}
            onDisabled={this.onDisableDocumentNotification}
          />
          {qnaSetting && (
            <QnaNotification
              status={qnaSetting.status}
              onEnabled={this.onEnableQnaNotification}
              onDisabled={this.onDisableQnaNotification}
            />
          )}
          {hasChanged && (
            <div className={styles.footer}>
              <Link className={styles.actionButton} variant="DefaultButton" href={indexLink}>
                {translate('Cancel')}
              </Link>
              <Button
                variant="Primary"
                data-test-id="saveNotificationButton"
                onClick={this.onSaveSetting}
              >
                {translate('Save')}
              </Button>
            </div>
          )}
        </div>
      </RoomLayout>
    );
  }
}

const mapState = (appState: AppStateType): Object => {
  const { dataRoomProjectName, dataroomId, userId } = appState.session;
  const indexLink = `/_mvc/${dataRoomProjectName}|${dataroomId}/${userId}/DocumentIndex#/DocumentIndex`;
  return {
    ...appState.notification,
    indexLink,
  };
};

export default connect<AppStateType, AppDispatch, *, StateProps>(mapState)(NotificationPage);
