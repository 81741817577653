// @flow

import React from 'react';
import { HelpLink, Dialog } from '@ansarada/ace-react';
import TranslationService from 'app/i18n/translate';
import styles from './styles.scss';
import Image1 from './images/img-1.png';
import Image2 from './images/img-2.png';
import Image3 from './images/img-3.png';

type RegistrationToolDialogType = {
  setShowToolDialogVisible: boolean => void,
};
const SelfRegistrationToolDialog = ({ setShowToolDialogVisible }: RegistrationToolDialogType) => {
  const closeDialog = () => setShowToolDialogVisible(false);
  return (
    <Dialog
      closeAltText="close"
      disableDefaultClose={false}
      onClose={closeDialog}
      size="Large"
      title={TranslationService('SelfRegistrationToolIntroDialogHeading')}
    >
      <div className={styles.imageRow}>
        <div className={styles.imageBlock}>
          <img src={Image1} alt="" />
          <b>{TranslationService('SelfRegistrationToolIntroDialogTitle1')}</b>
        </div>
        <div className={styles.imageBlock}>
          <img src={Image2} alt="" />
          <b>{TranslationService('SelfRegistrationToolIntroDialogTitle2')}</b>
        </div>
        <div className={styles.imageBlock}>
          <img src={Image3} alt="" />
          <b>{TranslationService('SelfRegistrationToolIntroDialogTitle3')}</b>
        </div>
      </div>
      <div className={styles.infoRow}>
        <HelpLink
          target="_blank"
          href="https://help.ansarada.com/en/articles/5171593-guest-registration"
          text={TranslationService('SelfRegistrationToolIntroDialogHelpLink')}
        />
      </div>
    </Dialog>
  );
};

export default SelfRegistrationToolDialog;
