import type { ThirdPartyIntegrations } from 'app/types/teamsTypes';

export const getThirdPartyIntegrationsAllowedState = (
  thirdPartyIntegrationsDataRoom: ThirdPartyIntegrations[],
  thirdPartyIntegrations?: ThirdPartyIntegrations,
): 'all' | 'none' | 'partial' | 'hidden' => {
  if (!thirdPartyIntegrations) return null;

  const allowedThirdPartyIntegrationsDataRoomNumber = thirdPartyIntegrationsDataRoom.filter(
    item => item.isAllowed,
  ).length;

  if (allowedThirdPartyIntegrationsDataRoomNumber === 0) return 'hidden';

  const allowedThirdPartyIntegrationsNumber = thirdPartyIntegrations.filter(item => item.isAllowed)
    .length;

  if (thirdPartyIntegrations.length === 0 || allowedThirdPartyIntegrationsNumber === 0) {
    return 'none';
  }

  if (allowedThirdPartyIntegrationsNumber === thirdPartyIntegrations.length) {
    return 'all';
  }

  return 'partial';
};
