// @flow

import AccessToggle from 'app/pages/security/accessToggle/AccessToggle';
import MessageBox from 'app/components/messageBox/MessageBox';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import { manageQnaSubjects } from 'app/uri/url';
import * as React from 'react';
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Table,
  WindowScroller,
} from 'react-virtualized';
import { Icon, icon } from '@ansarada/ace-react';
import classnames from 'classnames';
import { ACTION_TYPES, DIMENSIONS } from 'app/constants';
import translate from 'app/i18n/translate';
import type { SubjectOrGroup } from './subjectSecurityPageTypes';
import styles from './subjectSecurityView.scss';

export const Row = (
  props: $Exact<{
    className: string,
    rowData: SubjectOrGroup,
  }>,
) => {
  const { className, rowData } = props;

  return defaultTableRowRenderer({
    ...props,
    key: rowData.id,
    className: classnames(
      className,
      'ace-tree-group',
      `ace-tree-level-${rowData.isGroup ? 0 : 1}`,
      `ace-tree-branch-${rowData.expanded ? 'expanded' : 'collapsed'}`,
    ),
  });
};

export const SubjectColumn = (
  props: $Exact<{
    dispatch: AppDispatch,
    subject: SubjectOrGroup,
  }>,
) => {
  const { dispatch, subject } = props;

  const className = classnames('ace-tree-item', styles.subjectColumn, {
    [styles.subjectColumnGroup]: subject.isGroup,
    [styles.disabled]: subject.disabled,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-test-id="subjectCell"
      className={className}
      onClick={() => {
        if (!subject.isGroup) return;

        dispatch({
          type: ACTION_TYPES.SECURITY.SUBJECTS.SET_GROUP_EXPANDED,
          data: {
            id: subject.id,
            expanded: !subject.expanded,
          },
        });
      }}
    >
      {subject.isGroup && (
        <Icon
          glyph={subject.expanded ? icon.Glyphs.ControlExpanded : icon.Glyphs.ControlCollapsed}
          className={styles.subjectIcon}
        />
      )}

      {subject.disabled && (
        <Icon
          text={translate('Disabled')}
          glyph={icon.Glyphs.StatusDisabled}
          className={styles.subjectIcon}
        />
      )}

      <Icon
        glyph={subject.isGroup ? icon.Glyphs.AddSubjectGroup : icon.Glyphs.AddSubject}
        className={styles.subjectIcon}
      />

      <span data-test-id="subjectName" data-ansarada-ccd>
        {subject.name || translate('SubjectGroupNotSet')}
      </span>
    </div>
  );
};

export const SubjectSecurityViewTable = (
  props: $Exact<{
    dispatch: AppDispatch,
    height: number,
    onScroll: () => void,
    scrollTop: number,
    subjects: Array<SubjectOrGroup>,
    width: number,
    canManageQnaSecurity: boolean,
  }>,
) => {
  const { dispatch, height, onScroll, scrollTop, subjects, width, canManageQnaSecurity } = props;

  const COLUMN_STYLES = {
    SUBJECT: {
      width: 200,
      flexGrow: 1,
    },
    ACCESS: {
      width: 130,
      flexShrink: 0,
    },
  };

  const headerItems = [
    {
      style: COLUMN_STYLES.SUBJECT,
      textKey: 'Subject',
    },
    {
      style: COLUMN_STYLES.ACCESS,
      textKey: 'Access',
    },
  ];

  return (
    <React.Fragment>
      <div className={styles.headerRow} style={{ width }}>
        {headerItems.map(item => (
          <div
            key={item.textKey}
            className={styles.headerCell}
            style={item.style}
            data-test-id="securityTableHeader"
          >
            {translate(item.textKey)}
          </div>
        ))}
      </div>

      <Table
        autoHeight
        disableHeader
        gridClassName="ace-tree-grid"
        height={height}
        onScroll={onScroll}
        overscanRowCount={15}
        rowCount={subjects.length}
        rowGetter={({ index }) => subjects[index]}
        rowHeight={DIMENSIONS.TABLE_ROW_HEIGHT}
        rowRenderer={Row}
        scrollTop={scrollTop}
        width={width}
      >
        <Column
          dataKey="subject"
          {...COLUMN_STYLES.SUBJECT}
          cellRenderer={cell => <SubjectColumn subject={cell.rowData} dispatch={dispatch} />}
        />
        <Column
          dataKey="allowAccess"
          {...COLUMN_STYLES.ACCESS}
          className={styles.toggle}
          cellRenderer={cell => {
            const subject = cell.rowData;
            return (
              <AccessToggle
                id={subject.id}
                isAllowed={subject.allowAccess}
                disabled={!canManageQnaSecurity}
                data-test-id="subjectAccess"
                onToggle={() => {
                  dispatch({
                    type: ACTION_TYPES.SECURITY.SUBJECTS.SET_ACCESS,
                    data: {
                      id: subject.id,
                      isGroup: subject.isGroup,
                      allowAccess: !subject.allowAccess,
                    },
                  });
                }}
              />
            );
          }}
        />
      </Table>
    </React.Fragment>
  );
};

type SubjectSecurityViewProps = $Exact<{
  canManageQnaSecurity: boolean,
  dispatch: AppDispatch,
  session: SessionType,
  loaded: boolean,
  subjects: Array<SubjectOrGroup>,
}>;

export class SubjectSecurityView extends React.PureComponent<SubjectSecurityViewProps> {
  componentDidMount() {
    this.props.dispatch({ type: ACTION_TYPES.SECURITY.SUBJECTS.LOAD });
  }

  render() {
    const { dispatch, subjects, canManageQnaSecurity, session, loaded } = this.props;

    if (!loaded) return null;

    if (subjects.length) {
      return (
        <WindowScroller>
          {({ height, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <SubjectSecurityViewTable
                  dispatch={dispatch}
                  height={height}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  subjects={subjects}
                  width={width}
                  canManageQnaSecurity={canManageQnaSecurity}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      );
    }
    if (canManageQnaSecurity) {
      return (
        <MessageBox
          buttonText={translate('ManageQnaSubjects')}
          buttonAction={() => {
            document.location.href = manageQnaSubjects(session);
          }}
        >
          {translate('NoQuestionSubjects')}
        </MessageBox>
      );
    }
    return null;
  }
}
