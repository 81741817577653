// @flow

import { STATUS } from 'app/pages/notification/constants';
import type { STATUS_TYPE } from 'app/pages/notification/notificationTypes';
import styles from 'app/pages/notification/notificationPage.scss';
import React from 'react';
import { Label, Toggle } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';

type IntervalSelectorType = {
  status: STATUS_TYPE,
  onEnabled: () => void,
  onDisabled: () => void,
};

const QnaNotification = (props: IntervalSelectorType) => {
  const { status, onEnabled, onDisabled } = props;
  return (
    <div>
      <h3 className={styles.sectionTitle}>{translate('Qna')}</h3>
      <div className={styles.bg}>
        <div className={styles.row}>
          <Label className={styles.label} forEl="qnaNotificationOnOff">
            {translate('EmailNotifications')}
          </Label>
          <Toggle
            id="qnaNotificationOnOff"
            data-test-id="qnaNotificationToggle"
            checked={status === STATUS.ON}
            onChecked={checked => {
              if (checked) {
                onEnabled();
              } else {
                onDisabled();
              }
            }}
          />
          <span className={styles.desc}>
            {translate(
              status === STATUS.ON ? 'QnaNotificationSwitchOn' : 'QnaNotificationSwitchOff',
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default QnaNotification;
