// @flow
import React from 'react';
import {
  Autocomplete,
  AutocompleteItem,
  FormGrid,
  FormGridCell,
  FormGridRow,
  TextInput,
} from '@ansarada/ace-react';
import type { AutocompleteGroupType } from '@ansarada/ace-react/dist/ace/components/Autocomplete';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import { connect } from 'react-redux';
import { ACTION_TYPES } from 'app/constants';
import type { JobTitleGroupType } from 'app/layout/selfRegistration/selfRegistrationTypes';
import { LABELS } from 'app/layout/selfRegistration/validationSchema';
import { OTHER_JOB_TITLE_ID } from 'app/utils/constants';

const createJobTitleAutoCompleteItems = (jobTitleGroups: JobTitleGroupType[]) => {
  return jobTitleGroups.reduce((autoCompGroup: AutocompleteGroupType, group: JobTitleGroupType) => {
    return {
      ...autoCompGroup,
      [group.jobTitleGroupName]: group.jobTitles.map(
        jobTitle => new AutocompleteItem(String(jobTitle.jobTitleId), jobTitle.jobTitleName),
      ),
    };
  }, {});
};

export type PropsType = {
  errors: any,
  setFieldValue: (fieldName: string, value: any) => void,
  isShowError: (fieldName: string) => boolean,
  dispatch: AppDispatch,
  jobTitleGroups: JobTitleGroupType[],
  onTextBlur: (field: string, e: any) => void,
  values: any,
  renderAs?: 'formGroup' | 'control',
};

export class JobTitleInput extends React.Component<PropsType> {
  componentDidMount() {
    this.props.dispatch({
      type: ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.FETCH,
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.RESET,
    });
  }

  onChangeValue = (value: string) => {
    this.props.setFieldValue('jobTitle', value);
  };

  onSelected = (item: any) => {
    this.props.setFieldValue('selectedJobTitle', {
      jobTitleName: item.label,
      jobTitleId: Number(item.value),
    });
  };

  render() {
    const {
      jobTitleGroups,
      isShowError,
      setFieldValue,
      errors,
      onTextBlur,
      values,
      renderAs = 'formGroup',
    } = this.props;

    const jobTitleId = values.selectedJobTitle?.jobTitleId;
    const { profession, jobTitleOther } = values;
    const autoCompleteGroup = jobTitleGroups ? createJobTitleAutoCompleteItems(jobTitleGroups) : [];
    const component = (
      <Autocomplete
        key="jobTitle"
        data-test-id="job-title"
        items={autoCompleteGroup}
        onChangeItems={() => {}}
        filter="contains"
        status={isShowError('jobTitleId') ? 'Bad' : 'Good'}
        onChangeValue={this.onChangeValue}
        value={values.jobTitle}
        onSelected={this.onSelected}
        data-ansarada-ccd
      />
    );

    const rows =
      renderAs === 'formGroup'
        ? [
            <FormGridRow key="jobTitle">
              <FormGridCell
                labelText={LABELS.jobTitle}
                errors={isShowError('jobTitle') ? errors.jobTitle : ''}
                errorPosition="Bottom"
              >
                {component}
              </FormGridCell>
            </FormGridRow>,
          ]
        : [component];

    if (jobTitleId === OTHER_JOB_TITLE_ID) {
      rows.push(
        <FormGridRow key="profession">
          <FormGridCell
            labelText={LABELS.profession}
            errors={isShowError('profession') ? errors.profession : ''}
            errorPosition="Bottom"
          >
            <TextInput
              data-test-id="profession"
              type="Text"
              defaultValue={profession}
              status={isShowError('profession') ? 'Bad' : 'Good'}
              onChangeValue={value => setFieldValue('profession', value)}
              onBlur={e => onTextBlur('profession', e)}
              data-ansarada-ccd
            />
          </FormGridCell>
        </FormGridRow>,
      );

      rows.push(
        <FormGridRow key="jobTitleOther">
          <FormGridCell
            labelText={LABELS.jobTitle}
            errors={isShowError('jobTitleOther') ? errors.jobTitleOther : ''}
            errorPosition="Bottom"
          >
            <TextInput
              data-test-id="job-title-other"
              type="Text"
              defaultValue={jobTitleOther}
              status={isShowError('jobTitleOther') ? 'Bad' : 'Good'}
              onChangeValue={value => setFieldValue('jobTitleOther', value)}
              onBlur={e => onTextBlur('jobTitleOther', e)}
              data-ansarada-ccd
            />
          </FormGridCell>
        </FormGridRow>,
      );
    }

    // $FlowFixMe Temporary unstrict types
    return <FormGrid>{rows}</FormGrid>;
  }
}

const mapStateToProps = (state: AppStateType) => ({
  jobTitleGroups: state.selfRegistration.jobTitleGroups,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(JobTitleInput);
