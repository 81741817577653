// @flow

import { PageTitle } from 'app/components/typography/Typography';
import { ACTION_TYPES, PAGE_TITLES } from 'app/constants';
import translate from 'app/i18n/translate';
import { links } from 'app/links';
import type { TeamHistoryState } from 'app/pages/teamHistory/teamHistoryTypes';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { RouterHistory } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from '@ansarada/ace-react';
import RoomLayout from 'app/layout/roomLayout';
import HistoryTable from './historyTable/HistoryTable';

type TeamHistoryPageType = {
  history: RouterHistory,
  dispatch: AppDispatch,
  session: SessionType,
  match: Object,
} & TeamHistoryState;

class TeamHistoryPage extends React.Component<TeamHistoryPageType> {
  componentDidMount() {
    const { dispatch, match } = this.props;
    const id = parseInt(match.params.id, 10);

    dispatch({
      type: ACTION_TYPES.TEAM_HISTORY.DATA_FETCH_INITIAL,
      data: { id },
    });
  }

  render() {
    const { history, session, subTeam, teamHistory } = this.props;
    let breadcrumbItems = [];

    let fullTeamName = '';

    if (subTeam) {
      const { isGuest, isPrimary } = subTeam;
      if (isPrimary) {
        fullTeamName = subTeam.teamName || '';
      } else {
        fullTeamName = `${subTeam.name} (${subTeam.teamName || ''})`;
      }
      const side = isGuest
        ? session.translationTagNameNonAdminSide
        : session.translationTagNameAdminSide;
      breadcrumbItems = [
        {
          href: '', // TODO HOME-105: Remove this with the next version of ace-react
          text: '',
        },
        {
          text: translate(side),
          onClick: () => history.push(links.teams(isGuest)),
        },
        {
          href: '#',
          text: translate('TeamHistory'),
        },
      ];
    }

    return (
      <RoomLayout actionButtons title={PAGE_TITLES.TEAM_HISTORY} data-test-id="teamHistoryPage">
        <Breadcrumb items={breadcrumbItems} />
        <PageTitle data-ansarada-ccd withSpacing>{`${translate(
          'TeamHistoryFor',
        )} ${fullTeamName}`}</PageTitle>
        <HistoryTable
          teamHistory={teamHistory}
          isPrimarySubteam={(subTeam || {}).isPrimary}
          qnaEnabled={session.qnaEnabled}
          dataRoomType={session.dataRoomType}
        />
      </RoomLayout>
    );
  }
}

const mapStateToProps = (state: AppStateType) => ({
  session: state.session,
  subTeam: state.teamHistory.subTeam,
  teamHistory: state.teamHistory.teamHistory,
});

export default withRouter(connect<AppStateType, _, *, *>(mapStateToProps)(TeamHistoryPage));
