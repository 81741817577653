// @flow

import { ACTION_TYPES } from 'app/constants';
import store from 'app/store';
import { window } from 'app/config';

// Triggered when watermark creation on ASP is successful
window.handleWatermarkCreateSuccess = () => {
  store.dispatch({ type: ACTION_TYPES.SECURITY.WATERMARKS_FETCH });
};

// Triggered when user invite on ASP is completed
window.handleUserUpdateSuccess = () => {
  store.dispatch({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
};
