// @flow

import translate from 'app/i18n/translate';
import * as React from 'react';
import { Tooltip, Button } from '@ansarada/ace-react';
import styles from './TextCopy.scss';

type TextCopyPropType = {
  triggerText: string,
  text: string,
};

type StateType = {
  open: boolean,
};

const DEFAULT_TIMEOUT = 2000;

class TextCopy extends React.Component<TextCopyPropType, StateType> {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onCopy = () => {
    if (this.textArea && this.props.text) {
      this.textArea.select();
      document.execCommand('copy');
      if (this.triggerButton) {
        this.triggerButton.focus();
      }
      this.showTips();
    }
  };

  showTips() {
    this.setState({ open: true });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        open: false,
      });
    }, DEFAULT_TIMEOUT);
  }

  textArea: ?HTMLTextAreaElement;

  triggerButton: ?HTMLButtonElement;

  timer: TimeoutID;

  render() {
    const { triggerText, text } = this.props;
    return (
      <Tooltip
        id="dr-send-invitation-text-copy"
        open={this.state.open}
        text={translate('Copied')}
        position="Top"
        onTrigger={() => {}}
      >
        <>
          <textarea
            className={styles.invisible}
            ref={textArea => {
              this.textArea = textArea;
            }}
            tabIndex={-1}
            value={text}
            readOnly
          />
          <Button
            variant="Link"
            disabled={!text}
            buttonRef={ref => {
              this.triggerButton = ref;
            }}
            onClick={this.onCopy}
          >
            {triggerText}
          </Button>
        </>
      </Tooltip>
    );
  }
}

export default TextCopy;
