// @flow

import { links } from 'app/links';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as React from 'react';
import { Button, DropdownAction, DropdownGroup, Icon, icon } from '@ansarada/ace-react';
import Dropdown from 'app/components/dropdown/Dropdown';
import * as teamsActions from 'app/pages/teams/teamsActions';
import translate from 'app/i18n/translate';
import { ACTION_TYPES, USER_TYPE_CODES } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import type { RegistrationSettingsType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import TeamSendInvitePersonDropdownAction from './components/TeamSendInvitePersonDropdownAction';

type Props = {
  dispatch: AppDispatch,
  permissions: PermissionsType,
  isGuest: boolean,
  teamsFiltersActive: boolean,
  history: RouterHistory,
  hasPeople: boolean,
  registrationSettings: RegistrationSettingsType,
  session: SessionType,
  isPaidCustomer: boolean,
  isEmailVerified: boolean,
};

class TeamActions extends React.Component<Props> {
  onCreateTeam = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTION_TYPES.DIALOG_TOGGLE, data: translate('CreateTeam') });
    dispatch(teamsActions.createTeam());
  };

  onToggleFilter = () => {
    this.props.dispatch({
      type: ACTION_TYPES.TEAMS.FILTER_TOGGLE,
      isGuest: this.props.isGuest,
    });
  };

  onExpandAll = () => {
    this.props.dispatch({ type: ACTION_TYPES.TEAMS.EXPAND_ALL });
  };

  onCollapseAll = () => {
    this.props.dispatch({
      type: ACTION_TYPES.TEAMS.COLLAPSE_ALL,
    });
  };

  onBatchEdit = () => {
    const { isGuest, history } = this.props;
    history.push(links.editPeople(isGuest));
  };

  onSendInvitation = () => {
    const { isGuest, history } = this.props;

    history.push(links.sendInvitation({ isGuest }));
  };

  onEmailPeople = () => {
    const { history, isGuest } = this.props;

    history.push(links.emailPeople({ isGuest }));
  };

  onExportExcel = () => {
    this.props.dispatch({ type: ACTION_TYPES.TEAMS.EXPORT_USERS });
  };

  onToggleRegistrations = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG,
      data: true,
    });
  };

  render() {
    const {
      permissions,
      teamsFiltersActive,
      hasPeople,
      isGuest,
      session,
      registrationSettings,
      isPaidCustomer,
      isEmailVerified,
      history,
    } = this.props;

    if (!permissions) return null;

    return (
      <React.Fragment>
        {permissions.user.modify && (
          <Button
            data-test-id="drCreateTeamBtn"
            key="create-team-button"
            variant="Ghost"
            className="button-legacy-support button-collapsible-legacy-support"
            icon={<Icon glyph={icon.Glyphs.AddGeneric} size="Medium" />}
            onClick={this.onCreateTeam}
          >
            {translate('CreateTeam')}
          </Button>
        )}
        <Button
          key="nav-filter-button"
          data-test-id="navFilterButton"
          variant="Ghost"
          pressed={teamsFiltersActive}
          className="button-legacy-support button-collapsible-legacy-support"
          onClick={this.onToggleFilter}
          icon={<Icon glyph={icon.Glyphs.ControlFilter} size="Medium" />}
        >
          {translate('Filter')}
        </Button>
        <Dropdown
          trigger={
            <Button
              data-test-id="drTeamsActionsBtn"
              variant="Ghost"
              icon={<Icon glyph={icon.Glyphs.ControlMenu} size="Medium" />}
            >
              {translate('Actions')}
            </Button>
          }
        >
          <DropdownGroup>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ActionExpandAll} />}
              onClick={this.onExpandAll}
              data-test-id="expandAllButton"
            >
              {translate('ExpandAll_SentenceCase')}
            </DropdownAction>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ActionCollapseAll} />}
              onClick={this.onCollapseAll}
              data-test-id="collapseAllButton"
            >
              {translate('CollapseAll_SentenceCase')}
            </DropdownAction>
          </DropdownGroup>

          {permissions.user.modify && (
            <DropdownGroup>
              <DropdownAction
                icon={<Icon glyph={icon.Glyphs.ActionEdit} />}
                onClick={this.onBatchEdit}
                data-test-id="editPeopleButton"
                disabled={!hasPeople}
              >
                {translate('EditPeople')}
              </DropdownAction>

              <TeamSendInvitePersonDropdownAction
                history={history}
                isGuest={isGuest}
                isEmailVerified={isEmailVerified}
                hasPeople={hasPeople}
              />

              {isPaidCustomer && (
                <DropdownAction
                  icon={<Icon glyph={icon.Glyphs.ActionBulkEmail} />}
                  onClick={this.onEmailPeople}
                  data-test-id="emailPeopleButton"
                >
                  {translate('EmailPeople')}
                </DropdownAction>
              )}
            </DropdownGroup>
          )}
          {isGuest && session.userTypeCode === USER_TYPE_CODES.ADM && (
            <DropdownGroup>
              <DropdownAction
                icon={<Icon glyph={icon.Glyphs.ObjectsBuyside} />}
                onClick={this.onToggleRegistrations}
                data-test-id="toggleRegistrationsButton"
              >
                {registrationSettings.IsEnabled
                  ? translate('DisableRegistrations_SentenceCase')
                  : translate('EnableRegistrations_SentenceCase')}
              </DropdownAction>
            </DropdownGroup>
          )}
          <DropdownGroup>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.DocumentExcel} />}
              onClick={this.onExportExcel}
              data-test-id="exportExcelButton"
            >
              {translate('ExportToExcel_SentenceCase')}
            </DropdownAction>
          </DropdownGroup>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(TeamActions);
