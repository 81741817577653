// @flow

import { ACTION_TYPES } from 'app/constants';
import { validatePeopleForm } from './peopleFormDialogHelpers';

export const normalizeEmails = (emails: string): Array<string> =>
  emails
    .replace(/[,|;\s]+/g, ',')
    .split(',')
    .filter(e => e !== '');

export const generateEmailArray = (rawEmails: string) => ({
  type: ACTION_TYPES.TEAMS.PEOPLE_FORM_CHANGE,
  data: {
    key: 'emails',
    value: rawEmails ? normalizeEmails(rawEmails) : [],
  },
});

export const toSavePeopleForm = (data: Object) => {
  const errors = validatePeopleForm(data);
  return errors
    ? {
        type: ACTION_TYPES.TEAMS.PEOPLE_FORM_ERRORS,
        data: errors,
      }
    : {
        type: ACTION_TYPES.TEAMS.PEOPLE_SAVE,
        data,
      };
};

export const changeValue = ({ key, value }: { key: string, value: any }) => ({
  type: ACTION_TYPES.TEAMS.PEOPLE_FORM_CHANGE,
  data: {
    key,
    value,
  },
});
