"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_query_1 = require("@tanstack/react-query");
var manageRegistration_1 = require("app/services/manageRegistration");
var selfRegistration_1 = require("app/services/selfRegistration");
// @ts-ignore
var selfRegistrationSagas_1 = require("app/layout/selfRegistration/selfRegistrationSagas");
var transform_1 = require("app/utils/transform");
var useAccessRequest = function (_a) {
    var session = _a.session;
    var _b = (0, react_1.useState)(), selectedAccessRequest = _b[0], setSelectedAccessRequest = _b[1];
    var _c = (0, react_1.useState)(false), detailsDialogOpen = _c[0], setDetailsDialogOpen = _c[1];
    var _d = (0, react_1.useState)(false), newRequestDialogOpen = _d[0], setNewRequestDialogOpen = _d[1];
    var _e = (0, react_1.useState)(false), deleteRequestDialogOpen = _e[0], setDeleteRequestDialogOpen = _e[1];
    var queryClient = (0, react_query_1.useQueryClient)();
    var registrantsQuery = (0, react_query_1.useQuery)(['registrants'], function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, manageRegistration_1.fetchRegistrantRequestList)(session)];
    }); }); }, {
        retry: false,
    });
    var createAccessRequestMutation = (0, react_query_1.useMutation)(function (data) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, selfRegistration_1.submitGuestRegistration)(session, data)];
    }); }); }, {
        onSuccess: function () {
            setNewRequestDialogOpen(false);
            fetchAccessRequests();
        },
    });
    var deleteAccessRequestMutation = (0, react_query_1.useMutation)(function (registrantId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, manageRegistration_1.deleteRegistrationRequest)(session, registrantId)];
    }); }); }, {
        onSuccess: function () {
            setDeleteRequestDialogOpen(false);
            fetchAccessRequests();
        },
    });
    var fetchAccessRequests = function () {
        queryClient.invalidateQueries(['registrants']);
    };
    var submitAccessRequest = function (data) { return createAccessRequestMutation.mutate(data); };
    var deleteAccessRequest = function (requestAccessId) {
        return deleteAccessRequestMutation.mutate(requestAccessId);
    };
    var setDeleteDialogVisible = function (visible) {
        setDeleteRequestDialogOpen(visible);
    };
    var viewDetails = function (accessRequest) {
        setDetailsDialogOpen(true);
        setSelectedAccessRequest(accessRequest);
    };
    var handleDelete = function (accessRequest) {
        setDeleteDialogVisible(true);
        setSelectedAccessRequest(accessRequest);
    };
    var onDeleteRequestAccess = function () {
        if (selectedAccessRequest) {
            deleteAccessRequest(selectedAccessRequest.id);
        }
    };
    var openNewAccessRequestDialog = function () {
        setNewRequestDialogOpen(true);
    };
    var closeNewAccessRequestDialog = function () {
        setNewRequestDialogOpen(false);
    };
    var closeDeleteRequestDialog = function () {
        setDeleteDialogVisible(false);
    };
    var closeDetailsDialog = function () {
        setDetailsDialogOpen(false);
    };
    var onDeclineRequestAccess = function () {
        setDetailsDialogOpen(false);
        setDeleteDialogVisible(true);
    };
    (0, react_1.useEffect)(function () {
        fetchAccessRequests();
        return function () {
            queryClient.cancelQueries({ queryKey: ['registrants'] });
        };
    }, []);
    var requestErrors = (0, react_1.useMemo)(function () {
        var submitAccessRequestError = createAccessRequestMutation.error;
        if (!(submitAccessRequestError === null || submitAccessRequestError === void 0 ? void 0 : submitAccessRequestError.details)) {
            return null;
        }
        return (0, selfRegistrationSagas_1.formatRegistrationErrors)(submitAccessRequestError.details);
    }, [createAccessRequestMutation.error]);
    var accessRequests = (0, react_1.useMemo)(function () {
        if (!registrantsQuery.data) {
            return [];
        }
        return (0, transform_1.objectToCamelCase)(registrantsQuery.data).sort(function (a, b) { return new Date(b.insertDateUtc).valueOf() - new Date(a.insertDateUtc).valueOf(); });
    }, [registrantsQuery.data]);
    return {
        fetchAccessRequests: fetchAccessRequests,
        submitAccessRequest: submitAccessRequest,
        deleteAccessRequest: deleteAccessRequest,
        onDeleteRequestAccess: onDeleteRequestAccess,
        handleDelete: handleDelete,
        viewDetails: viewDetails,
        setSelectedAccessRequest: setSelectedAccessRequest,
        openNewAccessRequestDialog: openNewAccessRequestDialog,
        closeNewAccessRequestDialog: closeNewAccessRequestDialog,
        closeDeleteRequestDialog: closeDeleteRequestDialog,
        closeDetailsDialog: closeDetailsDialog,
        onDeclineRequestAccess: onDeclineRequestAccess,
        requestErrors: requestErrors,
        selectedAccessRequest: selectedAccessRequest,
        newRequestDialogOpen: newRequestDialogOpen,
        detailsDialogOpen: detailsDialogOpen,
        deleteRequestDialogOpen: deleteRequestDialogOpen,
        accessRequests: accessRequests,
        loading: registrantsQuery.isLoading,
        submitting: createAccessRequestMutation.isLoading,
        loadingDeleteRequest: deleteAccessRequestMutation.isLoading,
    };
};
exports.default = useAccessRequest;
