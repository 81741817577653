// @flow

import * as React from 'react';
import { Message, Button } from '@ansarada/ace-react';
import styles from 'app/components/messageBox/messageBox.scss';

type MessageBoxPropsType = {
  children: React.Node,
  buttonText: string,
  buttonAction: () => void,
  'data-test-id'?: string,
};

const MessageBox = (props: MessageBoxPropsType) => (
  <Message data-test-id={props['data-test-id']}>
    <div className={styles.messageTextGroup}>{props.children}</div>

    <div className={styles.messageButtonGroup}>
      <Button onClick={props.buttonAction} className="button-legacy-support">
        {props.buttonText}
      </Button>
    </div>
  </Message>
);

export default MessageBox;
