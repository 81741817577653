"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_dom_1 = require("react-dom");
var PLATFORM_HEADER_SELECTOR = '#platformHeader';
var SHELL_BANNER_HEADER_ID = 'shellBannerHeader';
function getShellBannerHeader() {
    return document.getElementById(SHELL_BANNER_HEADER_ID);
}
function ShellBannerHeader(props) {
    var children = props.children;
    var _a = (0, react_1.useState)(getShellBannerHeader()), shellBannerHeaderContainer = _a[0], setShellBannerHeaderContainer = _a[1];
    (0, react_1.useEffect)(function () {
        var platformHeader = window.document.querySelector(PLATFORM_HEADER_SELECTOR);
        if (platformHeader && !shellBannerHeaderContainer) {
            var wrapper = document.createElement('div');
            wrapper.id = 'shellBannerHeader';
            wrapper.classList.add('shell-banner-header');
            platformHeader.insertAdjacentElement('afterend', wrapper);
            setShellBannerHeaderContainer(wrapper);
        }
        // eslint-disable-next-line consistent-return
        return function () {
            if (shellBannerHeaderContainer) {
                shellBannerHeaderContainer.remove();
            }
        };
    }, [shellBannerHeaderContainer]);
    if (!shellBannerHeaderContainer) {
        return null;
    }
    return (0, react_dom_1.createPortal)(children, shellBannerHeaderContainer);
}
exports.default = ShellBannerHeader;
