// @flow

import * as React from 'react';
import { Button, LoadingIndicator } from '@ansarada/ace-react';
import styles from './fileSelectButton.scss';

type Props = {
  onFilesSelected: (Array<File>) => any,
  text: string,
  uploading: boolean,
};

class FileSelectButton extends React.Component<Props> {
  onClick = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    if (this.inputEl) {
      this.inputEl.value = ''; // Clear any currently selected files
      this.inputEl.click();
    }
  };

  onFilesSelected = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { onFilesSelected } = this.props;
    // The value returned from the event is a FileList which is not a true array.
    // Convert to a conventional array so it's easier to work with.
    onFilesSelected([...event.target.files]);
  };

  inputEl: ?HTMLInputElement;

  render() {
    const { text, uploading } = this.props;

    if (uploading) {
      return (
        <div className={styles.uploading}>
          <LoadingIndicator dark /> Uploading file
        </div>
      );
    }

    const inputRef = el => {
      this.inputEl = el;
    };
    return (
      <div className={styles.link}>
        <input
          id="file-input"
          ref={inputRef}
          type="file"
          onChange={this.onFilesSelected}
          className={styles.input}
        />
        <Button variant="Link" className={styles.button} onClick={this.onClick}>
          {text}
        </Button>
      </div>
    );
  }
}

export default FileSelectButton;
