// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import translate from 'app/i18n/translate';
import Dialog from 'app/components/dialog/Dialog';
import PeopleForm from 'app/pages/teams/peopleFormDialog/peopleForm/PeopleForm';
import * as actions from 'app/pages/teams/peopleFormDialog/peopleFormDialogActions';
import { PEOPLE_DIALOG_TYPES } from 'app/constants';
import VerifyEmailBanner from 'app/pages/teams/verifyEmailBanner/VerifyEmailBanner';

type PeopleFormDialogPropsType = {
  content: Object,
  isGuest: boolean,
  dispatch: AppDispatch,
  hasLicences: boolean,
  isEmailVerified: boolean,
};

export const PeopleFormDialog = (props: PeopleFormDialogPropsType) => {
  const { content, dispatch, isGuest, hasLicences, isEmailVerified } = props;

  let addText = content.data.sendEmailInvitations
    ? translate('AddAndInvitePeople')
    : translate('Add');

  const editText = content.data.sendEmailInvitations
    ? translate('SaveAndInvitePeople')
    : translate('Save');

  if (!isGuest && content.data.sendEmailInvitations && hasLicences) {
    addText = translate('SendInviteToSubscription');
  }

  const isAdding = content.data.type === PEOPLE_DIALOG_TYPES.CREATE;
  const shouldShowVerifyEmailBanner =
    !isEmailVerified && (isAdding || content.data.showInvitationOnEdit);

  return (
    <Dialog
      sensitiveContent
      data-test-id="peopleFormDialog"
      triggerButton={{
        children: isAdding ? addText : editText,
        variant: 'Primary',
        onClick: () => dispatch(actions.toSavePeopleForm(content.data)),
      }}
    >
      {shouldShowVerifyEmailBanner && <VerifyEmailBanner />}
      <PeopleForm isAdding={isAdding} hasLicences={hasLicences} isEmailVerified={isEmailVerified} />
    </Dialog>
  );
};

const mapStateToProps = (state: Object): Object => ({
  content: state.teams.viewData.peopleForm,
  isGuest: state.teams.props.isGuest,
  hasLicences: state.page.licences && state.page.licences.allowed,
  isEmailVerified: state.verifyEmail.isEmailVerified,
});

export default connect<_, _, *, *>(mapStateToProps)(PeopleFormDialog);
