// @flow

import { indexBy, prop } from 'ramda';
import translate from 'app/i18n/translate';
import type { QnaRole } from 'app/types/globalTypes';

export const getUserTypeLabel = (code: string, userTypes: Array<*>): string => {
  const userTypesByCode = indexBy(prop('userTypeCode'), userTypes);
  const matchingType = userTypesByCode[code];
  return matchingType ? matchingType.userType : translate('None');
};

export const getQnaRoleLabel = (code: string, qnaRoles: Array<QnaRole>): string => {
  const qnaRolesByCode = indexBy(prop('qnaRoleCode'), qnaRoles);
  const matchingRole = qnaRolesByCode[code];
  return matchingRole ? matchingRole.qnaRoleName : translate('None');
};

export const getTermsOfAccessLabel = (
  accessLevel: 0 | 1 | 2,
  termsOfAccessOnChange: boolean,
): string => {
  if (accessLevel === 0) {
    return translate('Never');
  }

  if (accessLevel === 1) {
    return translate('OnEveryLogin');
  }

  if (accessLevel === 2 && termsOfAccessOnChange) {
    return translate('OnceOnlyAndWhenTermsAreUpdated');
  }

  if (accessLevel === 2 && !termsOfAccessOnChange) {
    return translate('OnfirstLogin');
  }

  return '';
};
