// @flow

import * as React from 'react';
import { distanceInWordsToNow } from 'date-fns';
import { Icon, icon } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { Team, Teams } from 'app/types/teamsTypes';
import { makeTeamsItems } from 'app/pages/teams/teamsHelpers';

import type { PeopleWithRole, PersonWithRole } from 'app/components/emailForm/EmailFormTypes';

export const columnsMap = {
  email: {
    title: translate('Email'),
    renderCell: (person: PersonWithRole) => <span data-ansarada-ccd>{person.email}</span>,
  },
  roomRole: {
    title: translate('RoomRole_SentenceCase'),
    renderCell: (person: PersonWithRole) => person.userType,
  },
  qnaRole: {
    title: <span data-test-id="qnaRole">{translate('QnaRole_SentenceCase')}</span>,
    renderCell: (person: PersonWithRole) => <span data-test-id="qnaRole">{person.qnaRole}</span>,
  },
  dateSent: {
    title: translate('DateSent'),
    renderCell: (person: PersonWithRole) =>
      person.lastUserInvitationSentDate && distanceInWordsToNow(person.lastUserInvitationSentDate),
  },
  verified: {
    title: translate('Verified'),
    renderCell: (person: PersonWithRole) => (
      <Icon glyph={person.isVerified ? icon.Glyphs.StatusYes : icon.Glyphs.StatusNo} />
    ),
  },
  loggedInToDate: {
    title: translate('LoggedInToDate_SentenceCase'),
    renderCell: (person: PersonWithRole) => (
      <Icon glyph={person.userLoggedInToDate ? icon.Glyphs.StatusYes : icon.Glyphs.StatusNo} />
    ),
  },
  subteams: {
    title: translate('SubTeam'),
    renderCell: (person: PersonWithRole) => <span data-test-id="subteams">{person.subteam}</span>,
  },
  disabled: {
    title: translate('Disabled'),
    renderCell: (person: PersonWithRole) =>
      person.disabled && <Icon text="disabled" glyph={icon.Glyphs.StatusDisabled} />,
  },
};

export type ColumnKeyType = $Keys<typeof columnsMap>;

export const getPeopleInTeam = (
  people: PeopleWithRole,
  team: Team,
  teams: Teams,
): PeopleWithRole => {
  const subTeams = makeTeamsItems(teams).filter(item => item.data.key === 'subteamId');
  return people
    .filter((person: PersonWithRole) => person.teamId === team.teamId)
    .map(person => {
      const inSubteam = subTeams.find(subteam => subteam.subteamId === person.subteamId);
      return {
        ...person,
        subteam: inSubteam ? inSubteam.name : '',
      };
    });
};
