// @flow

import Link from 'app/components/aceReactRouterLink/AceReactRouterLink';
import { links } from 'app/links';
import * as R from 'ramda';
import { Breadcrumb, Button } from '@ansarada/ace-react';
import PeopleSelector from 'app/components/peopleSelector/PeopleSelector';
import { PageTitle } from 'app/components/typography/Typography';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import RoomLayout from 'app/layout/roomLayout';
import {
  addTypeOnPerson,
  getQnaTypeMap,
  getUserTypeMap,
} from 'app/pages/sendInvitation/sendInvitationHelper';
import type { PeopleWithRole, QueryType } from 'app/components/emailForm/EmailFormTypes';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import { getLocationProps } from 'app/uri/url';
import * as React from 'react';
import { connect } from 'react-redux';
import type { RouterHistory } from 'react-router-dom';
import { buildPayloadData, validateForm } from './editPeopleHelper';
import PropertyForm from './propertyForm/PropertyForm';
import type { EditPeopleState } from './editPeopleTypes';
import styles from './editPeoplePage.scss';

type OwnProps = {
  location: Object,
};

type PropsType = {
  dispatch: AppDispatch,
  session: SessionType,
  people: PeopleWithRole,
  userTypes: Array<*>,
  locationProps: QueryType,
  history: RouterHistory,
  teams: Array<*>,
  remoteIp: string,
  isAllowEnableGuestAccess: boolean,
} & EditPeopleState;

const hasError = (errors: Object) => Object.keys(errors).length > 0;

class EditPeoplePage extends React.Component<PropsType> {
  componentDidMount() {
    const { dispatch, locationProps } = this.props;
    dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.DATA_FETCH_INITIAL,
      data: {
        isGuest: locationProps.isGuest === '1',
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.RESET,
    });
  }

  componentDidUpdate() {
    if (this.props.isSubmitting && hasError(this.props.errors)) {
      const firstErrorFieldName = Object.keys(this.props.errors)[0];
      if (['roomRole', 'team'].includes(firstErrorFieldName)) {
        const errorElement = document.querySelector(
          `[data-test-id="${firstErrorFieldName}Field"] select`,
        );
        if (errorElement && errorElement.focus) {
          errorElement.focus();
        }
      } else if (firstErrorFieldName === 'ipAccessList') {
        const errorElement = document.querySelector('[data-test-id="ipAccessListField"] textarea');
        if (errorElement && errorElement.focus) {
          errorElement.focus();
        }
      } else if (firstErrorFieldName === 'properties') {
        const errorElement = document.querySelector('[data-test-id="propertiesError"]');
        if (errorElement && errorElement.scrollIntoView) {
          errorElement.scrollIntoView();
        }
      } else if (firstErrorFieldName === 'selectedPeopleIds') {
        const errorElement = document.querySelector('[data-test-id="selectedPeopleError"]');
        if (errorElement && errorElement.scrollIntoView) {
          errorElement.scrollIntoView();
        }
      }
      this.props.dispatch({ type: ACTION_TYPES.EDIT_PEOPLE.SET_IS_SUBMITTING, data: false });
    }
  }

  onRoomRoleChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_ROOM_ROLE,
      data,
    });
  };

  onQnaRoleChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_QNA_ROLE,
      data,
    });
  };

  onSubTeamChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_SUB_TEAM,
      data,
    });
  };

  onActivityReportChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_ACTIVITY_LOG,
      data,
    });
  };

  onStatusChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_STATUS,
      data,
    });
  };

  onIpAccessListChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_IP_ACCESS_LIST,
      data,
    });
  };

  onSelectPeopleIds = (peopleIds: Array<number>) => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SELECT_PEOPLE,
      data: peopleIds,
    });
  };

  onTermsOfAccessChange = data => {
    this.props.dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_TERMS_OF_ACCESS,
      data,
    });
  };

  onSubmit = () => {
    const { dispatch } = this.props;
    const formData = R.pick(
      [
        'roomRole',
        'qnaRole',
        'subTeam',
        'termsOfAccess',
        'activityReport',
        'status',
        'ipAccessList',
        'selectedPeopleIds',
        'people',
        'teams',
      ],
      this.props,
    );
    const errors = validateForm(formData);
    dispatch({
      type: ACTION_TYPES.EDIT_PEOPLE.SET_ERRORS,
      data: errors,
    });
    if (hasError(errors)) {
      dispatch({ type: ACTION_TYPES.EDIT_PEOPLE.SET_IS_SUBMITTING, data: true });
    } else {
      const data = buildPayloadData(formData);
      dispatch({
        type: ACTION_TYPES.EDIT_PEOPLE.UPDATE_PEOPLE,
        data,
      });
    }
  };

  render() {
    const {
      isLoaded,
      locationProps,
      history,
      session,
      userTypes,
      roomRole,
      qnaRole,
      teams,
      people,
      subTeam,
      activityReport,
      status,
      remoteIp,
      ipAccessList,
      selectedPeopleIds,
      isSubmitting,
      errors,
      hasBeenUpdated,
      termsOfAccess,
      isAllowEnableGuestAccess,
    } = this.props;
    const { qnaEnabled } = session;
    const pageTitle = translate('EditPeople');
    const isGuest = locationProps.isGuest === '1';
    const teamType = isGuest
      ? session.translationTagNameNonAdminSide
      : session.translationTagNameAdminSide;

    const teamTypeLabel = translate(teamType);
    const breadcrumbItems = [
      {
        href: '',
        text: '',
      },
      {
        text: teamTypeLabel,
        onClick() {
          history.push(links.teams(isGuest));
        },
      },
      {
        href: '#',
        text: pageTitle,
      },
    ];
    const successMessage = (
      <>
        <h2 className={styles.successMessage} data-test-id="successMessage">
          {translate('UserUpdated')}
        </h2>
        <Link to={`/room/teams?isGuest=${isGuest ? 1 : 0}`}>{translate('BackToTeamsPage')}</Link>
      </>
    );
    const form = (
      <>
        <h3 className={styles.sectionTitle}>{translate('Properties')}</h3>
        {errors.properties && (
          <div className={styles.errorUnderTitle} data-test-id="propertiesError">
            {errors.properties}
          </div>
        )}
        <PropertyForm
          isGuest={isGuest}
          qnaEnabled={qnaEnabled}
          roomRole={roomRole}
          qnaRole={qnaRole}
          onQnaRoleChange={this.onQnaRoleChange}
          onRoomRoleChange={this.onRoomRoleChange}
          userTypes={userTypes}
          teams={teams}
          subTeam={subTeam}
          onSubTeamChange={this.onSubTeamChange}
          activityReport={activityReport}
          status={status}
          onActivityReportChange={this.onActivityReportChange}
          onStatusChange={this.onStatusChange}
          isUserIpAccessListAllowed={session.isUserIpAccessListAllowed}
          remoteIp={remoteIp}
          onIpAccessListChange={this.onIpAccessListChange}
          ipAccessList={ipAccessList}
          errors={errors}
          termsOfAccess={termsOfAccess}
          onTermsOfAccessChange={this.onTermsOfAccessChange}
          useTermsOfAccess={session.dataRoomUseTermsOfAccess}
          isAllowEnableGuestAccess={isAllowEnableGuestAccess}
        />
        <h3 className={styles.sectionTitle}>{translate('SelectPeople_SentenceCase')}</h3>
        {errors.selectedPeopleIds && (
          <div className={styles.errorUnderTitle} data-test-id="selectedPeopleError">
            {errors.selectedPeopleIds}
          </div>
        )}
        <PeopleSelector
          teams={teams}
          people={people}
          columnKeys={[
            'email',
            'roomRole',
            qnaEnabled ? 'qnaRole' : undefined,
            'subteams',
            'disabled',
          ].filter(Boolean)}
          disabledRowSelectable
          selectedKeys={selectedPeopleIds}
          onSelect={this.onSelectPeopleIds}
        />
        <div className={styles.footer}>
          <Button
            loading={isSubmitting}
            variant="Primary"
            data-test-id="updatePeopleButton"
            onClick={this.onSubmit}
          >
            {translate('Update')}
          </Button>
        </div>
      </>
    );
    return (
      <RoomLayout title={pageTitle} loadingContent={!isLoaded} data-test-id="editPeoplePage">
        <Breadcrumb items={breadcrumbItems} />
        <PageTitle withSpacing>{pageTitle}</PageTitle>
        {hasBeenUpdated ? successMessage : form}
      </RoomLayout>
    );
  }
}

const mapStateToProps = (state: AppStateType, ownProps: OwnProps) => {
  const { teams, people } = state.teams.pageData;
  const { userTypes } = state.page;
  const { isAllowEnableGuestAccess } = state.features;
  const userTypeMap = getUserTypeMap(userTypes);
  const qnaRoleMap = getQnaTypeMap(userTypes);
  const peopleWithRole = people.map(person => addTypeOnPerson(person, userTypeMap, qnaRoleMap));
  return {
    people: peopleWithRole,
    locationProps: getLocationProps(ownProps.location),
    teams,
    userTypes,
    session: state.session,
    isLoaded: state.editPeople.isLoaded,
    roomRole: state.editPeople.roomRole,
    qnaRole: state.editPeople.qnaRole,
    subTeam: state.editPeople.subTeam,
    activityReport: state.editPeople.activityReport,
    status: state.editPeople.status,
    ipAccessList: state.editPeople.ipAccessList,
    remoteIp: state.page.remoteIp,
    selectedPeopleIds: state.editPeople.selectedPeopleIds,
    isSubmitting: state.editPeople.isSubmitting,
    hasBeenUpdated: state.editPeople.hasBeenUpdated,
    errors: state.editPeople.errors,
    termsOfAccess: state.editPeople.termsOfAccess,
    isAllowEnableGuestAccess,
  };
};

export default connect<AppStateType, _, *, *>(mapStateToProps)(EditPeoplePage);
