// @flow

import * as React from 'react';
import { Button, Dialog, FormattedContentBlock } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { RegistrantType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';

export type PropsType = {
  deleteRegistration: () => void,
  deleteRegistrationLoading: boolean,
  registrant: RegistrantType,
  setDeleteDialogVisible: (RegistrantType, boolean) => void,
};

const DeleteRegistrationDialog = ({
  deleteRegistration,
  deleteRegistrationLoading,
  registrant,
  setDeleteDialogVisible,
}: PropsType) => {
  const closeDialog = () => setDeleteDialogVisible(registrant, false);

  return (
    <Dialog
      title={translate('DeleteRegistration')}
      onClose={closeDialog}
      footerPrimaryActions={[
        <Button
          key="cancel"
          onClick={closeDialog}
          data-test-id="cancel"
          disabled={deleteRegistrationLoading}
        >
          {translate('Cancel')}
        </Button>,
        <Button
          variant="Caution"
          key="delete"
          onClick={deleteRegistration}
          data-test-id="delete-registration"
          loading={deleteRegistrationLoading}
        >
          {translate('Delete')}
        </Button>,
      ]}
    >
      <FormattedContentBlock data-ansarada-ccd>
        {translate('AreYouSureToDeleteRegistration', {
          registrant: registrant.Email,
        })}
      </FormattedContentBlock>
    </Dialog>
  );
};

export default DeleteRegistrationDialog;
