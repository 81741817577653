// @flow

import { ACTION_TYPES } from 'app/constants';
import type { NotificationSettingPayload, OptionalIntervalSetting } from './notificationTypes';

export const showDocumentSelector = () => ({
  type: ACTION_TYPES.NOTIFICATION.SHOW_DOCUMENT_SELECTOR,
});

export const changeDocumentSetting = (setting: OptionalIntervalSetting) => ({
  type: ACTION_TYPES.NOTIFICATION.SET_DOCUMENT_SETTING,
  payload: {
    ...setting,
  },
});

export const enableDocumentNotification = () => ({
  type: ACTION_TYPES.NOTIFICATION.ENABLE_DOCUMENT,
});

export const disableDocumentNotification = () => ({
  type: ACTION_TYPES.NOTIFICATION.DISABLE_DOCUMENT,
});

export const enableQnaNotification = () => ({
  type: ACTION_TYPES.NOTIFICATION.ENABLE_QNA,
});

export const disableQnaNotification = () => ({
  type: ACTION_TYPES.NOTIFICATION.DISABLE_QNA,
});

export const fetchSetting = () => ({
  type: ACTION_TYPES.NOTIFICATION.FETCH_SETTING,
});

export const saveSetting = (payload: NotificationSettingPayload) => ({
  type: ACTION_TYPES.NOTIFICATION.SAVE_SETTING,
  payload,
});
