// @flow

import { string, object } from 'yup';
import isEmail from 'validator/lib/isEmail';
import translate from 'app/i18n/translate';
import { OTHER_JOB_TITLE_ID } from 'app/utils/constants';
import { normalizeEmails } from 'app/pages/teams/peopleFormDialog/peopleFormDialogActions';

export const LABELS = {
  firstName: translate('FirstName'),
  lastName: translate('LastName'),
  jobTitle: translate('JobTitle'),
  profession: translate('Profession'),
  location: translate('Location'),
  email: translate('Email'),
  phoneNumber: translate('Phone'),
  company: translate('Company'),
  additionalEmails: translate('TeamMemberEmails'),
};

const validateAdditionalEmails = (value: ?string): string => {
  if (!value) return '';
  const emails = normalizeEmails(value);
  const errors = emails
    .filter(email => !isEmail(email))
    .map(email => translate('EmailsNotValid', { Email: email }));
  return errors.join(',');
};

export const VALIDATION_SCHEMA = {
  firstName: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.firstName }))
    .max(
      24,
      translate('FieldMustBeAtMostMaxCharacters', {
        field: LABELS.firstName,
        max: 24,
      }),
    ),
  lastName: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.lastName }))
    .max(
      24,
      translate('FieldMustBeAtMostMaxCharacters', {
        field: LABELS.lastName,
        max: 24,
      }),
    ),
  jobTitle: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.jobTitle }))
    .test(
      LABELS.jobTitle,
      translate('InvalidField', { field: translate('JobTitle').toLowerCase() }),
      function validate(value) {
        const values = this.parent;
        if (values.selectedJobTitle?.jobTitleId === OTHER_JOB_TITLE_ID) return true;
        return values.selectedJobTitle?.jobTitleName === value;
      },
    ),
  profession: string()
    .trim()
    .when('selectedJobTitle.jobTitleId', {
      is: OTHER_JOB_TITLE_ID,
      then: fieldSchema =>
        fieldSchema.required(translate('FieldIsRequired', { field: LABELS.profession })),
    }),
  jobTitleOther: string()
    .trim()
    .when('selectedJobTitle.jobTitleId', {
      is: OTHER_JOB_TITLE_ID,
      then: fieldSchema =>
        fieldSchema.required(translate('FieldIsRequired', { field: LABELS.jobTitle })),
    }),
  location: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.location }))
    .test(
      LABELS.location,
      translate('InvalidField', { field: translate('Location').toLowerCase() }),
      function validate(value) {
        const values = this.parent;
        return (
          values.selectedLocation?.locationId && values.selectedLocation?.locationName === value
        );
      },
    ),
  email: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.email }))
    .test(
      LABELS.email,
      translate('InvalidField', { field: translate('EmailAddress') }),
      (value: ?string) => value && isEmail(value),
    ),
  phoneNumber: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.phoneNumber })),
  company: string()
    .trim()
    .required(translate('FieldIsRequired', { field: LABELS.company }))
    .max(
      255,
      translate('FieldMustBeAtMostMaxCharacters', {
        field: LABELS.company,
        max: 255,
      }),
    ),
  additionalEmails: string()
    .trim()
    .test(
      LABELS.email,
      (info: Object) => validateAdditionalEmails(info.value),
      (value: ?string) => validateAdditionalEmails(value).length === 0,
    ),
};

const validationSchema = object().shape(VALIDATION_SCHEMA);

export default validationSchema;
