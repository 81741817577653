// @flow

import Dropdown from 'app/components/dropdown/Dropdown';
import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { Button, DropdownAction, DropdownGroup, Icon, icon } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import * as actions from 'app/pages/security/securityActions/securityActionsActions';
import { ACTION_TYPES, SECURITY_DIALOG_TYPES, SECURITY_TABS } from 'app/constants';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { Team } from 'app/pages/security/securityTypes';

type SecurityActionsProps = {
  dispatch: AppDispatch,
  permissions: PermissionsType,
  teams: Array<Team>,
  team: Team,
  activeTab: number,
};

class SecurityActions extends React.Component<SecurityActionsProps> {
  onExpandAll = () => {
    const { dispatch, activeTab } = this.props;
    if (activeTab === SECURITY_TABS.DOCUMENTS) {
      dispatch({ type: ACTION_TYPES.SECURITY.TREE_FETCH_EXPAND_ALL });
    }
    if (activeTab === SECURITY_TABS.SUBJECTS) {
      dispatch({ type: ACTION_TYPES.SECURITY.SUBJECTS.EXPAND_ALL_GROUPS });
    }
    if (activeTab === SECURITY_TABS.TENDER_SUBMISSIONS) {
      dispatch({ type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.EXPAND_TREE });
    }
  };

  onCollapseAll = () => {
    const { dispatch, activeTab } = this.props;
    if (activeTab === SECURITY_TABS.DOCUMENTS) {
      dispatch({ type: ACTION_TYPES.SECURITY.TREE_FETCH_COLLAPSE_ALL });
    }
    if (activeTab === SECURITY_TABS.SUBJECTS) {
      dispatch({ type: ACTION_TYPES.SECURITY.SUBJECTS.COLLAPSE_ALL_GROUPS });
    }
    if (activeTab === SECURITY_TABS.TENDER_SUBMISSIONS) {
      dispatch({ type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.COLLAPSE_TREE });
    }
  };

  copySecurity = () => {
    const { dispatch, teams, team } = this.props;
    dispatch(actions.copySecuritySecurityAction(teams, team));
    dispatch({
      type: ACTION_TYPES.SECURITY.DIALOG_TYPE,
      data: SECURITY_DIALOG_TYPES.COPY_SECURITY,
    });
  };

  exportSecurity = () => {
    this.props.dispatch({ type: ACTION_TYPES.SECURITY.EXPORT_SECURITY });
  };

  render() {
    const { permissions, activeTab } = this.props;
    if (!permissions) {
      return null;
    }
    return (
      <Dropdown
        trigger={
          <Button
            data-test-id="drTeamsActionsBtn"
            variant="Ghost"
            icon={<Icon glyph={icon.Glyphs.ControlMenu} size="Medium" />}
          >
            {translate('Actions')}
          </Button>
        }
      >
        <DropdownGroup>
          <DropdownAction
            icon={<Icon glyph={icon.Glyphs.ActionExpandAll} />}
            onClick={this.onExpandAll}
            data-test-id="expandAllButton"
          >
            {translate('ExpandAll_SentenceCase')}
          </DropdownAction>
          <DropdownAction
            icon={<Icon glyph={icon.Glyphs.ActionCollapseAll} />}
            onClick={this.onCollapseAll}
            data-test-id="collapseAllButton"
          >
            {translate('CollapseAll_SentenceCase')}
          </DropdownAction>
        </DropdownGroup>

        {/* TODO HOME-181: Show unsaved data warning before taking action to "Copy Security" or "Export to Excel" */}
        {permissions.securityGroup.modify && (
          <DropdownGroup>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ActionCopy} />}
              onClick={this.copySecurity}
              data-test-id="copySecurityButton"
            >
              {translate('CopySecurity')}
            </DropdownAction>
          </DropdownGroup>
        )}

        {/* TODO HOME-181: Show unsaved data warning before taking action to "Copy Security" or "Export to Excel" */}
        {activeTab === SECURITY_TABS.DOCUMENTS && (
          <DropdownGroup>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.DocumentExcel} />}
              onClick={this.exportSecurity}
              data-test-id="exportExcelButton"
            >
              {translate('ExportToExcel_SentenceCase')}
            </DropdownAction>
          </DropdownGroup>
        )}
      </Dropdown>
    );
  }
}
export default SecurityActions;
