// @flow

import * as React from 'react';
import { Icon } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';

type SubmissionPermissionInfoType = {
  canEditTenderSubmissionAfterClose: ?boolean,
};

export const SubmissionPermissionInfo = ({
  canEditTenderSubmissionAfterClose,
}: SubmissionPermissionInfoType) =>
  !!canEditTenderSubmissionAfterClose && (
    <p>
      <Icon glyph="StatusYes" />
      &nbsp;
      <span>{translate('EditAfterCloseDate')}</span>
    </p>
  );
