// @flow

import React from 'react';
import ReactDOM from 'react-dom';

export const createWebComponent = (name: string, App: any) => {
  if (!window.customElements.get(name)) {
    const webComponent = class extends HTMLElement {
      connectedCallback() {
        // $FlowFixMe Flow thinks there might not be a document.body
        document.body.classList.add('react-spa');
        ReactDOM.render(<App />, this);
      }

      disconnectedCallback() {
        // $FlowFixMe Flow thinks there might not be a document.body
        document.body.classList.remove('react-spa');
        ReactDOM.unmountComponentAtNode(this);
      }
    };

    window.customElements.define(name, webComponent);
  }
};
