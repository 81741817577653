// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Asset,
  asset,
  Checkbox,
  Icon,
  icon,
  Label,
  Panel,
  panel,
  Progressbar,
} from '@ansarada/ace-react';
import Dialog from 'app/components/dialog/Dialog';
import translate from 'app/i18n/translate';
import StatusSwatch from 'app/pages/security/statusSwatch/StatusSwatch';
import {
  calculatePercentage,
  getFromId,
  dialogProps,
} from 'app/pages/security/copySecurityDialog/copySecurityDialogHelpers';
import { ACTION_TYPES, COPY_SECURITY_STATUSES, COPY_SECURITY_ACTIONS } from 'app/constants';
import type {
  Team,
  CopySecurityDialogType,
  CopyDialogTeam,
  CopyDialogSubteam,
  SecurityActionsType,
  Status,
} from 'app/pages/security/securityTypes';
import styles from './copySecurityDialog.scss';

type LoadProgressBarProps = {
  progress: number,
};

export const LoadProgressBar = (props: LoadProgressBarProps) => (
  <div>
    <Progressbar percentValue={props.progress} />
    <p className={styles.successMessage}>{translate('StayOnPage')}</p>
  </div>
);

export const CheckboxItem = (props: {
  item: CopyDialogTeam | CopyDialogSubteam,
  dispatch: AppDispatch,
  fromId: number,
}) => {
  const { item, dispatch, fromId } = props;
  if (item.isTeam && (!item.subteams || item.subteams.length === 0) && item.id === fromId) {
    return null;
  }
  if (!item.isTeam && item.id === fromId) return null;
  const colour = item.colour || '';
  return (
    <div>
      {/* $FlowFixMe ACE requires unnecessary value attribute */}
      <Checkbox
        name={`checkbox-to-${item.id}`}
        id={`checkbox-to-${item.id}`}
        label={
          <span data-ansarada-ccd>
            <StatusSwatch isTeam={item.isTeam} name={item.name} colour={colour} />
            {!item.isTeam && <Icon glyph={icon.Glyphs.ObjectsArrowindent} text=" " />}
            {item.name}
          </span>
        }
        checked={item.selected}
        onChecked={() =>
          dispatch({
            type: ACTION_TYPES.SECURITY.COPY_FORM_SELECT,
            data: item.id,
          })
        }
        disabled={item.id === fromId}
      />
      {item.isTeam &&
        item.subteams &&
        item.subteams.map(subteam => (
          <CheckboxItem
            key={`list-item-subteam-${subteam.id}`}
            dispatch={dispatch}
            item={{ isTeam: false, ...subteam }}
            fromId={fromId}
          />
        ))}
    </div>
  );
};

const CheckboxAll = (props: {
  selected: boolean,
  someSelected: boolean,
  dispatch: AppDispatch,
}) => {
  const { selected, someSelected, dispatch } = props;
  return (
    <div>
      {/* $FlowFixMe ACE requires unnecessary value attribute */}
      <Checkbox
        name="checkbox-to-all"
        id="checkbox-to-all"
        label={translate('All')}
        checked={selected}
        indeterminate={!selected && someSelected}
        onChecked={checked =>
          dispatch({
            type: ACTION_TYPES.SECURITY.COPY_FORM_SELECT,
            data: checked,
          })
        }
      />
    </div>
  );
};

export const StatusDisplay = (props: { status: Status }) => {
  const { status, ...rest } = props;
  switch (status) {
    case COPY_SECURITY_STATUSES.DONE:
      return <Icon {...rest} glyph={icon.Glyphs.StatusSuccess} />;
    case COPY_SECURITY_STATUSES.ERROR:
      return <Icon {...rest} glyph={icon.Glyphs.StatusError} />;
    case COPY_SECURITY_STATUSES.IN_PROGRESS:
      return <span {...rest}>In progress</span>;
    default:
      return <span {...rest}>{status}</span>;
  }
};

export const ListItem = (props: { item: CopyDialogTeam, nextAction: SecurityActionsType }) => (
  <div className="list-item">
    <Label forEl={`label-to-${props.item.id}`}>
      <Icon glyph={icon.Glyphs.StatusSwatch} text={props.item.name} colour={props.item.colour} />
      {props.item.name}
    </Label>
    {props.nextAction === COPY_SECURITY_ACTIONS.DISMISS && (
      <StatusDisplay
        style={{ float: 'right' }}
        status={props.item.status || COPY_SECURITY_STATUSES.QUEUED}
      />
    )}
  </div>
);

export const CopyOptions = (props: {
  dispatch: AppDispatch,
  submissionToolEnabled: boolean,
  content: CopySecurityDialogType,
  isGuest: boolean,
  qnaEnabled: boolean,
}) => {
  const {
    content: { copyOptions, nextAction },
    dispatch,
    isGuest,
    submissionToolEnabled,
    qnaEnabled,
  } = props;
  const copySubmissionIndexSecurityTranslationKey = isGuest
    ? 'SubmissionIndexTopLevelFoldersSecurity'
    : 'SubmissionIndexesSecurity';

  return (
    <Panel variant={panel.Variants.Secondary}>
      <div className={styles.group}>
        <div className={styles.groupName}>Options</div>
        <div className={styles.teamsContainer}>
          {nextAction === COPY_SECURITY_ACTIONS.REVIEW && (
            <>
              <Checkbox
                checked={copyOptions.documentIndexSecuritySettings}
                label={translate('DocumentIndexSecurity')}
                value="documentIndexSecuritySettings"
                onChecked={() => {
                  dispatch({
                    type: ACTION_TYPES.SECURITY.COPY_FORM_UPDATE_OPTIONS,
                    data: { optionType: 'documentIndexSecuritySettings' },
                  });
                }}
              />
              {qnaEnabled && (
                <Checkbox
                  checked={copyOptions.qnaSubjectSecuritySettings}
                  label={translate('QnaSubjectSecurity')}
                  value="qnaSubjectSecuritySettings"
                  onChecked={() => {
                    dispatch({
                      type: ACTION_TYPES.SECURITY.COPY_FORM_UPDATE_OPTIONS,
                      data: { optionType: 'qnaSubjectSecuritySettings' },
                    });
                  }}
                />
              )}
              {submissionToolEnabled && (
                <Checkbox
                  checked={copyOptions.submissionIndexSecuritySettings}
                  label={translate(copySubmissionIndexSecurityTranslationKey)}
                  value="submissionIndexSecuritySettings"
                  onChecked={() => {
                    dispatch({
                      type: ACTION_TYPES.SECURITY.COPY_FORM_UPDATE_OPTIONS,
                      data: { optionType: 'submissionIndexSecuritySettings' },
                    });
                  }}
                />
              )}
            </>
          )}

          {(nextAction === COPY_SECURITY_ACTIONS.CONFIRM ||
            nextAction === COPY_SECURITY_ACTIONS.DISMISS) && (
            <div className={styles.optionsPadding}>
              {copyOptions.documentIndexSecuritySettings && (
                <div>{translate('DocumentIndexSecurity')}</div>
              )}
              {qnaEnabled && copyOptions.qnaSubjectSecuritySettings && (
                <div>{translate('QnaSubjectSecurity')}</div>
              )}

              {submissionToolEnabled && copyOptions.submissionIndexSecuritySettings && (
                <div>{translate(copySubmissionIndexSecurityTranslationKey)}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};

export const CopyToContent = (props: {
  teams: Array<CopyDialogTeam>,
  dispatch: AppDispatch,
  nextAction: SecurityActionsType,
  progress: number,
  fromId: number,
}) => {
  const { teams, dispatch, progress, fromId } = props;
  const { nextAction } = props;
  const all = teams
    .reduce((accumalator, current) => {
      if (current) {
        accumalator.push(current);
        if (current.subteams) {
          accumalator.push(...current.subteams);
        }
      }
      return accumalator;
    }, [])
    .filter(t => t.id !== fromId);
  const allSelected = all.every(t => t.selected);
  const someSelected = all.some(t => t.selected);

  return (
    <Panel variant={panel.Variants.Secondary}>
      <div className={styles.group}>
        <div className={styles.groupName}>{translate('To')}</div>
        <div className={styles.teamsContainer} data-ansarada-ccd>
          {(nextAction === COPY_SECURITY_ACTIONS.DISMISS ||
            nextAction === COPY_SECURITY_ACTIONS.CONFIRM) &&
            teams.map(team => (
              <ListItem key={`list-item-team-${team.id}`} item={team} nextAction={nextAction} />
            ))}
          {nextAction === COPY_SECURITY_ACTIONS.REVIEW &&
            !!teams.length &&
            [
              <CheckboxAll
                key="checkbox-item-team-all"
                selected={allSelected}
                someSelected={someSelected}
                dispatch={dispatch}
              />,
            ].concat(
              teams.map(team => (
                <CheckboxItem
                  key={`checkbox-item-team-${team.id}`}
                  dispatch={dispatch}
                  item={{ isTeam: true, ...team }}
                  fromId={fromId}
                />
              )),
            )}
        </div>
      </div>
      {nextAction === COPY_SECURITY_ACTIONS.DISMISS && <LoadProgressBar progress={progress} />}
    </Panel>
  );
};

export const CopyFromContent = (props: { team: Team }) => (
  <Panel variant={panel.Variants.Secondary}>
    <div className={styles.group}>
      <div className={styles.groupName}>{translate('From')}</div>
      <div className={styles.teamsContainer} data-ansarada-ccd>
        <Asset
          data-test-id="copyFrom"
          data-ansarada-ccd
          truncate="true"
          variant={asset.Variants.UserGroup}
          name={props.team.name}
          iconColour={props.team.colour}
        />
        <br />
        {!!props.team.subteams && (
          <span style={{ marginLeft: '30px' }} data-ansarada-ccd="true">
            <Icon glyph={icon.Glyphs.ObjectsArrowindent} text=" " />
            <Asset
              data-ansarada-ccd
              truncate="true"
              variant={asset.Variants.User}
              name={props.team.subteams[0].name}
            />
          </span>
        )}
      </div>
    </div>
  </Panel>
);

export const CopySecurityDialog = (props: {
  content: CopySecurityDialogType,
  dispatch: AppDispatch,
  submissionToolEnabled: boolean,
  isGuest: boolean,
  qnaEnabled: boolean,
}) => {
  const { content, dispatch, isGuest, submissionToolEnabled, qnaEnabled } = props;
  const fromTeam = content.from;
  if (!fromTeam) return null;
  const progress = calculatePercentage(content.to, content.nextAction);
  return (
    <Dialog
      data-test-id="copySecurityDialog"
      classCSS="copy-security-dialog-content"
      {...dialogProps(
        content.nextAction,
        progress.completed,
        content.to,
        content.copyOptions,
        isGuest,
        submissionToolEnabled,
        dispatch,
      )}
    >
      <CopyOptions
        submissionToolEnabled={submissionToolEnabled}
        dispatch={dispatch}
        content={content}
        isGuest={isGuest}
        qnaEnabled={qnaEnabled}
      />
      <CopyFromContent team={fromTeam} />
      <CopyToContent
        dispatch={dispatch}
        fromId={getFromId(fromTeam)}
        teams={content.to}
        nextAction={content.nextAction}
        progress={progress.progress}
      />
    </Dialog>
  );
};

export const mapStateToProps = (state: Object): Object => ({
  content: state.security.documents.copySecurityDialog,
  isGuest: state.teams.props.isGuest,
  submissionToolEnabled: state.session.submissionToolEnabled,
  qnaEnabled: state.session.qnaEnabled,
});

export default connect<_, _, *, *>(mapStateToProps)(CopySecurityDialog);
