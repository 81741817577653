// @flow

import * as React from 'react';
import { Icon, icon, Dropdown, Button, DropdownGroup, DropdownAction } from '@ansarada/ace-react';
import { connect } from 'react-redux';
import translate from 'app/i18n/translate';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import type {
  RegistrationSettingsType,
  RegistrantType,
} from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import { ACTION_TYPES } from 'app/constants';

export type PropsType = {
  setShowInfoDialogVisible: boolean => void,
  registrationSettings: RegistrationSettingsType,
  dispatch: AppDispatch,
  registrants: RegistrantType[],
  setShowToolDialogVisible: boolean => void,
};

export const RegistrationPanelActionsComponent = ({
  setShowInfoDialogVisible,
  registrationSettings,
  dispatch,
  registrants,
  setShowToolDialogVisible,
}: PropsType) => {
  return (
    <Dropdown
      defaultOpen={false}
      keepOpenOnScroll
      trigger={
        <Button
          size="Small"
          variant="Default"
          data-test-id="registration-menu"
          icon={<Icon text="control-menu" glyph={icon.Glyphs.ControlMenuContextual} />}
        />
      }
    >
      <DropdownGroup>
        {(registrationSettings.IsEnabled || registrants.length > 0) && (
          <DropdownAction
            data-test-id="copy-registration-link"
            icon={<Icon glyph={icon.Glyphs.ActionLink} text="" />}
            onClick={() => setShowInfoDialogVisible(true)}
            disabled={!registrationSettings.IsEnabled && registrants.length > 0}
          >
            {translate('CopyRegistrationLink')}
          </DropdownAction>
        )}
        <DropdownAction
          icon={<Icon glyph={icon.Glyphs.ObjectsBuyside} text="" />}
          onClick={() =>
            dispatch({
              type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG,
              data: true,
            })
          }
          data-test-id="toggle-registration"
        >
          {registrationSettings.IsEnabled
            ? translate('DisableRegistrations_SentenceCase')
            : translate('EnableRegistrations_SentenceCase')}
        </DropdownAction>
      </DropdownGroup>
      <DropdownGroup>
        <DropdownAction
          data-test-id="guide-to-using-link"
          icon={<Icon glyph={icon.Glyphs.StatusHelp} text="" />}
          onClick={() => setShowToolDialogVisible(true)}
        >
          {translate('GuideToUsingLink')}
        </DropdownAction>
      </DropdownGroup>
    </Dropdown>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  registrationSettings: state.manageRegistration.registrationSettings,
});

export default connect<_, _, *, *>(mapStateToProps)(RegistrationPanelActionsComponent);
