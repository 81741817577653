// @flow

import { string, object } from 'yup';
import translate from 'app/i18n/translate';

export const LABELS = {
  qnaRole: translate('QnaRole'),
  teamName: translate('TeamName'),
  teamId: translate('Team'),
};

const validationSchema = object().shape({
  teamName: string()
    .trim()
    .test(
      LABELS.teamName,
      translate('FieldIsRequired', { field: LABELS.teamName }),
      function validate(value) {
        const values = this.parent;
        return values.teamOption === 'new' ? value?.length > 0 : true;
      },
    )
    .test(
      `${LABELS.teamName}team name long`,
      translate('FieldMustBeAtMostMaxCharacters', {
        field: LABELS.teamName,
        max: 50,
      }),

      function validate(value) {
        const values = this.parent;
        return values.teamOption === 'new' ? value?.length <= 50 : true;
      },
    ),
  teamId: string()
    .trim()
    .test(LABELS.teamId, translate('FieldIsRequired', { field: LABELS.teamId }), function validate(
      value,
    ) {
      const values = this.parent;
      return values.teamOption === 'existing' ? value?.length > 0 : true;
    }),
});

export default validationSchema;
