// @flow

import React from 'react';
import { TruncateText } from '@ansarada/ace-react';
import styles from 'app/components/toast-message/styles.scss';
import type { ToastMessageType } from 'app/components/toast-message/types';

const TextContent = (props: ToastMessageType) => {
  const { truncate, message } = props;

  return (
    <>
      {truncate !== false ? (
        <span className={styles.toast}>
          <TruncateText truncatePosition="Middle">{message}</TruncateText>
        </span>
      ) : (
        message
      )}
    </>
  );
};

export default TextContent;
