// @flow

import * as React from 'react';
import { format, compareAsc } from 'date-fns';
import { comparator } from 'ramda';
import { Icon, Highlight } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import SortableTable from 'app/components/sortableTable/SortableTable';
import {
  getUserTypeLabel,
  getQnaRoleLabel,
  getTermsOfAccessLabel,
} from 'app/pages/personHistory/personHistoryUtils';
import type { PersonHistory } from 'app/types/personTypes';
import type { QnaRole } from 'app/types/globalTypes';
import { DataRoomType } from 'app/types/dataRoomTypes';
import styles from './personHistoryTable.scss';

type Props = {
  history: PersonHistory,
  userTypes: Array<*>,
  qnaRoles: Array<QnaRole>,
  showIpAccessList: boolean,
  showTermsOfAccess: boolean,
  qnaEnabled: boolean,
  dataRoomType: DataRoomType,
};

const getBooleanLabel = (bool: boolean) => (bool ? translate('Yes') : translate('No'));
const highlightUpdated = (
  fieldName: string,
  renderCell: (text: any, record: Object) => React.Node,
) => (text: any, record: Object) => {
  const { updatedFields } = record;
  if (updatedFields.includes(fieldName)) {
    // $FlowFixMe as content could be Icon here.
    return <Highlight data-test-id="highlight">{renderCell(text, record)}</Highlight>;
  }
  return renderCell(text, record);
};

function PersonHistoryTable(props: Props) {
  const {
    history,
    userTypes,
    qnaRoles,
    showIpAccessList,
    showTermsOfAccess,
    qnaEnabled,
    dataRoomType,
  } = props;

  const isIrlm = dataRoomType === DataRoomType.Irlm;
  const columns = [
    {
      title: translate('Email'),
      key: 'email',
      dataIndex: 'person.email',
      sorter: true,
      sensitiveContent: true,
      renderCell: highlightUpdated('Email', email => <span data-test-id="email">{email}</span>),
    },
    {
      title: translate('DataroomRole'),
      key: 'roomRole',
      dataIndex: 'person.roomRole',
      renderCell: highlightUpdated('RoomRole', roomRole => (
        <span data-test-id="roomRole">{getUserTypeLabel(roomRole, userTypes)}</span>
      )),
      sorter: true,
    },
    qnaEnabled
      ? {
          title: translate('QnaRole'),
          key: 'qnaRole',
          dataIndex: 'person.qnaRole',
          headerTestId: 'qnaRoleHeader',
          renderCell: highlightUpdated('QnaRole', qnaRole => (
            <span data-test-id="qnaRole">{getQnaRoleLabel(qnaRole, qnaRoles)}</span>
          )),
          sorter: true,
        }
      : undefined,
    {
      title: translate('Team'),
      key: 'team',
      dataIndex: 'person.teamName',
      sensitiveContent: true,
      renderCell: highlightUpdated('TeamName', (teamName, record) => (
        <div className={styles.teamCell}>
          <Icon
            glyph="StatusSwatch"
            colour={record.person.teamColour}
            title={`Team colour – ${record.person.teamColour}`}
          />
          <span data-test-id="teamName" className={styles.teamLabel}>
            {teamName}
          </span>
        </div>
      )),
      sorter: true,
    },
    showTermsOfAccess
      ? {
          title: translate('AcceptTermsOfAccess_SentenceCase'),
          key: 'termsOfAccesss',
          dataIndex: 'person.termsOfAccess',
          renderCell: highlightUpdated('TermsOfAccess', (accessLevel, record) => (
            <span data-test-id="termsOfAccess">
              {getTermsOfAccessLabel(accessLevel, record.person.termsOfAccessOnChange)}
            </span>
          )),
          sorter: true,
        }
      : undefined,
    isIrlm
      ? undefined
      : {
          title: translate('ShowActivityInReports_SentenceCase'),
          key: 'showActivityInReports',
          dataIndex: 'person.showActivityInReports',
          renderCell: highlightUpdated('ShowActivityInReports', showActivityInReports => (
            <span data-test-id="showActivityInReports">
              {getBooleanLabel(showActivityInReports)}
            </span>
          )),
          sorter: true,
        },
    {
      title: translate('Disabled'),
      key: 'disabled',
      dataIndex: 'person.disabled',
      renderCell: highlightUpdated('Disabled', disabled => (
        <span data-test-id="disabled">{getBooleanLabel(disabled)}</span>
      )),
      sorter: true,
    },
    showIpAccessList
      ? {
          title: translate('IpAccessList_SentenceCase'),
          key: 'ipAccessList',
          dataIndex: 'person.useUserIpAccessList',
          renderCell: highlightUpdated('UseUserIpAccessList', useUserIpAccessList => (
            <span data-test-id="useUserIpAccessList">{getBooleanLabel(useUserIpAccessList)}</span>
          )),
          sorter: true,
        }
      : undefined,
    {
      title: translate('ModifiedBy'),
      key: 'modifiedBy',
      dataIndex: 'modifiedByUser',
      sensitiveContent: true,
      renderCell: modifiedByUser => (
        <>
          <div>{modifiedByUser.fullName}</div>
          <div>{modifiedByUser.userEmail && `(${modifiedByUser.userEmail})`}</div>
        </>
      ),
      sorter: comparator((a, b) => a.modifiedByUser.fullName > b.modifiedByUser.fullName),
    },
    {
      title: translate('DateModified'),
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
      headerTestId: 'modifiedDate',
      sorter: compareAsc,
      renderCell: modifiedDate => (
        <>
          <span data-test-id="dateModified">{format(modifiedDate, 'DD MMM YYYY HH:mm')}</span>
        </>
      ),
    },
  ].filter(Boolean);
  return (
    <SortableTable
      data-test-id="personHistoryTable"
      className={styles.scrollContainer}
      dataSource={history}
      columns={columns}
      rowKey="modifiedDate"
      defaultSortColumnKey="modifiedDate"
      defaultSortOrder="Descending"
    />
  );
}

export default PersonHistoryTable;
