// @flow

import type { Team, Teams, Subteam } from 'app/types/teamsTypes';
import type { SessionType } from 'app/types/globalTypes';
import { TERMS_OF_ACCESS } from 'app/pages/teams/teamsConstants';
import {
  ACTION_TYPES,
  COPY_SECURITY_ACTIONS,
  SITE_DIALOG_TYPES,
  TEAM_DIALOG_TYPES,
} from 'app/constants';
import { pickRandomColour, pickAnotherColour } from 'app/pages/teams/teamsHelpers';
import translate from 'app/i18n/translate';
import store from 'app/store';

export const copyTeam = (group: Team | Subteam) => {
  const newColour = group.colour ? pickAnotherColour(group.colour) : pickRandomColour();

  const { thirdPartyIntegrationsSecurityGroups } = store.getState().teams.pageData;
  const thirdPartyIntegrations = thirdPartyIntegrationsSecurityGroups[group.subteamId];

  return {
    type: ACTION_TYPES.TEAMS.TEAM_FORM_COPY,
    data: {
      ...group,
      isCopy: true,
      colour: newColour,
      name: `${translate('CopyOf')} ${group.name}`,
      type: TEAM_DIALOG_TYPES.COPY,
      copiedName: group.name,
      thirdPartyIntegrations,
    },
  };
};

export const expanderToggle = (data: Object) => {
  const { collapsedTeams, expanded, group } = data;
  let newCollapsedTeams = { ...collapsedTeams };
  const id = group.subteamId;
  if (!expanded) {
    delete newCollapsedTeams[id];
  } else {
    newCollapsedTeams = {
      [id]: true,
      ...newCollapsedTeams,
    };
  }
  if (!group.isSubteam && group.hasSubteams && expanded) {
    group.subteams.forEach(subteam => {
      const subteamExpandId = subteam.subteamId;
      if (!newCollapsedTeams[subteamExpandId]) {
        newCollapsedTeams = {
          [subteamExpandId]: true,
          ...newCollapsedTeams,
        };
      }
    });
  }
  return {
    type: ACTION_TYPES.TEAMS.EXPANDER_TOGGLE,
    data: {
      collapsedTeams: newCollapsedTeams,
    },
  };
};

export const copySecurityTeamsAction = (team?: Team, teams: Teams, group: Subteam | Team) => {
  const copyToTeam = teams.map<*>(t => ({
    id: t.primarySubteamId,
    subteamId: t.primarySubteamId,
    colour: t.colour,
    name: t.name,
    status: null,
    selected: false,
    useSod: t.useSod,
    subteams:
      t.subteams &&
      t.subteams.map(subteam => ({
        id: subteam.subteamId,
        subteamId: subteam.subteamId,
        name: subteam.name,
        status: null,
        selected: false,
        useSod: t.useSod,
      })),
  }));

  const copyFromTeam =
    group.isSubteam && team
      ? {
          id: team.teamId,
          subteamId: team.subteamId,
          name: team.name,
          colour: team.colour,
          subteams: [
            {
              ...group,
              id: group.subteamId,
            },
          ],
        }
      : {
          id: group.teamId,
          subteamId: group.subteamId,
          name: group.name,
          colour: group.colour,
        };
  return {
    type: ACTION_TYPES.SECURITY.COPY_SECURITY_INIT,
    data: {
      from: copyFromTeam,
      to: copyToTeam,
      nextAction: COPY_SECURITY_ACTIONS.REVIEW,
    },
  };
};

export const deleteTeamAction = (
  group: Subteam | Team,
  countPeople: number,
  isQualifiedBidder?: boolean,
) => {
  const data = group.isSubteam ? group.subteamId : group.teamId;
  if (countPeople > 0 || isQualifiedBidder) {
    return {
      type: ACTION_TYPES.TEAMS.DELETE_TEAM_DIALOG,
      data: {
        isSubteam: group.isSubteam,
        dialogType: SITE_DIALOG_TYPES.DELETE_TEAM_DIALOG,
        hasPeople: countPeople > 0,
        isQualifiedBidder,
      },
    };
  }

  return {
    type: ACTION_TYPES.TEAMS.DELETE_TEAM,
    data: {
      deleteData: data,
      isSubteam: group.isSubteam,
    },
  };
};

export const addPersonAction = (
  isSubteam?: boolean,
  teamId: number,
  subteamId: number,
  isGuest: boolean,
  session: SessionType,
) => {
  // The default value for termsOfAccess should be 2 ("Once ...")
  // unless useTermsOfAccess is false, in which case it should be 'null'
  const termsOfAccess = session.dataRoomUseTermsOfAccess
    ? TERMS_OF_ACCESS.ONCE
    : TERMS_OF_ACCESS.NA;

  return {
    type: ACTION_TYPES.TEAMS.PEOPLE_FORM_ADD,
    data: {
      teamId,
      subteamId,
      isGuest,
      isSubteam,
      termsOfAccess,
    },
  };
};
