// @flow

import { Icon, Highlight } from '@ansarada/ace-react';
import { comparator } from 'ramda';
import translate from 'app/i18n/translate';
import type { TeamRecords } from 'app/pages/teamHistory/teamHistoryTypes';
import * as React from 'react';
import { DataRoomType } from 'app/types/dataRoomTypes';
import SortableTable from 'app/components/sortableTable/SortableTable';
import { format, compareAsc } from 'date-fns';
import SubmissionTable from './SubmissionTable';
import styles from './historyTable.scss';

type HistoryTablePropsType = {
  teamHistory: TeamRecords,
  isPrimarySubteam: boolean,
  qnaEnabled: boolean,
  dataRoomType: DataRoomType,
};

const getPermittedText = (allow: boolean) => (allow ? translate('Allow') : translate('Deny'));

const highlightUpdated = (
  filedName: string,
  renderCell: (text: any, record: Object) => React.Node,
) => (text: any, record: Object) => {
  const { updatedFields } = record;
  if (updatedFields.includes(filedName)) {
    return <Highlight data-test-id="highlight">{renderCell(text, record)}</Highlight>;
  }
  return renderCell(text, record);
};

function HistoryTable(props: HistoryTablePropsType) {
  const { isPrimarySubteam, qnaEnabled, dataRoomType } = props;
  const isIrlm = dataRoomType === DataRoomType.Irlm;
  const columns = [
    isPrimarySubteam
      ? {
          title: translate('Colour'),
          key: 'colour',
          dataIndex: 'team.colour',
          renderCell: highlightUpdated('Colour', colour => (
            <Icon
              glyph="StatusSwatch"
              colour={colour}
              title={translate('TeamColour', { colour })}
            />
          )),
        }
      : undefined,
    {
      title: translate('Name'),
      key: 'name',
      dataIndex: 'team.name',
      sorter: true,
      sensitiveContent: true,
      renderCell: highlightUpdated('Name', name => <span data-test-id="teamName">{name}</span>),
    },
    !isIrlm
      ? {
          title: translate('SecureOffice'),
          key: 'useSod',
          dataIndex: 'team.useSod',
          renderCell: highlightUpdated('UseSod', getPermittedText),
          sorter: true,
        }
      : undefined,
    !isIrlm
      ? {
          title: translate('FullText'),
          key: 'fullTextSearchAllowed',
          dataIndex: 'team.fullTextSearchAllowed',
          renderCell: highlightUpdated('FullTextSearchAllowed', getPermittedText),
          sorter: true,
        }
      : undefined,
    !isIrlm
      ? {
          title: translate('BulkDownload_SentenceCase'),
          key: 'bulkDownloadAllowed',
          dataIndex: 'team.bulkDownloadAllowed',
          renderCell: highlightUpdated('BulkDownloadAllowed', getPermittedText),
          sorter: true,
        }
      : undefined,
    qnaEnabled && isPrimarySubteam
      ? {
          title: translate('QnaSubmissionsLimit'),
          key: 'qnaDetailsLimits',
          dataIndex: 'team.qnaDetails',
          renderCell(qnaDetails, record) {
            if (!qnaDetails.maxQuestionsFrequency || !qnaDetails.maxQuestionsTotal) {
              return 'No limits set';
            }
            return (
              <SubmissionTable
                qnaDetails={qnaDetails}
                updatedFields={record.updatedFields}
                data-test-id="submissionTable"
              />
            );
          },
        }
      : undefined,
    qnaEnabled && isPrimarySubteam
      ? {
          title: translate('QnaSubmissionsOnWeekends'),
          key: 'qnaDetailsWeekends',
          dataIndex: 'team.qnaDetails.allowQuestionsOnWeekends',
          width: '60px',
          renderCell: highlightUpdated('QnaDetails.AllowQuestionsOnWeekends', getPermittedText),
        }
      : undefined,
    {
      title: translate('ModifiedBy'),
      key: 'modifiedBy',
      dataIndex: 'modifiedByUser',
      sensitiveContent: true,
      renderCell: modifiedByUser => (
        <>
          <div>{modifiedByUser.fullName}</div>
          <div>{modifiedByUser.userEmail}</div>
        </>
      ),
      sorter: comparator((a, b) => a.modifiedByUser.fullName < b.modifiedByUser.fullName),
    },
    {
      title: translate('DateModified'),
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
      sorter: compareAsc,
      renderCell: modifiedDate => (
        <>
          <span data-test-id="dateModified">{format(modifiedDate, 'DD MMM YYYY HH:mm')}</span>
        </>
      ),
    },
  ].filter(Boolean);
  return (
    <SortableTable
      className={styles.scrollContainer}
      dataSource={props.teamHistory}
      columns={columns}
      rowKey="modifiedDate"
      defaultSortColumnKey="modifiedDate"
      defaultSortOrder="Descending"
    />
  );
}

export default HistoryTable;
