// @flow

import { links } from 'app/links';
import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { getLocationProps } from 'app/uri/url';
import { PageTitle } from 'app/components/typography/Typography';
import { ACTION_TYPES, PAGE_TITLES, EMPTY_NAME_CHAR } from 'app/constants';
import RoomLayout from 'app/layout/roomLayout';
import PersonHistoryTable from 'app/pages/personHistory/personHistoryTable/PersonHistoryTable';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import type { SessionType, QnaRole } from 'app/types/globalTypes';
import type { PersonHistoryState } from 'app/pages/personHistory/personHistoryTypes';
import type { RouterHistory } from 'react-router-dom';

type Props = {
  dispatch: AppDispatch,
  session: SessionType,
  personId: number,
  userTypes: Array<*>,
  qnaRoles: Array<QnaRole>,
  history: RouterHistory,
  location: Object,
} & PersonHistoryState;

const getDisplayName = (person): string =>
  !person.name || person.name === EMPTY_NAME_CHAR ? person.email : person.name;

class PersonHistoryPage extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, personId } = this.props;
    dispatch({
      type: ACTION_TYPES.PERSON_HISTORY.DATA_FETCH_INITIAL,
      data: { personId, isGuest: this.getIsGuest() },
    });
    dispatch({
      type: ACTION_TYPES.SEGMENT_EVENTS.USER_HISTORY_VIEWED,
      data: { personId },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.PERSON_HISTORY.RESET_STATE,
    });
  }

  getIsGuest() {
    const locationProps = getLocationProps(this.props.location);
    return locationProps.isGuest === '1';
  }

  render() {
    const { history, session, isLoaded, person, personHistory, userTypes, qnaRoles } = this.props;

    const isGuest = this.getIsGuest();

    const pageTitle = person
      ? `${translate('PersonHistoryFor')} ${getDisplayName(person)}`
      : translate('PersonHistory');

    const teamType = isGuest
      ? session.translationTagNameNonAdminSide
      : session.translationTagNameAdminSide;

    const teamTypeLabel = translate(teamType);

    const breadcrumbItems = [
      {
        href: '',
        text: '',
      },
      {
        text: teamTypeLabel,
        onClick() {
          history.push(links.teams(isGuest));
        },
      },
      {
        href: '#',
        text: translate('PersonHistory'),
      },
    ];
    return (
      <RoomLayout
        showSidebar
        actionButtons
        data-test-id="personHistoryPage"
        title={PAGE_TITLES.PERSON_HISTORY}
        loadingContent={!isLoaded}
      >
        <Breadcrumb items={breadcrumbItems} />
        <PageTitle withSpacing data-ansarada-ccd>
          {pageTitle}
        </PageTitle>
        <PersonHistoryTable
          history={personHistory}
          qnaRoles={qnaRoles}
          userTypes={userTypes}
          showIpAccessList={session.isUserIpAccessListAllowed}
          showTermsOfAccess={session.dataRoomUseTermsOfAccess}
          qnaEnabled={session.qnaEnabled}
          dataRoomType={session.dataRoomType}
        />
      </RoomLayout>
    );
  }
}

const mapStateToProps = (state: AppStateType, props: Object) => ({
  personId: parseInt(props.match.params.id, 10),
  userTypes: state.page.userTypes,
  session: state.session,
  qnaRoles: state.page.qnaRoles,
  ...state.personHistory,
});

export { PersonHistoryPage };

export default compose(
  connect<AppStateType, _, *, *>(mapStateToProps),
  withRouter,
)(PersonHistoryPage);
