// @flow

import { PageTitle } from 'app/components/typography/Typography';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Lozenge, Message, Button, Close } from '@ansarada/ace-react';
import MessageBox from 'app/components/messageBox/MessageBox';
import { filterActive, getFilteredTeamAndPeopleData, lozengeActive } from 'app/utils/helpers';
import translate from 'app/i18n/translate';
import TeamList from 'app/pages/teams/teamList/TeamList';
import Filter from 'app/pages/teams/filter/Filter';
import RoomLayout from 'app/layout/roomLayout';
import * as teamsActions from 'app/pages/teams/teamsActions';
import * as verifyEmailDialogActions from 'app/pages/teams/verifyEmailDialog/verifyEmailDialogActions';
import type { ViewData, PageData, VerifyEmailData } from 'app/pages/teams/teamsTypes';
import { ACTION_TYPES, PAGE_TITLES, PAGE_SECTIONS, USER_TYPE_CODES } from 'app/constants';
import ToggleRegistrationsDialog from 'app/pages/teams/toggleRegistrationsDialog/ToggleRegistrationsDialog';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { FilterData } from 'app/pages/teams/filter/filterTypes';
import { getLocationProps } from 'app/uri/url';
import { getDialog } from 'app/utils/getDialog';
import { DataRoomType } from 'app/types/dataRoomTypes';
import type {
  RegistrationSettingsType,
  RegistrantType,
} from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import { PremiumAuthorization } from 'app/components/premiumAuthorization';
import { ActivateSubscriptionMessage } from 'app/components/activateSubscriptionMessage/ActivateSubscriptionMessage';
import { NotifyOwnerMessage } from 'app/components/activateSubscriptionMessage/NotifyOwnerMessage';
import { ActivatedLiveBanner } from 'app/components/activateSubscriptionMessage/ActivatedLiveBanner';
import { ReadyToActivateLiveBanner } from 'app/components/activateSubscriptionMessage/ReadyToActivateLiveBanner';
import { getFeature2Data } from 'app/utils/feature2';
import { FeatureAdvisorUpgradeFreeToPaid } from 'app/components/featureAdvisorUpgradeFreeToPaid';
import InviteGuestBannerHeader from 'app/modules/subscription/components/InviteGuestBannerHeader';
import TeamsInviteGuestUpgradeDialogs from 'app/pages/teams/TeamsInviteGuestUpgradeDialogs';
import TeamActions from './teamActions/TeamActions';
import RegistrationPanel from './teamList/registrationPanel/RegistrationPanel';
import styles from './teamsPage.scss';
import BillingCommencesAlert from './billingCommencesAlert/BillingCommencesAlert';

type TeamsPageProps = {
  loading: boolean,
  permissions: PermissionsType,
  isGuest: boolean,
  filterData: FilterData,
  dispatch: AppDispatch,
  pageData: PageData,
  viewData: ViewData,
  session: SessionType,
  userTypes: Array<*>,
  filterIsActive: boolean,
  location: Object,
  isDialogOpen: boolean,
  registrationSettings: RegistrationSettingsType,
  registrants: RegistrantType[],
  toggleSettingDialogVisible: boolean,
  billingOnFirstGuestInvite: boolean,
  verifyEmailData: VerifyEmailData,
};

export class TeamsPage extends React.Component<TeamsPageProps> {
  componentDidMount() {
    this.props.dispatch({ type: ACTION_TYPES.PAGE.SECTION_SET, data: PAGE_SECTIONS.TEAMS });
    this.fetchTeams(this.props.location, this.props.isGuest, false);
    this.fetchRegistrationSettings(this.props.location);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: TeamsPageProps) {
    this.fetchTeams(nextProps.location, nextProps.isGuest, true);

    // refetch registration settings
    const currentUrl = `${this.props.location.pathname}?${this.props.location.search}`;
    const nextUrl = `${nextProps.location.pathname}?${nextProps.location.search}`;
    if (currentUrl !== nextUrl) {
      this.fetchRegistrationSettings(nextProps.location);
    }
  }

  shouldComponentUpdate(nextProps: TeamsPageProps) {
    // Update the page when switching between loading and not loading
    if (nextProps.loading !== this.props.loading) return true;

    // but not for each state change while the page is loading
    return !nextProps.loading;
  }

  fetchTeams = (location: Object, isGuest: boolean, checkForUpdates: boolean) => {
    const locationIsGuest = this.locationIsGuest(location);

    if (!checkForUpdates || isGuest !== locationIsGuest) {
      this.props.dispatch({
        type: ACTION_TYPES.TEAMS.DATA_FETCH,
        data: { isGuest: locationIsGuest },
      });
    }
  };

  fetchRegistrationSettings = (location: Object) => {
    const { dispatch, session } = this.props;
    const locationIsGuest = this.locationIsGuest(location);
    const selfRegistrationEnabled = locationIsGuest && session.userTypeCode === USER_TYPE_CODES.ADM;
    if (selfRegistrationEnabled) {
      dispatch({
        type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.FETCH,
      });
      dispatch({
        type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.FETCH,
      });
    }
  };

  locationIsGuest = (location: Object) => {
    const locationProps = getLocationProps(location);
    return locationProps.isGuest === '1';
  };

  render() {
    const {
      dispatch,
      filterIsActive,
      isGuest,
      filterData,
      loading,
      pageData,
      permissions,
      session,
      userTypes,
      viewData,
      isDialogOpen,
      registrationSettings,
      toggleSettingDialogVisible,
      registrants,
      billingOnFirstGuestInvite,
      verifyEmailData,
    } = this.props;
    const hasTeams = !!(pageData.teams && pageData.teams.length);
    const isIrlm = session.dataRoomType === DataRoomType.Irlm;

    const pageTitleKey: string = isGuest
      ? session.translationTagNameNonAdminSide || 'BuySide'
      : session.translationTagNameAdminSide || 'SellSide';
    // $FlowFixMe we have to trust that the session props contain values in the translations
    const pageTitle = translate(pageTitleKey) || (isGuest ? 'Buy side' : 'Sell side');
    const createMessage = isIrlm
      ? translate('CreateTeamEmptyState')
      : translate('CreateSideTeam', { Side: pageTitle.toLowerCase() });
    const createMessageButtonText = isIrlm
      ? translate('CreateTeam')
      : translate('CreateSideTeam', { Side: pageTitle.toLowerCase() });

    const { isPaidCustomer } = session;

    const showCreateATeam = !!(
      !hasTeams &&
      !filterIsActive &&
      permissions &&
      permissions.userGroup &&
      permissions.userGroup.modify
    );

    const people = (pageData.people || []).filter(person => person.isGuest === isGuest);
    const hasNoLoginUser = () => !people.find(person => person.loginCount > 0);
    const teamActions = (
      <TeamActions
        dispatch={dispatch}
        isGuest={isGuest}
        session={session}
        permissions={permissions}
        teamsFiltersActive={filterIsActive}
        hasPeople={people.length > 0}
        registrationSettings={registrationSettings}
        isPaidCustomer={isPaidCustomer}
        isEmailVerified={verifyEmailData.isEmailVerified}
      />
    );

    const showSelfRegistrationPanel =
      isGuest &&
      session.userTypeCode === USER_TYPE_CODES.ADM &&
      (registrationSettings.IsEnabled || registrants.length > 0);

    const isShowEmailVerificationMsg =
      !getFeature2Data('dig-970-freemium-corporate-signup') &&
      !verifyEmailData.isEmailVerified &&
      !verifyEmailData.closeMessage &&
      !loading;

    return (
      <RoomLayout
        actionButtons={teamActions}
        bannerHeader={isGuest && <InviteGuestBannerHeader />}
        title={PAGE_TITLES.TEAMS}
        data-test-id="teamsPage"
      >
        <FeatureAdvisorUpgradeFreeToPaid>
          {({ isAdvisorUpgradeFreeToPaidEnabled }) =>
            isAdvisorUpgradeFreeToPaidEnabled &&
            verifyEmailData.isEmailVerified && <ActivateSubscriptionMessage />
          }
        </FeatureAdvisorUpgradeFreeToPaid>

        {!filterData.filterOpen && filterIsActive && (
          <div className="teams-filter-container">
            {lozengeActive(filterData.filters).map(activeFilter => (
              <Lozenge
                text={activeFilter.item.text}
                key={activeFilter.item.id}
                closeable
                onClose={() => {
                  dispatch(teamsActions.lozengeRemove(activeFilter, filterData.filters, isGuest));
                }}
              />
            ))}
          </div>
        )}
        {filterData.filterOpen && (
          <Filter
            dispatch={dispatch}
            filterData={filterData}
            isGuest={isGuest}
            qnaEnabled={session.qnaEnabled}
          />
        )}

        <PageTitle>{pageTitle}</PageTitle>

        {isShowEmailVerificationMsg && (
          <Message
            data-test-id="messageVerifyEmail"
            className={styles.messageVerify}
            close={
              <Close
                data-test-id="closeButtonVerifyMessage"
                onUpdate={() => dispatch(verifyEmailDialogActions.closeMessage())}
                className={styles.closeButton}
              />
            }
          >
            <Button
              variant="Link"
              onClick={() => dispatch(verifyEmailDialogActions.toggleDialog(true))}
              className={styles.messageButtonVerify}
            >
              {translate('VerifyYourEmail')}
            </Button>
            {translate('ToBeAbleToInvitePeopleToYourDataRoom')}
          </Message>
        )}
        {showSelfRegistrationPanel && <RegistrationPanel session={session} />}

        <FeatureAdvisorUpgradeFreeToPaid>
          {({ advisorSelfServiceFlowEnabled, isGettingAdvisorUpgradeFreeToPaidEnabled }) => {
            if (isGettingAdvisorUpgradeFreeToPaidEnabled) {
              return null;
            }

            return (
              !advisorSelfServiceFlowEnabled &&
              isGuest &&
              !loading &&
              billingOnFirstGuestInvite &&
              hasNoLoginUser() && (
                <Message variant="error">Billing commences when first guest logs in.</Message>
              )
            );
          }}
        </FeatureAdvisorUpgradeFreeToPaid>

        <FeatureAdvisorUpgradeFreeToPaid>
          {({ isAdvisorUpgradeFreeToPaidEnabled }) =>
            isAdvisorUpgradeFreeToPaidEnabled &&
            isGuest &&
            session.userTypeCode === USER_TYPE_CODES.ADM && (
              <>
                <NotifyOwnerMessage />
                <ActivatedLiveBanner />
                <ReadyToActivateLiveBanner />
              </>
            )
          }
        </FeatureAdvisorUpgradeFreeToPaid>

        <PremiumAuthorization
          render={({ bannerContext, isTender }) =>
            isGuest && !loading && !isTender && bannerContext.show && <BillingCommencesAlert />
          }
        />

        {!!permissions && hasTeams && (
          // Caution, there is a race between team data and permission data
          // Don't render until both are ready
          <TeamList
            collapsedTeams={viewData.collapsedTeams}
            dispatch={dispatch}
            filterActive={filterIsActive}
            focusTeam={viewData.focusTeam}
            isGuest={isGuest}
            permissions={permissions}
            session={session}
            teams={pageData.teams}
            userTypes={userTypes}
            isDialogOpen={isDialogOpen}
            bidders={pageData.bidders}
            isEmailVerified={verifyEmailData.isEmailVerified}
            isPaidCustomer={isPaidCustomer}
          />
        )}

        {filterIsActive && !hasTeams && !loading && (
          <div className={styles.emptyState}>
            <p>No results to display</p>
          </div>
        )}

        {showCreateATeam && (
          <MessageBox
            data-test-id="createTeam"
            buttonText={createMessageButtonText}
            buttonAction={() => {
              dispatch({ type: ACTION_TYPES.DIALOG_TOGGLE, data: createMessage });
              dispatch(teamsActions.createTeam());
            }}
          >
            {createMessage}
          </MessageBox>
        )}

        {getDialog(viewData.dialogType)}

        {toggleSettingDialogVisible && <ToggleRegistrationsDialog />}
        {isGuest && <TeamsInviteGuestUpgradeDialogs />}
      </RoomLayout>
    );
  }
}

export const mapStateToProps = (state: AppStateType): Object => {
  const filterData = state.teams.props.isGuest
    ? state.teams.viewData.guestFilterData
    : state.teams.viewData.nonGuestFilterData;
  const { pageData } = state.teams;

  const teams = getFilteredTeamAndPeopleData(filterData.filters, pageData.teams, pageData.people);

  return {
    isGuest: state.teams.props.isGuest,
    loading: state.page.loadingBar.loading,
    permissions: state.page.userPermissions,
    filterData,
    filterIsActive: filterActive(filterData.filters),
    userTypes: state.page.userTypes,
    isDialogOpen: state.page.dialog.open || false,
    pageData: {
      teams,
      people: pageData.people,
      bidders: pageData.bidders,
    },
    session: state.session,
    viewData: state.teams.viewData,
    registrationSettings: state.manageRegistration.registrationSettings,
    registrants: state.manageRegistration.registrants,
    toggleSettingDialogVisible: state.manageRegistration.toggleSettingDialogVisible,
    billingOnFirstGuestInvite: state.page.licences && state.page.licences.billingOnFirstGuestInvite,
    verifyEmailData: state.verifyEmail,
  };
};

export default connect<_, _, *, *>(mapStateToProps)(TeamsPage);
