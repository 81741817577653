// @flow

// eslint-disable-next-line no-unused-vars
import { FormGrid, FormGridCell, FormGridRow, Editor } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import AttachmentList from 'app/components/emailForm/attachments/Attachments';
import styles from 'app/pages/sendInvitation/SendInvitationPage.scss';
import classnames from 'classnames';
import type { Attachments } from 'app/components/emailForm/EmailFormTypes';
import * as React from 'react';
import EmailImportance from 'app/components/emailForm/importance/Importance';
import type { EmailImportanceTypes } from 'app/components/emailForm/importance/Importance';
import TextInputField from 'app/components/emailForm/textInput/TextInputField';

const EMAIL_SUBJECT_CHAR_LIMIT = 200;

type Props = {
  title: string,
  showImportance?: boolean,
  showHintMessage?: boolean,
  importance?: EmailImportanceTypes,
  onImportanceUpdate?: (value: string) => void,
  messageHint?: string,
  message: string,
  messageErrors?: Array<string>,
  attachments: Attachments,
  isUploadingAttachment: boolean,
  isReadOnly: boolean,
  onAttachmentSelected: (files: Array<File>) => void,
  onRemoveAttachment: (attachmentId: number) => void,
  onEditorUpdate: (editorHtml: string, editorPlainText: string) => void,
};

const EmailSection = (props: Props) => {
  const {
    title,
    showImportance = false,
    importance,
    onImportanceUpdate,
    messageHint,
    messageErrors,
    attachments,
    message,
    onAttachmentSelected,
    onRemoveAttachment,
    onEditorUpdate,
    isUploadingAttachment,
    isReadOnly,
    showHintMessage = true,
  } = props;
  return (
    <fieldset>
      <legend>
        <h3 className={styles.sectionTitle}>{title}</h3>
      </legend>

      <FormGrid>
        {/* $FlowFixMe */}
        {showImportance && (
          <FormGridRow>
            <FormGridCell labelText={translate('Importance')}>
              {/* $FlowFixMe */}
              <EmailImportance value={importance} onUpdate={onImportanceUpdate} />
            </FormGridCell>
          </FormGridRow>
        )}

        <FormGridRow>
          {/* $FlowFixMe */}
          <TextInputField
            name="subject"
            label={translate('Subject_Email_')}
            hint={showHintMessage ? translate('SubjectEmailHint') : undefined}
            data-test-id="subjectField"
            maxLength={EMAIL_SUBJECT_CHAR_LIMIT}
            readOnly={isReadOnly}
            data-ansarada-ccd
          />
        </FormGridRow>

        <FormGridRow>
          <FormGridCell
            data-test-id="messageField"
            labelText={translate('Message')}
            hint={showHintMessage && messageHint && !messageErrors ? messageHint : undefined}
            errors={messageErrors}
          >
            <Editor
              data-test-id="messageEditor"
              resizable
              showHeadingControls
              html={message}
              onHtmlChange={(html, plainText) => {
                onEditorUpdate(html, plainText);
              }}
              status={messageErrors ? 'Bad' : 'Good'}
              readOnly={isReadOnly}
              data-ansarada-ccd
              className={classnames({ [styles.editorReadOnly]: isReadOnly })}
            />
          </FormGridCell>
        </FormGridRow>

        <FormGridRow>
          <FormGridCell labelText={translate('Attachments')}>
            <AttachmentList
              attachments={attachments}
              onFileSelected={onAttachmentSelected}
              onRemove={onRemoveAttachment}
              isUploadingAttachment={isUploadingAttachment}
            />
          </FormGridCell>
        </FormGridRow>
      </FormGrid>
    </fieldset>
  );
};

export default EmailSection;
