// @flow

import { INTERVAL_TYPE, STATUS } from 'app/pages/notification/constants';
import type {
  IntervalSetting,
  OptionalIntervalSetting,
  STATUS_TYPE,
} from 'app/pages/notification/notificationTypes';
import React from 'react';
import { Button, Label, Toggle } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import styles from '../notificationPage.scss';
import IntervalSelector from './intervalSelector/IntervalSelector';

type IntervalSelectorType = IntervalSetting & {
  status: STATUS_TYPE,
  viewOnly: boolean,
  onChange: OptionalIntervalSetting => void,
  onShowSelector: () => void,
  onEnabled: () => void,
  onDisabled: () => void,
};

const DocumentNotification = (props: IntervalSelectorType) => {
  const {
    status,
    intervalType,
    weekday,
    hour,
    period,
    viewOnly,
    onChange,
    onShowSelector,
    onEnabled,
    onDisabled,
  } = props;

  let summary = translate(intervalType);
  const periodText = {
    AM: translate('TimePeriodAm'),
    PM: translate('TimePeriodPm'),
  };
  const displayedHour = hour === 0 ? 12 : hour;
  if (intervalType === INTERVAL_TYPE.WEEKLY) {
    summary += ` ${translate(weekday)} ${displayedHour} ${periodText[period]}`;
  } else if (intervalType === INTERVAL_TYPE.DAILY) {
    summary += ` ${displayedHour} ${periodText[period]}`;
  }

  return (
    <div>
      <h3 className={styles.sectionTitle}>{translate('Documents')}</h3>
      <div className={styles.bg}>
        <div className={styles.row}>
          <Label className={styles.label} forEl="documentNotificationOnOff">
            {translate('EmailNotifications')}
          </Label>
          <Toggle
            id="documentNotificationOnOff"
            data-test-id="documentNotificationToggle"
            checked={status === STATUS.ON}
            onChecked={checked => {
              if (checked) {
                onEnabled();
              } else {
                onDisabled();
              }
            }}
          />
          {status === STATUS.OFF && (
            <span className={styles.desc}>{translate('SentDocumentChanged')}</span>
          )}
        </div>
        {status === STATUS.ON && (
          <div className={styles.row}>
            <Label className={styles.label} forEl="documentIntervalSelector">
              {translate('SendUpdates')}
            </Label>
            {viewOnly ? (
              <div>
                <span className={styles.summary} data-test-id="summary">
                  {summary}
                </span>
                <Button data-test-id="selectorChange" onClick={onShowSelector}>
                  {translate('Change')}
                </Button>
              </div>
            ) : (
              <IntervalSelector
                id="documentIntervalSelector"
                data-test-id="documentIntervalSelector"
                intervalType={intervalType}
                weekday={weekday}
                hour={hour}
                period={period}
                onChange={onChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentNotification;
