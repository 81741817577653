// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { ExpanderGroup } from '@ansarada/ace-react';
import TeamPanel from 'app/pages/teams/teamList/teamPanel/TeamPanel';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { Team, Bidder } from 'app/types/teamsTypes';
import type { FocusTeamType } from 'app/pages/teams/teamsTypes';
import styles from './teamList.scss';

type TeamsPropsType = {
  collapsedTeams: Object,
  dispatch: AppDispatch,
  filterActive: boolean,
  focusTeam: FocusTeamType,
  isGuest: boolean,
  permissions: PermissionsType,
  session: SessionType,
  teams: Array<Team>,
  userTypes: Array<*>,
  isDialogOpen: boolean,
  bidders?: Array<Bidder>,
  isEmailVerified: boolean,
  isPaidCustomer: boolean,
};

const TeamList = (props: TeamsPropsType) => {
  const { teams, focusTeam, isDialogOpen, bidders } = props;
  const hasMultipleTeams = teams.length > 1 || (!!teams[0] && teams[0].subteams.length > 0);

  return (
    <div className="team-expander" key="teams-list">
      {teams &&
        teams.map(team => {
          const teamScrollIntoView = focusTeam.teamId === team.teamId && !focusTeam.subteamId;

          return (
            <ExpanderGroup
              variant="Compact"
              key={`${team.teamId}-${team.subteamId}`}
              className={styles.expanderGroup}
            >
              {/* $FlowFixMe TeamPanel is not a valid ExpanderGroup child */}
              <TeamPanel
                collapsedTeams={props.collapsedTeams}
                dispatch={props.dispatch}
                filterActive={props.filterActive}
                isGuest={props.isGuest}
                permissions={props.permissions}
                session={props.session}
                teams={props.teams}
                userTypes={props.userTypes}
                countPeople={team.countPeople}
                group={team}
                isSecurityEdited={team.isSecurityEdited}
                key={`${team.teamId}-${team.subteamId}`}
                people={team.people}
                scrollIntoView={teamScrollIntoView}
                hasOtherTeam={hasMultipleTeams}
                isDialogOpen={isDialogOpen}
                isQualifiedBidder={
                  bidders && bidders.some(bidder => bidder.userGroupId === team.id)
                }
                isEmailVerified={props.isEmailVerified}
                isPaidCustomer={props.isPaidCustomer}
              />

              {team.subteams.map(subteam => {
                const subteamScrollIntoView =
                  focusTeam.teamId === team.teamId && focusTeam.subteamId === subteam.subteamId;
                // TODO consider redesign the team type, Keep a full name in team type so that we don't have to pass team to T
                // eamPanel and pass to EmptyStateMessage. https://jira.ansarada.com/browse/DR-18006
                return (
                  // $FlowFixMe TeamPanel is not a valid ExpanderGroup child
                  <TeamPanel
                    collapsedTeams={props.collapsedTeams}
                    dispatch={props.dispatch}
                    filterActive={props.filterActive}
                    isGuest={props.isGuest}
                    permissions={props.permissions}
                    session={props.session}
                    teams={props.teams}
                    userTypes={props.userTypes}
                    countPeople={subteam.countPeople}
                    group={subteam}
                    isSecurityEdited={subteam.isSecurityEdited}
                    key={`${subteam.teamId}-${subteam.subteamId}`}
                    people={subteam.people}
                    scrollIntoView={subteamScrollIntoView}
                    team={team}
                    hasOtherTeam={hasMultipleTeams}
                    isDialogOpen={isDialogOpen}
                    isEmailVerified={props.isEmailVerified}
                    isPaidCustomer={props.isPaidCustomer}
                  />
                );
              })}
            </ExpanderGroup>
          );
        })}
    </div>
  );
};

export default TeamList;
