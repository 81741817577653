// @flow
import { links } from 'app/links';
import * as React from 'react';
import { Button, Dropdown, DropdownAction, DropdownGroup, icon, Icon } from '@ansarada/ace-react';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import * as actions from 'app/pages/teams/teamList/teamListActions';
import { viewDocumentIndexAsTeamRedirect } from 'app/pages/teams/teamsHelpers';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { Subteam, Team } from 'app/types/teamsTypes';
import type { RouterHistory } from 'react-router-dom';
import store from 'app/store';
import TeamSendInvitePersonDropdownAction from './components/TeamSendInvitePersonDropdownAction';

type TeamPanelActionsType = {
  countPeople?: number,
  dispatch: AppDispatch,
  group: Subteam | Team,
  isGuest: boolean,
  permissions: PermissionsType,
  session: SessionType,
  team?: Team,
  teams: Array<Team>,
  hasOtherTeam: boolean,
  history: RouterHistory,
  isQualifiedBidder: boolean,
  isEmailVerified: boolean,
  isPaidCustomer: boolean,
  hasPeople?: boolean,
};

// $FlowFixMe
const withEmptyChildrenRender = (Component: React.ReactNode, empty = null) => (props: {
  children: any,
}) => {
  const children = React.Children.toArray(props.children).filter(Boolean);
  if (!children.length) {
    return empty;
  }
  return <Component {...props}>{children}</Component>;
};

const DropdownWrapper = withEmptyChildrenRender(Dropdown);
DropdownWrapper.displayName = 'Dropdown';

const DropdownGroupWrapper = withEmptyChildrenRender(DropdownGroup);
DropdownGroupWrapper.displayName = 'DropdownGroup';

class TeamPanelActions extends React.Component<TeamPanelActionsType> {
  onEditTeam = () => {
    const { group, dispatch } = this.props;

    const editText = group.isSubteam ? translate('EditSubteam') : translate('EditTeam');
    dispatch({ type: ACTION_TYPES.DIALOG_TOGGLE, data: `${editText} ${group.name}` });

    const { thirdPartyIntegrationsSecurityGroups } = store.getState().teams.pageData;
    const thirdPartyIntegrations = thirdPartyIntegrationsSecurityGroups[group.subteamId];

    dispatch({
      type: ACTION_TYPES.TEAMS.TEAM_FORM_EDIT,
      data: { ...group, thirdPartyIntegrations },
    });
  };

  onCreateSubTeam = () => {
    const { dispatch, group } = this.props;
    dispatch({
      type: ACTION_TYPES.DIALOG_TOGGLE,
      data: translate('CreateSubteam'),
    });
    const { thirdPartyIntegrationsSecurityGroups } = store.getState().teams.pageData;

    const thirdPartyIntegrations = thirdPartyIntegrationsSecurityGroups[group.subteamId];

    dispatch({
      type: ACTION_TYPES.TEAMS.TEAM_FORM_ADD_SUBTEAM,
      data: {
        teamId: group.teamId,
        useSod: group.useSod,
        thirdPartyIntegrations,
        isCopy: false,
      },
    });
  };

  onAddPeople = () => {
    const { group, session, isGuest, dispatch } = this.props;
    const { isSubteam, name, subteamId, teamId } = group;

    dispatch({
      type: ACTION_TYPES.DIALOG_TOGGLE,
      data: `${translate('AddPeopleTo')} ${name}`,
    });
    dispatch(actions.addPersonAction(isSubteam, teamId, subteamId, isGuest, session));
  };

  onCopyTeam = () => {
    const { group, dispatch } = this.props;
    dispatch({
      type: ACTION_TYPES.DIALOG_TOGGLE,
      data: translate('CopyTeam'),
    });
    dispatch(actions.copyTeam(group));
  };

  onSendInvitation = () => {
    const { group, history, isGuest } = this.props;

    history.push(
      links.sendInvitation({
        teamId: group.teamId,
        subTeamId: group.isSubteam ? group.subteamId : undefined,
        isGuest,
      }),
    );
  };

  onEmailPeople = () => {
    const { group, history, isGuest } = this.props;

    history.push(
      links.emailPeople({
        teamId: group.teamId,
        subTeamId: group.isSubteam ? group.subteamId : undefined,
        isGuest,
      }),
    );
  };

  onDelete = () => {
    const { group, dispatch, countPeople = 0, isQualifiedBidder } = this.props;
    dispatch(actions.deleteTeamAction(group, countPeople, isQualifiedBidder));
    if (countPeople > 0 || isQualifiedBidder) {
      dispatch({
        type: ACTION_TYPES.DIALOG_TOGGLE,
        data: translate(group.isSubteam ? 'DeleteSubteam' : 'DeleteTeam'),
      });
    }
  };

  onViewHistory = () => {
    const { group, history, isGuest } = this.props;
    history.push(links.teamHistory(group.subteamId, isGuest));
  };

  render() {
    const {
      dispatch,
      group,
      isGuest,
      permissions,
      session,
      team,
      teams,
      hasOtherTeam,
      history,
      isPaidCustomer,
      hasPeople,
      isEmailVerified,
    } = this.props;
    const { isSubteam } = group;

    const renderSecurityAndTeamActions = () => {
      const actionItems = [
        permissions.securityGroup.modify && permissions.document.modify && (
          // $FlowFixMe
          <>
            <DropdownAction
              data-test-id="editSecurity"
              onClick={() => history.push(links.security({ subteamIds: group.subteamId, isGuest }))}
            >
              {translate('EditSecurity_SentenceCase')}
            </DropdownAction>
            <DropdownAction
              data-test-id="viewIndexAsGhost"
              onClick={() => {
                viewDocumentIndexAsTeamRedirect(session, group.subteamId);
              }}
            >
              {translate('ViewIndexAsGhost')}
            </DropdownAction>
          </>
        ),
        (isSubteam ? permissions.securityGroup.modify : permissions.userGroup.modify) && (
          <DropdownAction onClick={this.onEditTeam} data-test-id="editTeam">
            {isSubteam ? translate('EditSubteam') : translate('EditTeam')}
          </DropdownAction>
        ),
      ].filter(Boolean);

      if (!actionItems.length) {
        return null;
      }
      return <DropdownGroupWrapper>{actionItems}</DropdownGroupWrapper>;
    };

    return (
      <DropdownWrapper
        data-test-id="teamMenuDropdown"
        keepOpenOnScroll
        defaultOpen={false}
        trigger={
          <Button
            key={`${group.teamId}-${group.subteamId}`}
            size="Small"
            variant={group.isSubteam ? 'Ghost' : 'Default'}
            data-test-id="teamMenu"
            icon={
              <Icon glyph={icon.Glyphs.ControlMenuContextual} text="control-menu" size="Medium" />
            }
          />
        }
      >
        {renderSecurityAndTeamActions()}
        {/* $FlowFixMe */}
        <React.Fragment>
          <DropdownGroupWrapper>
            {permissions.user.modify && (
              <DropdownAction data-test-id="addPeople" onClick={this.onAddPeople}>
                {translate('AddPeople')}
              </DropdownAction>
            )}

            {permissions.securityGroup.modify && !isSubteam && (
              <DropdownAction data-test-id="createSubteam" onClick={this.onCreateSubTeam}>
                {translate('CreateSubteam')}
              </DropdownAction>
            )}
          </DropdownGroupWrapper>
          <DropdownGroupWrapper>
            {permissions.userGroup.modify && !isSubteam && (
              <DropdownAction data-test-id="copyTeam" onClick={this.onCopyTeam}>
                {translate('CopyTeam')}
              </DropdownAction>
            )}

            {permissions.securityGroup.modify && permissions.document.modify && hasOtherTeam && (
              <DropdownAction
                data-test-id="copySecurity"
                onClick={() => dispatch(actions.copySecurityTeamsAction(team, teams, group))}
              >
                {translate('CopySecurity')}
              </DropdownAction>
            )}
          </DropdownGroupWrapper>
          <DropdownGroupWrapper>
            {permissions.user.modify && (
              // $FlowFixMe
              <React.Fragment>
                <TeamSendInvitePersonDropdownAction
                  history={history}
                  isGuest={isGuest}
                  group={group}
                  isEmailVerified={isEmailVerified}
                  hasPeople={hasPeople}
                />

                {isPaidCustomer && (
                  <DropdownAction data-test-id="emailPeople" onClick={this.onEmailPeople}>
                    {translate('EmailPeople')}
                  </DropdownAction>
                )}
              </React.Fragment>
            )}

            {(isSubteam ? permissions.securityGroup.modify : permissions.userGroup.modify) && (
              <DropdownAction data-test-id="delete" onClick={this.onDelete}>
                {translate('Delete')}
              </DropdownAction>
            )}
          </DropdownGroupWrapper>
          {(isSubteam ? permissions.securityGroup.select : permissions.userGroup.select) &&
            permissions.user.select && (
              <DropdownGroup>
                <DropdownAction data-test-id="history" onClick={this.onViewHistory}>
                  {translate('History')}
                </DropdownAction>
              </DropdownGroup>
            )}
        </React.Fragment>
      </DropdownWrapper>
    );
  }
}

export default TeamPanelActions;
