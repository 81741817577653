// @flow

import { Checkbox, HelpLink, icon, Icon, Tooltip } from '@ansarada/ace-react';
import type { OptionalCheckedValue } from 'app/pages/editPeople/editPeopleTypes';
import * as React from 'react';
import classNames from 'classnames';
import styles from './propertyForm.scss';

type PropTypes = {
  label: string,
  disabled?: boolean,
  checked: boolean,
  onChange: (OptionalCheckedValue<*>) => void,
  children: React.Element<*>,
  'data-test-id'?: string,
  helpText?: string,
  helpUrl?: string,
  className?: string,
  error?: string,
};

const CheckableField = (props: PropTypes) => {
  const {
    label,
    disabled = false,
    onChange,
    children,
    'data-test-id': testId,
    helpText,
    helpUrl,
    className,
    error,
  } = props;
  const classes = classNames(className, styles.checkableField);
  return (
    <div className={classes} data-test-id={testId}>
      <Checkbox
        className={styles.labelBlock}
        label={label}
        value="on"
        disabled={disabled}
        checked={disabled ? false : props.checked}
        onChecked={checked => onChange({ checked })}
      />
      <div className={styles.itemControl}>
        {React.cloneElement(children, { disabled: disabled || !props.checked })}
        {error && <div className={styles.error}>{error}</div>}
      </div>
      {helpUrl && helpText && (
        <Tooltip id={label} defaultOpen={false} text={helpText} position="Top">
          <HelpLink
            href={helpUrl}
            icon={<Icon glyph={icon.Glyphs.StatusHelp} />}
            className={styles.helper}
            target="_blank"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default CheckableField;
