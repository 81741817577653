"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePremiumHeaderContext = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var sessionReducer_1 = require("app/reducers/sessionReducer");
var dataRoomTypes_1 = require("app/types/dataRoomTypes");
var subscription_event_context_1 = require("app/context/subscription-event-context");
var event_1 = require("./event");
var initialBannerState = {
    stage: 'Initial',
    handleClicked: event_1.publishPremiumUpgradeDialogOpenedEvent,
    show: false,
};
function usePremiumHeaderContext() {
    var dataRoomType = (0, react_redux_1.useSelector)(sessionReducer_1.selectSession).dataRoomType;
    var _a = (0, subscription_event_context_1.useSubscriptionEventContext)(), bannerContext = _a.bannerContext, subscriptionStage = _a.subscriptionStage;
    var isTender = (0, react_1.useMemo)(function () { return dataRoomType === dataRoomTypes_1.DataRoomType.Tender; }, [dataRoomType]);
    var isFreemium = (0, react_1.useMemo)(function () { return subscriptionStage === 'Freemium'; }, [subscriptionStage]);
    return { bannerContext: bannerContext, isTender: isTender, isFreemium: isFreemium };
}
exports.usePremiumHeaderContext = usePremiumHeaderContext;
