// @flow

import React from 'react';
import translate from 'app/i18n/translate';
import { FormattedContentBlock, Columns, Column, Dialog, Button, Link } from '@ansarada/ace-react';
import type { RegistrantType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import { STATUSES } from 'app/pages/teams/teamList/registrationPanel/constants';
import { OTHER_JOB_TITLE_ID } from 'app/utils/constants';
import styles from './RegistrationDetailsDialog.scss';
import RegistrantStatus from '../../registrantStatus/RegistrantStatus';

export type PropsType = {
  registrant: RegistrantType,
  closeDialog: () => void,
  invite: () => void,
  decline: () => void,
};

const RegistrantDetailsDialog = ({ registrant, invite, decline, closeDialog }: PropsType) => {
  const inviteRegistration = () => {
    closeDialog();
    invite();
  };

  const declineRegistration = () => {
    closeDialog();
    decline();
  };

  const jobTitleName =
    registrant.JobTitleId === OTHER_JOB_TITLE_ID
      ? `${registrant.JobTitleOther} - ${registrant.JobTitleGroupOther}`
      : registrant.JobTitleName;

  return (
    <Dialog
      title={translate('RegistrationDetails')}
      onClose={closeDialog}
      footerPrimaryActions={[
        <Button key="close" onClick={closeDialog} data-test-id="close">
          {translate('Close')}
        </Button>,
        (registrant.Status === STATUSES.PEN || registrant.Status === STATUSES.DEC) && (
          <Button
            variant="Primary"
            key="invite"
            onClick={inviteRegistration}
            data-test-id="invite-registration"
          >
            {translate('Accept')}
          </Button>
        ),
      ]}
      footerSecondaryActions={[
        registrant.Status === STATUSES.PEN && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link variant="Caution" onClick={declineRegistration} data-test-id="decline-registration">
            {translate('Decline')}
          </Link>
        ),
      ]}
    >
      <div className={styles.wrapper}>
        <Columns spacing="100">
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('Email')}</strong>
            </FormattedContentBlock>
            <Link data-test-id="mail" href={`mailto:${registrant.Email}`} data-ansarada-ccd>
              {registrant.Email}
            </Link>
          </Column>
        </Columns>
        <br />
        <Columns spacing="100">
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('FullName')}</strong>
            </FormattedContentBlock>
            <div data-ansarada-ccd>{registrant.FullName}</div>
          </Column>

          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('Status')}</strong>
            </FormattedContentBlock>
            <RegistrantStatus status={registrant.Status} />
          </Column>
        </Columns>
        <br />

        <Columns spacing="100">
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('JobTitle')}</strong>
            </FormattedContentBlock>
            <div data-ansarada-ccd>{jobTitleName}</div>
          </Column>
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('Company')}</strong>
            </FormattedContentBlock>
            <div data-ansarada-ccd>{registrant.Company}</div>
          </Column>
        </Columns>

        <br />

        <Columns spacing="100">
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('Location')}</strong>
            </FormattedContentBlock>
            <div data-ansarada-ccd>{registrant.LocationName}</div>
          </Column>
          <Column alignment="None">
            <FormattedContentBlock>
              <strong>{translate('PhoneNumber_SentenceCase')}</strong>
            </FormattedContentBlock>
            <div data-ansarada-ccd>{registrant.PhoneNumber}</div>
          </Column>
        </Columns>
        <br />
        {registrant.Note && (
          <Columns spacing="100">
            <Column alignment="None">
              <FormattedContentBlock>
                <strong>{translate('ReasonForRequest')}</strong>
              </FormattedContentBlock>
              <p className={styles.note}>{registrant.Note}</p>
            </Column>
          </Columns>
        )}
        <br />
        {registrant.RequestedByEmail && (
          <Columns spacing="100">
            <Column alignment="None">
              <FormattedContentBlock>
                <strong>{translate('RequestorEmail')}</strong>
              </FormattedContentBlock>
              <Link
                key={`requestor-email-${registrant.RequestedByEmail}`}
                data-test-id="requestormail"
                href={`mailto:${registrant.RequestedByEmail}`}
                data-ansarada-ccd
              >
                {registrant.RequestedByEmail}
              </Link>
            </Column>
          </Columns>
        )}
        <br />
        {registrant.AdditionalEmails?.length > 0 && (
          <Columns spacing="100">
            <Column alignment="None">
              <FormattedContentBlock>
                <strong>Team member emails</strong>
              </FormattedContentBlock>
              {registrant.AdditionalEmails.map(item => (
                <Link
                  className={styles.additionalEmail}
                  key={`additional-email-${item}`}
                  data-test-id="mail"
                  href={`mailto:${item}`}
                  data-ansarada-ccd
                >
                  {item}
                </Link>
              ))}
            </Column>
          </Columns>
        )}
      </div>
    </Dialog>
  );
};
export default RegistrantDetailsDialog;
