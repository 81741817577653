// @flow

import { Label, Option, Select } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { INTERVAL_TYPE, PERIOD, WEEKDAYS } from 'app/pages/notification/constants';
import type {
  IntervalSetting,
  OptionalIntervalSetting,
} from 'app/pages/notification/notificationTypes';
import * as React from 'react';
import uuid from 'uuid/v4';
import classNames from 'classnames';
import styles from './IntervalSelector.scss';

const hours = Array.from({ length: 12 }).map((value, index) => ({
  value: String(index + 1 === 12 ? 0 : index + 1),
  text: String(index + 1),
  key: String(index + 1),
}));

type IntervalSelectorType = IntervalSetting & {
  id?: string,
  'data-test-id'?: string,
  onChange: OptionalIntervalSetting => void,
};

class IntervalSelector extends React.Component<IntervalSelectorType> {
  constructor(props: IntervalSelectorType) {
    super(props);
    this.idPrefixes = uuid();
  }

  idPrefixes: string;

  render() {
    const { intervalType, weekday, hour, period, onChange } = this.props;
    const { idPrefixes } = this;
    const localTimeZone = Intl && Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
      <div
        id={this.props.id}
        data-test-id={this.props['data-test-id']}
        className={styles.intervalSelector}
      >
        <Label className={styles.assist} forEl={`${idPrefixes}-interval`}>
          {translate('Interval')}
        </Label>
        <Select
          className={classNames(styles.intervalInput, styles.intervalType)}
          id={`${idPrefixes}-interval`}
          data-test-id="intervalSelector"
          value={intervalType}
          onChangeValue={(intervalValue: any) => onChange({ intervalType: intervalValue })}
        >
          <Option value={INTERVAL_TYPE.HOURLY} text={translate('Hourly')} />
          <Option value={INTERVAL_TYPE.DAILY} text={translate('Daily')} />
          <Option value={INTERVAL_TYPE.WEEKLY} text={translate('Weekly')} />
        </Select>
        {intervalType === INTERVAL_TYPE.WEEKLY && (
          <React.Fragment>
            <Label className={styles.intervalOption} forEl={`${idPrefixes}-day`}>
              {translate('Every')}
            </Label>
            <Select
              className={classNames(styles.intervalInput, styles.weekday)}
              id={`${idPrefixes}-day`}
              data-test-id="daySelector"
              value={weekday}
              onChangeValue={(weekdayValue: any) => onChange({ weekday: weekdayValue })}
            >
              {WEEKDAYS.map(day => (
                <Option key={day} value={day} text={day} />
              ))}
            </Select>
          </React.Fragment>
        )}
        {(intervalType === INTERVAL_TYPE.WEEKLY || intervalType === INTERVAL_TYPE.DAILY) && (
          <React.Fragment>
            <Label className={styles.intervalOption} forEl={`${idPrefixes}-time`}>
              {translate('At')}
            </Label>
            <Select
              className={styles.intervalInput}
              id={`${idPrefixes}-time`}
              data-test-id="timeSelector"
              value={String(hour)}
              onChangeValue={hourValue => onChange({ hour: Number(hourValue) })}
            >
              {hours.map(hourOption => (
                <Option key={hourOption.key} value={hourOption.value} text={hourOption.text} />
              ))}
            </Select>
            <Label className={styles.assist} forEl={`${idPrefixes}-period`}>
              {translate('Period')}
            </Label>
            <Select
              className={styles.intervalInput}
              id={`${idPrefixes}-period`}
              data-test-id="periodSelector"
              value={period}
              onChangeValue={(periodValue: any) => onChange({ period: periodValue })}
            >
              <Option value={PERIOD.AM} text={translate('TimePeriodAm')} />
              <Option value={PERIOD.PM} text={translate('TimePeriodPm')} />
            </Select>
            {localTimeZone && <span>{`${localTimeZone} ${translate('Time')}`}</span>}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default IntervalSelector;
