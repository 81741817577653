"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var useCheckShowActivateSubscriptionMessage_1 = require("app/hooks/useCheckShowActivateSubscriptionMessage");
var licenseReducer_1 = require("app/reducers/licenseReducer");
var react_redux_1 = require("react-redux");
var useGetSubscriptionConfigurationByAsset_1 = require("app/hooks/useGetSubscriptionConfigurationByAsset");
var inviteGuestUpgradeDialogActions_1 = require("../inviteGuestUpgradeDialogActions");
function useInviteGuestAuthorizationContext() {
    var license = (0, react_redux_1.useSelector)(licenseReducer_1.selectLicenseState);
    var subscriptionType = (0, useCheckShowActivateSubscriptionMessage_1.useCheckShowActivateSubscriptionMessage)().subscriptionType;
    var data = (0, useGetSubscriptionConfigurationByAsset_1.useGetSubscriptionConfigurationByAsset)().data;
    var dispatch = (0, react_redux_1.useDispatch)();
    var canUseAlwaysAutomationFlow = ((data === null || data === void 0 ? void 0 : data.subscriptionConfigurationByAsset) || {}).canUseAlwaysAutomationFlow;
    var handleOpenTenderFreemiumInviteGuestDialog = function () {
        return (0, inviteGuestUpgradeDialogActions_1.toggleTenderFreemiumInviteGuestUpgradeDialog)(dispatch, true);
    };
    var handleOpenAlwaysInviteGuestDialog = function () {
        return (0, inviteGuestUpgradeDialogActions_1.toggleAlwaysInviteGuestUpgradeDialog)(dispatch, true);
    };
    if (license.isTendersFreemium) {
        return ['TenderFreemium', handleOpenTenderFreemiumInviteGuestDialog];
    }
    if (canUseAlwaysAutomationFlow && subscriptionType === 'Always') {
        return ['Always', handleOpenAlwaysInviteGuestDialog];
    }
    return ['None', function () { }];
}
exports.default = useInviteGuestAuthorizationContext;
