"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
var sessionReducer_1 = require("app/reducers/sessionReducer");
var react_1 = require("react");
var useGetSubscriptionConfigurationByAsset_1 = require("./useGetSubscriptionConfigurationByAsset");
function useFeatureAdvisorUpgradeFreeToPaid() {
    var _a = (0, useGetSubscriptionConfigurationByAsset_1.useGetSubscriptionConfigurationByAsset)(), data = _a.data, isLoading = _a.isLoading;
    var session = (0, react_redux_1.useSelector)(sessionReducer_1.selectSession);
    var isAdvisorClient = session.provisioningType === 'AdvisorFreemium';
    var advisorSelfServiceFlowEnabled = Boolean(data === null || data === void 0 ? void 0 : data.subscriptionConfigurationByAsset.canUseAdvisorSelfServiceFlow);
    return (0, react_1.useMemo)(function () { return ({
        isAdvisorClient: isAdvisorClient,
        advisorSelfServiceFlowEnabled: advisorSelfServiceFlowEnabled,
        isAdvisorUpgradeFreeToPaidEnabled: isAdvisorClient && advisorSelfServiceFlowEnabled,
        isGettingAdvisorUpgradeFreeToPaidEnabled: isLoading,
    }); }, [isAdvisorClient, advisorSelfServiceFlowEnabled, isLoading]);
}
exports.default = useFeatureAdvisorUpgradeFreeToPaid;
