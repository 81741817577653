// @flow

import { ACTION_TYPES } from 'app/constants';
import { pickRandomColour } from 'app/pages/teams/teamsHelpers';
import type { SessionType } from 'app/types/globalTypes';
import * as url from 'app/uri/url';
import { openWindow } from 'app/utils/legacy-helpers';
import store from 'app/store';

export const lozengeRemove = (activeFilter: Object, filters: Object, isGuest: boolean) => {
  const newFilterSelected = filters[activeFilter.filterName].selected.filter(
    item => item.id !== activeFilter.item.id,
  );

  return {
    type: ACTION_TYPES.TEAMS.FILTER_REMOVE,
    data: {
      filterName: activeFilter.filterName,
      newFilterSelected,
    },
    isGuest,
  };
};

export const createTeam = () => {
  const { thirdPartyIntegrationsDataRoom } = store.getState().teams.pageData;
  const thirdPartyIntegrations = thirdPartyIntegrationsDataRoom
    ? thirdPartyIntegrationsDataRoom.filter(item => item.isAllowed)
    : [];

  return {
    type: ACTION_TYPES.TEAMS.TEAM_FORM_ADD_TEAM,
    data: { colour: pickRandomColour(), thirdPartyIntegrations, isCopy: false },
  };
};

export const openViewHistoryPage = (session: SessionType, userId: number) => {
  const windowUrl = url.generateLegacyURL('ViewUserHistory.MC.asp', session, null, {
    userId,
    sortAscending: 0,
  });
  openWindow(windowUrl, `user-${userId}-history`);
};
