// @flow

import * as React from 'react';
import { SITE_DIALOG_TYPES } from 'app/constants';
import type { SiteDialogType } from 'app/pages/teams/teamsTypes';
import TeamFormDialog from 'app/pages/teams/teamFormDialog/TeamFormDialog';
import PeopleFormDialog from 'app/pages/teams/peopleFormDialog/PeopleFormDialog';
import CopySecurityDialog from 'app/pages/security/copySecurityDialog/CopySecurityDialog';
import DeleteTeamDialog from 'app/pages/teams/deleteTeamDialog/DeleteTeamDialog';
import ToggleRegistrationsDialog from 'app/pages/teams/toggleRegistrationsDialog/ToggleRegistrationsDialog';
import VerifyEmailDialog from 'app/pages/teams/verifyEmailDialog/VerifyEmailDialog';

export const getDialog = (dialogType: ?SiteDialogType) => {
  switch (dialogType) {
    case SITE_DIALOG_TYPES.TEAM_FORM:
      return <TeamFormDialog />;
    case SITE_DIALOG_TYPES.PEOPLE_FORM:
      return <PeopleFormDialog />;
    case SITE_DIALOG_TYPES.COPY_SECURITY_FORM:
      return <CopySecurityDialog />;
    case SITE_DIALOG_TYPES.DELETE_TEAM_DIALOG:
      return <DeleteTeamDialog />;
    case SITE_DIALOG_TYPES.TOGGLE_REGISTRATIONS_DIALOG:
      return <ToggleRegistrationsDialog />;
    case SITE_DIALOG_TYPES.TOGGLE_VERIFY_EMAIL_DIALOG:
      return <VerifyEmailDialog />;
    default:
      return undefined;
  }
};
