// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import translate from 'app/i18n/translate';
import { Link, FormattedContentBlock } from '@ansarada/ace-react';

type FormInputHelpStaticProps = {
  topic: 'fullTextSearch' | 'bulkDownload' | 'sod' | 'roomRole',
};

type FormInputHelpProps = {
  visible: boolean,
  info?: string,
  warning?: string,
  text: string,
  findMoreUrl: string,
  className: string,
};

export const FormInputHelp = (props: FormInputHelpProps) => {
  const { className, info, visible, text, findMoreUrl, warning } = props;

  if (!visible) return null;

  return (
    <div className={className}>
      <span style={{ color: '#5f6062' }}>
        <FormattedContentBlock>
          <p>
            <small>{text}&nbsp;</small>
          </p>
          {warning && (
            <p>
              <strong>{translate('Warning')}: </strong>
              {warning}&nbsp;
            </p>
          )}
          {info && (
            <p>
              <strong>{info}</strong>
            </p>
          )}
        </FormattedContentBlock>
        <Link href={findMoreUrl} target="_blank">
          Find out more.
        </Link>
      </span>
    </div>
  );
};

const mapStateToProps = (state: Object, props: FormInputHelpStaticProps): Object => ({
  visible: state.teams.viewData.formHelpers[props.topic],
});

export default connect<_, _, *, *>(mapStateToProps)(FormInputHelp);
