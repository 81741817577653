// @flow

import * as React from 'react';
import styles from './typography.scss';

type PageTitlePropType = {|
  'data-test-id'?: string,
  'data-ansarada-ccd'?: true,
  children?: any,
  withSpacing?: boolean,
|};

export const PageTitle = (props: PageTitlePropType) => (
  <h2
    className={props.withSpacing ? styles.pageTitleWithSpacing : styles.pageTitle}
    data-test-id={props['data-test-id'] || 'pageTitle'}
    data-ansarada-ccd={props['data-ansarada-ccd'] || undefined}
  >
    {props.children}
  </h2>
);
