// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { SwatchesPicker } from 'react-color';
import { ACTION_TYPES } from 'app/constants';
import styles from 'app/components/colourPicker/colourPicker.scss';

type ColourPickerPropType = {
  colourData?: Array<Array<string>>,
  isVisible: boolean,
  colour: string, // this is a hex string
  onToggle: () => void,
  onChange: string => void,
  dispatch: AppDispatch,
};

const ColourPicker = (props: ColourPickerPropType) => {
  const { onChange, colour, colourData, isVisible, dispatch, onToggle } = props;

  const onChangeAction = e => {
    onChange(e.hex);
    dispatch({ type: ACTION_TYPES.COLOURPICKER_TOGGLE });
  };

  return (
    <div data-test-id="colourPicker">
      <div role="button" tabIndex="0" className="dr-colour-picker-swatch" onClick={onToggle}>
        <div className={styles.color} style={{ backgroundColor: colour }} />
      </div>

      {isVisible ? (
        <div className={styles.popover}>
          <div role="button" tabIndex="0" className={styles.cover} onClick={onToggle} />
          <SwatchesPicker
            colors={colourData}
            color={colour}
            width="335px"
            onChange={e => onChangeAction(e)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColourPicker;
