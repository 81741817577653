// @flow

import * as React from 'react';
import Dialog from 'app/components/dialog/Dialog';
import { Panel } from '@ansarada/ace-react';
import { connect } from 'react-redux';
import translate from 'app/i18n/translate';

type DeleteTeamDialogPropType = {
  isSubteam: boolean,
  hasPeople: boolean,
  isQualifiedBidder: boolean,
};

const deleteTeamInformation = (hasPeople: boolean, isQualifiedBidder: boolean) => {
  if (hasPeople && isQualifiedBidder) {
    return (
      <>
        {translate('DeleteBidderTeamInformationLine1')}
        <br />
        {translate('DeleteBidderTeamInformationLine2')}
      </>
    );
  }
  if (isQualifiedBidder) {
    return translate('DeleteBidderInformation');
  }
  if (hasPeople) {
    return translate('DeleteTeamInformation');
  }
  return '';
};

export const DeleteTeamDialog = (props: DeleteTeamDialogPropType) => {
  const { isSubteam, hasPeople, isQualifiedBidder } = props;
  const content = isSubteam
    ? translate('DeleteSubteamInformation')
    : deleteTeamInformation(hasPeople, isQualifiedBidder);

  return (
    <Dialog closeButtonText={translate('GotIt')} size="Small">
      <Panel>{content}</Panel>
    </Dialog>
  );
};

export const mapStateToProps = (state: Object): DeleteTeamDialogPropType => ({
  isSubteam: state.teams.viewData.isSubteam,
  hasPeople: state.teams.viewData.hasPeople,
  isQualifiedBidder: state.teams.viewData.isQualifiedBidder,
});

export default connect<_, _, *, *>(mapStateToProps)(DeleteTeamDialog);
