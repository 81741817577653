// @flow

export const isShallowEqual = (a: Object, b: Object) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key in b) {
    if (!(key in a) || a[key] !== b[key]) return false;
  }
  return true;
};

export const isEmptyObject = (obj: Object) => {
  return !!(obj && Object.keys(obj).length === 0 && obj.constructor === Object);
};
