// @flow

import * as React from 'react';
import translate from 'app/i18n/translate';
import type { PeopleWithRole, PersonWithRole } from 'app/components/emailForm/EmailFormTypes';
import type { Teams } from 'app/types/teamsTypes';
import {
  Tree,
  TableHeadLegacy,
  TableBodyLegacy,
  TreeHeaderGroup,
  TreeItem,
  Checkbox,
} from '@ansarada/ace-react';
import TeamTreeGroup from './TeamTreeGroup';
import styles from './teamTreeGroup.scss';
import { columnsMap, getPeopleInTeam } from './peopleSelectorHelper';
import type { ColumnKeyType } from './peopleSelectorHelper';

type PeopleSelectorPropsType = {
  selectedKeys: Array<number>,
  teams: Teams,
  people: PeopleWithRole,
  onSelect: (Array<number>) => void,
  columnKeys?: Array<ColumnKeyType>,
  disabledRowSelectable?: boolean,
};

class PeopleSelector extends React.Component<PeopleSelectorPropsType> {
  onHeadUpdate = (checked: boolean) => {
    const { people, onSelect } = this.props;
    const selectablePeople = people.filter(this.isSelectable);
    if (checked) {
      onSelect(selectablePeople.map(person => person.personId));
    } else {
      onSelect([]);
    }
  };

  getHeadCheckStatus() {
    const { selectedKeys, people } = this.props;
    const selectablePeople = people.filter(this.isSelectable);
    return {
      disabled: selectablePeople.length === 0,
      checked: selectedKeys.length !== 0 && selectedKeys.length === selectablePeople.length,
      indeterminate: selectedKeys.length > 0 && selectedKeys.length < selectablePeople.length,
    };
  }

  isSelectable = (person: PersonWithRole) => {
    return this.props.disabledRowSelectable ? true : !person.disabled;
  };

  render() {
    const { teams, selectedKeys, onSelect, columnKeys = [], disabledRowSelectable } = this.props;
    const filteredColumnKeys = columnKeys.filter(key => !!columnsMap[key]);
    return (
      // eslint-disable-next-line css-modules/no-undef-class
      <Tree data-test-id="peopleSelector" className={styles.peopleSelector}>
        <TableHeadLegacy>
          <TreeHeaderGroup>
            <TreeItem className={styles.headerCheckbox}>
              <Checkbox
                label={translate('Name')}
                value="on"
                {...this.getHeadCheckStatus()}
                onChecked={checked => {
                  this.onHeadUpdate(checked);
                }}
              />
            </TreeItem>
            {filteredColumnKeys.map(key => {
              return <TreeItem key={key}>{columnsMap[key].title}</TreeItem>;
            })}
          </TreeHeaderGroup>
        </TableHeadLegacy>
        <TableBodyLegacy>
          {// $FlowFixMe TableBodyLegacy doesn't accept null
          teams.map(team => {
            const people = getPeopleInTeam(this.props.people, team, teams);
            if (!people.length) {
              return null;
            }
            return (
              <TeamTreeGroup
                key={team.teamId}
                people={people}
                team={team}
                selectedKeys={selectedKeys}
                onSelect={onSelect}
                columnsCount={columnKeys.length}
                disabledRowSelectable={disabledRowSelectable}
                renderCells={person => {
                  return filteredColumnKeys.map(key => {
                    return <TreeItem key={key}>{columnsMap[key].renderCell(person)}</TreeItem>;
                  });
                }}
              />
            );
          })}
        </TableBodyLegacy>
      </Tree>
    );
  }
}

export default PeopleSelector;
