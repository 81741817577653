// @flow

import queryString from 'query-string';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'app/constants';

type SecurityQueryParams = {|
  isGuest: boolean,
  subteamIds?: number,
  autoRedirectToGhostView?: boolean,
|};

type SendInvitationQueryParams = {|
  isGuest: boolean,
  teamId?: number,
  subTeamId?: number,
  userId?: number,
|};

type EmailPeopleQueryParams = {|
  isGuest: boolean,
  teamId?: number,
  subTeamId?: number,
|};

type QueryWithIsGuest = {
  isGuest: boolean,
};

const formatQueryWithIsGuest = (query: QueryWithIsGuest) => {
  return queryString.stringify({
    ...query,
    isGuest: query.isGuest ? 1 : 0,
  });
};

export const links = {
  teams: (isGuest: boolean) =>
    `${ROUTES.TEAMS}?${queryString.stringify({ isGuest: isGuest ? 1 : 0 })}`,
  security: (params: SecurityQueryParams) => `${ROUTES.SECURITY}?${formatQueryWithIsGuest(params)}`,
  teamHistory: (teamId: number, isGuest: boolean) =>
    `${generatePath(ROUTES.TEAM_HISTORY, { id: teamId })}?${formatQueryWithIsGuest({ isGuest })}`,
  personHistory: (personId: number, isGuest: boolean) =>
    `${generatePath(ROUTES.PERSON_HISTORY, { id: personId })}?${formatQueryWithIsGuest({
      isGuest,
    })}`,
  sendInvitation: (params: SendInvitationQueryParams) =>
    `${ROUTES.SEND_INVITATION}?${formatQueryWithIsGuest(params)}`,
  editPeople: (isGuest: boolean) => `${ROUTES.EDIT_PEOPLE}?${formatQueryWithIsGuest({ isGuest })}`,
  emailPeople: (params: EmailPeopleQueryParams) =>
    `${ROUTES.EMAIL_PEOPLE}?${formatQueryWithIsGuest(params)}`,
  documentGhostView: (teamId: number) => {
    return `${ROUTES.VIEW_DOCUMENT_INDEX}?view-as=${teamId}`;
  },
};
