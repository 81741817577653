// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import TranslationService from 'app/i18n/translate';
import { Toaster, Toast, toast, Icon, icon } from '@ansarada/ace-react';
import ToastMessageContents from 'app/components/toast-message/toast-message-contents';
import type { AppDispatch } from 'app/types/appStateTypes';
import toastActions from './actions';
import type { ToastMessageType, ToastMessageStatusType } from './types.js';

type ToastMessagesProps = {
  toasts: Array<ToastMessageType>,
  dispatch: AppDispatch,
};

const isAutoHide = (t: ToastMessageType) => t.status === 'success' && t.autoHide;

export const getToastIcon = (status: ToastMessageStatusType) => {
  if (status === 'none') return undefined;
  const toastIcon = status === 'success' ? icon.Glyphs.StatusSuccess : icon.Glyphs.StatusLoading;
  const toastIconText = status === 'success' ? 'Success' : 'Loading';

  return <Icon text={TranslationService(toastIconText)} glyph={toastIcon} />;
};

const getToastMessageContent = (toastMessageType: ToastMessageType) => {
  const ToastMessageContent = ToastMessageContents[toastMessageType.contentType];

  return <ToastMessageContent {...toastMessageType} />;
};

export const getActionText = (toastMessageType: ToastMessageType) => {
  if (toastMessageType.actionText || toastMessageType.actionText === '')
    return toastMessageType.actionText;

  return isAutoHide(toastMessageType) ? '' : TranslationService('Close');
};

type Props = ToastMessagesProps;

export const ToastMessages = (props: Props) => {
  const { toasts, dispatch } = props;

  return (
    <Toaster data-test-id="toaster-test-id" position={toast.Position.BOTTOM} offset={70}>
      {toasts.map(t => (
        <Toast
          key={t.id}
          icon={getToastIcon(t.status)}
          actionText={getActionText(t)}
          onAction={() => toastActions(dispatch).remove(t.id)}
          timeoutDuration={isAutoHide(t) ? 3000 : -1}
          onTimeout={() => {
            toastActions(dispatch).remove(t.id);
          }}
          text={getToastMessageContent(t)}
          data-ansarada-ccd
        />
      ))}
    </Toaster>
  );
};

const mapStateToProps = (state: Object): Object => {
  return state.toastMessage;
};

export default connect<_, _, *, *>(mapStateToProps)(ToastMessages);
