// @flow

import * as React from 'react';
import { Button, Dialog, FormattedContentBlock } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { AppDispatch } from 'app/types/appStateTypes';
import { ACTION_TYPES } from 'app/constants';
import { connect } from 'react-redux';
import type { RegistrationSettingsType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';

export type PropsType = {
  registrationSettings: RegistrationSettingsType,
  updateRegistrationSettingsLoading: boolean,
  dispatch: AppDispatch,
};

export const ToggleRegistrationsDialog = (props: PropsType) => {
  const { registrationSettings, updateRegistrationSettingsLoading, dispatch } = props;
  const registrationsEnabled = registrationSettings.IsEnabled;
  const content = registrationsEnabled
    ? translate('DisableRegistrationsInformation')
    : translate('EnableRegistrationsInformation');
  const closeDialog = () => {
    dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG,
      data: false,
    });
  };

  return (
    <Dialog
      title={
        registrationsEnabled
          ? translate('DisableRegistrations_SentenceCase')
          : translate('EnableRegistrations_SentenceCase')
      }
      onClose={closeDialog}
      footerPrimaryActions={[
        <Button
          key="cancel"
          onClick={closeDialog}
          data-test-id="cancel"
          disabled={updateRegistrationSettingsLoading}
        >
          {translate('Cancel')}
        </Button>,
        <Button
          variant="Primary"
          key="toggle"
          onClick={() => {
            dispatch({
              type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.UPDATE,
              data: { IsEnabled: !registrationsEnabled },
            });
          }}
          data-test-id="toggle-registration"
          loading={updateRegistrationSettingsLoading}
        >
          {registrationsEnabled
            ? translate('DisableRegistrationsAction')
            : translate('EnableRegistrationsAction')}
        </Button>,
      ]}
    >
      <FormattedContentBlock>{content}</FormattedContentBlock>
    </Dialog>
  );
};

const mapStateToProps = (state: Object): Object => ({
  registrationSettings: state.manageRegistration.registrationSettings,
  updateRegistrationSettingsLoading: state.manageRegistration.updateRegistrationSettingsLoading,
});

export default connect<_, _, *, *>(mapStateToProps)(ToggleRegistrationsDialog);
