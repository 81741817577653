// @flow

import translate from 'app/i18n/translate';
import * as React from 'react';
import { Button, Dialog, FormattedContentBlock } from '@ansarada/ace-react';

type WarningType = {
  visible: boolean,
  header: string,
  message: string,
  confirmText: string,
  isDanger?: boolean,
  isMedium?: boolean,
  sensitiveContent?: boolean,
  onCancel: () => void,
  onConfirm: () => void,
};

const WarningDialog = (props: WarningType) => {
  const {
    visible,
    onCancel,
    onConfirm,
    header,
    message,
    confirmText,
    isDanger,
    isMedium,
    sensitiveContent,
  } = props;

  if (!visible) return null;
  const toCancel = () => onCancel();
  const toConfirm = () => onConfirm();
  const footerActions = [
    <Button key="cancel" data-test-id="cancelButton" onClick={toCancel}>
      {translate('Cancel')}
    </Button>,
    <Button
      key="confirm"
      data-test-id="confirmButton"
      onClick={toConfirm}
      variant={isDanger ? 'Caution' : 'Primary'}
    >
      {confirmText}
    </Button>,
  ];
  return (
    <Dialog
      data-test-id="warningMessage"
      title={header}
      size={isMedium ? 'Medium' : 'Small'}
      onClose={toCancel}
      footerPrimaryActions={footerActions}
      data-ansarada-ccd={sensitiveContent || undefined}
    >
      <FormattedContentBlock>
        <p data-ansarada-ccd={sensitiveContent || undefined}>{message}</p>
      </FormattedContentBlock>
    </Dialog>
  );
};

export default WarningDialog;
