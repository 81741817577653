// @flow
import type { PeopleWithRole, PersonWithRole } from 'app/components/emailForm/EmailFormTypes';
import type { Team } from 'app/types/teamsTypes';
import { without, uniq } from 'ramda';
import * as React from 'react';
import { Checkbox, Icon, TreeGroup, TreeItem, Asset } from '@ansarada/ace-react';
import classNames from 'classnames';
import styles from './teamTreeGroup.scss';

type Props = {
  team: Team,
  people: PeopleWithRole,
  selectedKeys: Array<number>,
  onSelect: (Array<number>) => void,
  columnsCount: number,
  renderCells: (person: PersonWithRole) => React.Node,
  disabledRowSelectable?: boolean,
};

type State = {
  isExpanded: boolean,
};

class TeamTreeGroup extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      isExpanded: true,
    };
  }

  onToggleExpand = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  isSelectable = (person: PersonWithRole) => {
    return this.props.disabledRowSelectable ? true : !person.disabled;
  };

  onTeamUpdate = (checked: boolean) => {
    const { people, selectedKeys, onSelect } = this.props;
    const peopleIds = people.filter(this.isSelectable).map(person => person.personId);
    if (checked) {
      onSelect(uniq(selectedKeys.concat(peopleIds)));
    } else {
      onSelect(without(peopleIds, selectedKeys));
    }
  };

  onPersonUpdate(person: PersonWithRole, checked: boolean) {
    const { selectedKeys, onSelect } = this.props;
    if (checked) {
      onSelect(selectedKeys.concat([person.personId]));
    } else {
      onSelect(without([person.personId], selectedKeys));
    }
  }

  getTeamCheckStatus() {
    const { people, selectedKeys } = this.props;
    const selectablePeople = people.filter(this.isSelectable);
    const allSelected = selectablePeople.every(person => selectedKeys.includes(person.personId));
    return {
      disabled: !selectablePeople.length,
      checked: selectablePeople.length > 0 && allSelected,
      indeterminate:
        !allSelected && selectablePeople.some(person => selectedKeys.includes(person.personId)),
    };
  }

  render() {
    const { team, people, selectedKeys, columnsCount = 0 } = this.props;
    return (
      <React.Fragment>
        <TreeGroup expanded>
          <TreeItem colSpan={columnsCount + 1}>
            <div className={styles.teamItem} data-test-id={`teamTreeGroup-${team.teamId}`}>
              <button
                type="button"
                className={classNames(styles.expander, {
                  [styles.isExpanded]: this.state.isExpanded,
                })}
                onClick={this.onToggleExpand}
              >
                <Icon glyph="ControlCollapsed" />
              </button>
              <Checkbox
                label=""
                value="on"
                {...this.getTeamCheckStatus()}
                onChecked={checked => {
                  this.onTeamUpdate(checked);
                }}
              />
              <Asset
                iconColour={team.colour}
                name={team.name}
                variant="UserGroup"
                data-ansarada-ccd
              />
            </div>
          </TreeItem>
        </TreeGroup>
        {this.state.isExpanded &&
          people.map(person => (
            <TreeGroup
              level={1}
              key={person.personId}
              data-test-id="peopleItem"
              className={classNames({
                [styles.unselectable]: !this.isSelectable(person),
                [styles.disabled]: person.disabled,
              })}
            >
              <TreeItem className={styles.personFirstItem}>
                <Checkbox
                  checked={selectedKeys.includes(person.personId)}
                  label={person.name}
                  value="on"
                  disabled={!this.isSelectable(person)}
                  onChecked={checked => {
                    this.onPersonUpdate(person, checked);
                  }}
                  className={classNames({ [styles.disabled]: person.disabled })}
                  data-ansarada-ccd
                />
              </TreeItem>
              {// $FlowFixMe TreeGroup expect TreeItem
              this.props.renderCells(person)}
            </TreeGroup>
          ))}
      </React.Fragment>
    );
  }
}

export default TeamTreeGroup;
