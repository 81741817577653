// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { Progressbar } from '@ansarada/ace-react';
import { window } from 'app/config';
import { ACTION_TYPES } from 'app/constants';
import type { LoadingBarType } from './loadingBarTypes';

type LoadingBarProps = LoadingBarType & {
  dispatch: AppDispatch,
};

const COMPLETE = 100;
const UPDATE_TIME = 200;
const FINAL_DELAY = 1000;
const PROGRESS_STEP = 30;

export const newValue = (value: number) => {
  // To slow down progress near 100% but never reach 100%
  const smoothedProgressIncrease = PROGRESS_STEP * Math.cos(value * (Math.PI / 2 / COMPLETE));

  return value + smoothedProgressIncrease;
};

export class LoadingBar extends React.Component<LoadingBarProps> {
  constructor(props: LoadingBarProps) {
    super(props);
    this.boundSimulateProgress = this.simulateProgress.bind(this);
    this.boundReset = this.reset.bind(this);
  }

  componentDidMount() {
    if (this.props.loading) {
      this.launch();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: LoadingBarProps) {
    if (!this.props.loading && nextProps.loading) {
      this.launch();
    }
    if (!!this.progressInterval && !nextProps.loading) {
      this.props.dispatch({ type: ACTION_TYPES.LOADINGBAR_VALUE_UPDATE, data: COMPLETE });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    window.clearInterval(this.progressInterval);
    window.clearTimeout(this.animateEndig);
  }

  launch() {
    if (!this.progressInterval) {
      this.progressInterval = window.setInterval(this.boundSimulateProgress, UPDATE_TIME);
    }
    if (this.props.value === 100 && this.animateEndig) {
      window.clearTimeout(this.animateEndig);
    }
  }

  reset() {
    this.props.dispatch({ type: ACTION_TYPES.LOADINGBAR_VALUE_UPDATE, data: 0 });
    this.animateEndig = null;
  }

  simulateProgress() {
    if (this.props.value >= COMPLETE) {
      window.clearInterval(this.progressInterval);
      this.progressInterval = null;
      this.animateEndig = window.setTimeout(this.boundReset, FINAL_DELAY);
      return;
    }
    const nextValue = newValue(this.props.value);
    if (nextValue <= COMPLETE) {
      this.props.dispatch({ type: ACTION_TYPES.LOADINGBAR_VALUE_UPDATE, data: nextValue });
    }
  }

  animateEndig: ?Function;

  progressInterval: ?Function;

  boundReset: Function;

  boundSimulateProgress: Function;

  render() {
    if (!(this.props.value > 0 && this.props.value <= COMPLETE)) return null;
    return (
      <div id="dr-spa-loading-bar">
        <Progressbar percentValue={this.props.value} isPageContext />
      </div>
    );
  }
}

const mapStateToProps = (state: Object): Object => ({
  value: state.page.loadingBar.value,
  loading: state.page.loadingBar.loading,
});

export default connect<_, _, *, *>(mapStateToProps)(LoadingBar);
