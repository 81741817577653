// @flow

import * as React from 'react';
import {
  FormattedContentBlock,
  Link,
  Button,
  TableBodyLegacy,
  TableDataLegacy,
  TableLegacy,
  TableRowLegacy,
  AceText,
} from '@ansarada/ace-react';
import type { RegistrantType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import type { TeamsState } from 'app/pages/teams/teamsTypes';
import translate from 'app/i18n/translate';
import { makeTeamsItems } from 'app/pages/teams/teamsHelpers';
import RegistrantActions from './registrantActions/RegistrantActions';
import RegistrantStatus from './registrantStatus/RegistrantStatus';
import styles from './RegistrantList.scss';
import { renderRegistrantTitle } from './utils';

export type PropsType = {
  registrants: RegistrantType[],
  teams: TeamsState,
  setDeclineDialogVisible: (RegistrantType, boolean) => void,
  setDeleteDialogVisible: (RegistrantType, boolean) => void,
  setInviteDialogVisible: (RegistrantType, boolean) => void,
  setViewDetailsDialogVisible: (RegistrantType, boolean) => void,
};

const title = (registrant: RegistrantType) => {
  return renderRegistrantTitle({
    jobTitleId: registrant.JobTitleId,
    jobTitleName: registrant.JobTitleName,
    jobTitleGroupOther: registrant.JobTitleGroupOther,
    jobTitleOther: registrant.JobTitleOther,
    company: registrant.Company,
  });
};

const InvitedFrom = ({ teams, registrant }: { teams: TeamsState, registrant: RegistrantType }) => {
  if (!registrant.RequestedBySubTeamID) {
    return null;
  }

  const teamsItems = makeTeamsItems(teams.pageData.teams || []);
  const team = teamsItems.find(x => x.subteamId === registrant.RequestedBySubTeamID);

  return (
    <span data-test-id="registrant-invited-from">
      <AceText text={`Invited from ${team ? team.text : ''}`} />
    </span>
  );
};

const RegistrantList = ({
  registrants,
  teams,
  setDeclineDialogVisible,
  setDeleteDialogVisible,
  setInviteDialogVisible,
  setViewDetailsDialogVisible,
}: PropsType) => {
  return (
    <TableLegacy data-test-id="registrant-list">
      <TableBodyLegacy>
        {registrants.map(registrant => {
          const additionalEmailSize = registrant.AdditionalEmails?.length;

          return (
            <TableRowLegacy key={registrant.Id} className={styles.registrationRow}>
              <TableDataLegacy className={styles.nameColumn} data-ansarada-ccd>
                <FormattedContentBlock>
                  <Button
                    onClick={() => setViewDetailsDialogVisible(registrant, true)}
                    variant="SubtleLink"
                  >
                    {registrant.FullName}
                  </Button>
                  <div className={styles.extraInfo} data-test-id="title-info">
                    {title(registrant)}
                  </div>
                </FormattedContentBlock>
              </TableDataLegacy>
              <TableDataLegacy className={styles.registrationColumn} data-ansarada-ccd>
                <Link data-test-id="mail" href={`mailto:${registrant.Email}`}>
                  {registrant.Email}
                </Link>
                {!!additionalEmailSize && (
                  <div className={styles.extraInfo} data-test-id="additional-email-size">
                    {translate('OtherTeamMembers', { size: additionalEmailSize })}
                  </div>
                )}
              </TableDataLegacy>
              <TableDataLegacy className={styles.registrationColumn}>
                <div />
              </TableDataLegacy>
              <TableDataLegacy className={styles.registrationColumn}>
                <RegistrantStatus status={registrant.Status} />
              </TableDataLegacy>
              <TableDataLegacy className={styles.invitedFromColumn}>
                <InvitedFrom teams={teams} registrant={registrant} />
              </TableDataLegacy>
              <TableDataLegacy className="ace-table-cell-control">
                <RegistrantActions
                  registrant={registrant}
                  setDeclineDialogVisible={setDeclineDialogVisible}
                  setDeleteDialogVisible={setDeleteDialogVisible}
                  setInviteDialogVisible={setInviteDialogVisible}
                  setViewDetailsDialogVisible={setViewDetailsDialogVisible}
                />
              </TableDataLegacy>
            </TableRowLegacy>
          );
        })}
      </TableBodyLegacy>
    </TableLegacy>
  );
};

export default RegistrantList;
