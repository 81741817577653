// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { bindActionCreators } from 'redux';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import { ACTION_TYPES } from 'app/constants';
import type { PermissionsType } from 'app/types/permissionsTypes';
import { isFooterVisible } from 'app/utils/dom';
import { NavigationWarning } from 'app/components/navigationWarning/NavigationWarning';
import SaveChangesBar from 'app/pages/security/saveChangesBar/SaveChangesBar';
import TreeHeader from 'app/pages/security/documentIndex/treeHeader/TreeHeader';
import { toggleDialog } from 'app/components/dialog/dialogActions';
import { getLocationProps } from 'app/uri/url';
import { links } from 'app/links';
import { DataRoomType } from 'app/types/dataRoomTypes';
import TenderBidderEditSecurityBannerMessage from 'app/pages/security/TenderBidderEditSecurityBannerMessage';
import { mapToDocumentIndex } from '../helpers/treeHelpers';
import type { DocumentIndexItem, FolderActionsType, WatermarkType } from '../securityTypes';
import { expandFolder, setDialogType, setSecuritySettings } from '../securityActions';
import { DocumentIndexTable } from './DocumentIndexTable';
import { setAppcuesTeamVariables, showAppcuesFlow } from './Helpers/appcuesHelper';
import styles from './documentIndex.scss';

export type DocumentIndexProps = {
  index: Array<DocumentIndexItem>,
  watermarks: Array<WatermarkType>,
  actions: FolderActionsType & {
    setDialogType: Function,
    toggleDialog: Function,
    setSecuritySettings: Function,
  },
  dispatch: AppDispatch,
  loading: boolean,
  permissions: PermissionsType,
  session: SessionType,
  hasUnsavedData: boolean,
  location?: Object,
};

export class DocumentIndex extends React.Component<DocumentIndexProps> {
  shouldComponentUpdate(nextProps: DocumentIndexProps) {
    return !nextProps.loading && !!nextProps.permissions;
  }

  componentDidUpdate(prevProps: DocumentIndexProps) {
    // We might need to shift the footer up/down as items are expanded
    const changedItems = this.props.index.length !== prevProps.index.length;
    const nowHasUnsavedData = !prevProps.hasUnsavedData && this.props.hasUnsavedData;

    if (changedItems || nowHasUnsavedData) {
      isFooterVisible(this.props.dispatch);
    }
  }

  componentWillUnmount() {
    const { hasUnsavedData, dispatch } = this.props;
    if (hasUnsavedData) {
      dispatch({ type: ACTION_TYPES.SECURITY.CLEAR_CHANGES });
    }
  }

  redirectToGhostView(subTeamId: number) {
    const spaUrlSegment = links.documentGhostView(subTeamId);
    if (spaUrlSegment) {
      const { dataRoomProjectName, dataroomId, userId } = this.props.session;
      window.location.replace(
        `/_mvc/${dataRoomProjectName}|${dataroomId}/${userId}${spaUrlSegment}`,
        '',
      );
    }
  }

  render() {
    const { dispatch, permissions, index, hasUnsavedData, session } = this.props;
    const locationProps = getLocationProps(this.props.location);
    const subteamId = Number(locationProps.subteamIds);
    const isAutoRedirectToGhostView = locationProps.autoRedirectToGhostView === 'true';
    const isGuest = locationProps.isGuest === '1';

    if (!index || !(index.length > 0) || !permissions) return null;

    const teamUsesSod = index[0].securities[0].useSod;
    const useSod = session.dataRoomUseSod && teamUsesSod;
    setAppcuesTeamVariables(useSod);

    return (
      <Box>
        {isGuest && session.dataRoomType === DataRoomType.Tender && (
          <TenderBidderEditSecurityBannerMessage sx={{ my: 2 }} />
        )}

        <div data-test-id="documentsTab" className={styles.scrollWrapper}>
          <div className={styles.container}>
            <TreeHeader
              setDialogType={this.props.actions.setDialogType}
              toggleDialog={this.props.actions.toggleDialog}
              documentSecurityEnabled={session.documentSecurityEnabled}
              showAppcuesFlow={() => {
                showAppcuesFlow(session.disableDownloadWhenSavingDenied, useSod);
              }}
            />

            <WindowScroller>
              {({ height, onChildScroll, scrollTop }) => (
                <AutoSizer className="document-index-content" disableHeight>
                  {({ width }) => (
                    <React.Fragment>
                      <div className="transition-cover" />
                      <DocumentIndexTable
                        width={width}
                        height={height}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        {...this.props}
                      />
                    </React.Fragment>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </div>

          {hasUnsavedData && (
            <React.Fragment>
              <SaveChangesBar
                onSave={() => {
                  dispatch({ type: ACTION_TYPES.SECURITY.SETTINGS_SAVE });
                  if (isAutoRedirectToGhostView) {
                    this.redirectToGhostView(subteamId);
                  }

                  dispatch({ type: ACTION_TYPES.SEGMENT_EVENTS.TEAM_SECURITY_EDITED });
                  dispatch({ type: ACTION_TYPES.SEGMENT_EVENTS.TEAM_ACCESS_EDITED });
                  dispatch({ type: ACTION_TYPES.SEGMENT_EVENTS.TEAM_WATERMARK_EDITED });
                }}
                onReset={() => dispatch({ type: ACTION_TYPES.SECURITY.CLEAR_CHANGES })}
              />
              {!isAutoRedirectToGhostView && <NavigationWarning />}
            </React.Fragment>
          )}
        </div>
      </Box>
    );
  }
}

export const mapStateToProps = (state: Object) => ({
  index: mapToDocumentIndex(
    // TODO: Move this up
    state.security.documents.treeData.tree,
    state.security.documents.treeData.index,
    state.security.documents.viewData.expanded,
  ),
  watermarks: state.security.documents.pageData.watermarks,
  loading: state.page.loadingBar.loading,
  permissions: state.page.userPermissions,
  hasUnsavedData: !!Object.keys(state.security.documents.treeData.edited).length,
  session: state.session,
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    { expandFolder, setDialogType, toggleDialog, setSecuritySettings },
    dispatch,
  ),
  dispatch,
});

export default connect<_, _, *, *, *>(mapStateToProps, mapDispatchToProps)(DocumentIndex);
