// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import BannerMessage from 'app/components/bannerMessage/BannerMessage';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import 'app/style/_bannerMessage.scss';
import type { BannerMessagesDataType } from 'app/components/bannerMessage/bannerMessageTypes';

type BannerMessagesPropsType = {
  messages: BannerMessagesDataType,
  dispatch: AppDispatch,
};

export const BannerMessages = (props: BannerMessagesPropsType) => {
  const { messages, dispatch } = props;
  return (
    <TransitionGroup>
      {messages.map((message, i) => {
        const key = `banner-transition-${i}`;
        return (
          <CSSTransition key={key} classNames="bannerMessage" timeout={{ enter: 500, exit: 300 }}>
            <BannerMessage message={message} dispatch={dispatch} key={message.id} />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

const mapStateToProps = (state: Object): Object => ({
  messages: state.notifications.bannerMessages,
});

export default connect<_, _, *, *>(mapStateToProps)(BannerMessages);
