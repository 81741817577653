// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { Button } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { getMessageCode } from 'app/components/bannerMessageContent/bannerMessageContentHelpers';
import type { MessageContentType } from 'app/components/bannerMessageContent/bannerMessageContentTypes';

type Props = MessageContentType & {
  dispatch: AppDispatch,
};

const BannerMessageContent = ({ code, description, link, dispatch }: Props) => (
  <div data-test-id="bannerMessageContent">
    {code ? translate(getMessageCode(code)) : description}

    {link && (
      <Button onClick={() => link && dispatch(link.dispatchAction)} variant="Link">
        {link.text}
      </Button>
    )}
  </div>
);

export default BannerMessageContent;
