"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureLicense = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var useFeatureLicense = function () {
    var features = (0, react_redux_1.useSelector)(function (state) { return state.page.licences.features; });
    var checkFeatureEnabled = (0, react_1.useCallback)(function (featureKey) { return features[featureKey]; }, [features]);
    return {
        checkFeatureEnabled: checkFeatureEnabled,
    };
};
exports.useFeatureLicense = useFeatureLicense;
