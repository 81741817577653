// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import {
  Message as ACEMessage,
  Close as ACEClose,
  message as ACEmessage,
} from '@ansarada/ace-react';
import { window } from 'app/config';
import { BANNER_MESSAGE_TYPES } from 'app/components/bannerMessage/bannerMessageTypes';
import BannerMessageContent from 'app/components/bannerMessageContent/BannerMessageContent';
import { ACTION_TYPES } from 'app/constants';
import type {
  BannerMessageDataType,
  BannerMessageType,
} from 'app/components/bannerMessage/bannerMessageTypes';

type BannerMessagePropsType = {
  message: BannerMessageDataType,
  dispatch: AppDispatch,
};

export const bannerMessageTypeToMessageLevel = (type: BannerMessageType) => {
  switch (type) {
    case BANNER_MESSAGE_TYPES.ERROR:
      return ACEmessage.Variants.Critical;

    case BANNER_MESSAGE_TYPES.WARN:
      return ACEmessage.Variants.Bad;

    case BANNER_MESSAGE_TYPES.SUCCESS:
      return ACEmessage.Variants.Good;

    default:
      return ACEmessage.Variants.Information;
  }
};

class BannerMessage extends React.Component<BannerMessagePropsType> {
  componentDidMount() {
    const { message } = this.props;
    if (message.autoDismiss) {
      window.setTimeout(this.onUpdate, message.autoDismissDelay || 3000);
    }
  }

  onUpdate = () => {
    const { dispatch, message } = this.props;
    dispatch({
      type: ACTION_TYPES.BANNER_MESSAGES.ITEM_DISMISS,
      data: message.id,
    });
  };

  render() {
    const { message, dispatch } = this.props;
    return (
      <ACEMessage
        style={{ zIndex: 10003 }}
        id={message.id}
        variant={bannerMessageTypeToMessageLevel(message.type)}
        className="ace-message-pagecontext"
        close={<ACEClose onUpdate={this.onUpdate} />}
      >
        <BannerMessageContent {...message.content} dispatch={dispatch} />
      </ACEMessage>
    );
  }
}

export default BannerMessage;
