// @flow

import * as React from 'react';
import classnames from 'classnames';
import { Asset as ACEAsset, asset, Icon, icon, TruncateText } from '@ansarada/ace-react';
import { getDocumentType } from 'app/utils/helpers';
import translate from 'app/i18n/translate';
import styles from 'app/pages/security/subjectSecurityPage/subjectSecurityView.scss';
import documentStyles from './styles.scss';

class Document extends React.Component<{
  name: string,
  extension: ?string,
  isDisabled: number,
}> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="dr-spa-tree-item ace-tree-item">
        <Icon glyph={icon.Glyphs.NOGLYPH} className="tree-item-icon-helper" />

        {!!this.props.isDisabled && (
          <Icon
            text={translate('Disabled')}
            glyph={icon.Glyphs.StatusDisabled}
            className={styles.subjectIcon}
          />
        )}
        <ACEAsset
          variant={asset.Variants.File}
          title={this.props.name}
          fileType={getDocumentType(this.props.extension)}
          name={<TruncateText truncatePosition="Tail">{this.props.name}</TruncateText>}
          className={classnames({
            'transition-blur': true,
            [documentStyles.documentNameWrapper]: true,
          })}
          disabled={!!this.props.isDisabled}
          data-test-id="asset"
          data-ansarada-ccd
        />
      </div>
    );
  }
}

export default Document;
