// @flow
import type { AppDispatch } from 'app/types/appStateTypes';
import type { ToastMessageType } from './types';

const actions = (dispatch: AppDispatch) => ({
  remove(id: string) {
    dispatch({
      type: 'TOAST_MESSAGE:REMOVE',
      data: {
        id,
      },
    });
  },

  add(toast: ToastMessageType) {
    dispatch({
      type: 'TOAST_MESSAGE:ADD',
      data: toast,
    });
  },

  update(toast: ToastMessageType) {
    dispatch({
      type: 'TOAST_MESSAGE:UPDATE',
      data: toast,
    });
  },
});

export default actions;
