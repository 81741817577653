// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Tabs, Tab } from '@ansarada/ace-react';
import type { RouterHistory } from 'react-router-dom';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import type { PermissionsType } from 'app/types/permissionsTypes';
import {
  ACTION_TYPES,
  PAGE_SECTIONS,
  PAGE_TITLES,
  SECURITY_DIALOG_TYPES,
  ROUTES,
} from 'app/constants';
import RoomLayout from 'app/layout/roomLayout';
import { window } from 'app/config';
import { isFooterVisible } from 'app/utils/dom';
import { getLocationProps } from 'app/uri/url';
import translate from 'app/i18n/translate';
import DocumentIndex from 'app/pages/security/documentIndex/DocumentIndex';
import SubjectSecurityPage from 'app/pages/security/subjectSecurityPage/SubjectSecurityPage';
import CopySecurityDialog from 'app/pages/security/copySecurityDialog/CopySecurityDialog';
import SecurityHeader from 'app/pages/security/securityHeader/SecurityHeader';
import SecurityHelperDialog from './securityHelperDialog/SecurityHelperDialog';
import type { SecurityDialogType, Team } from './securityTypes';
import SecurityActions from './securityActions/SecurityActions';
import TenderSubmissionsSecurityPage from './tenderSubmissionsSecurityPage/TenderSubmissionsSecurityPage';

const TAB_ROUTES = [
  ROUTES.SECURITY,
  ROUTES.SECURITY_QNA_SECURITY,
  ROUTES.SECURITY_TENDER_SUBMISSION,
];

export const getDialog = (dialogType: SecurityDialogType) => {
  switch (dialogType) {
    case SECURITY_DIALOG_TYPES.COPY_SECURITY:
      return <CopySecurityDialog />;
    case SECURITY_DIALOG_TYPES.SECURITY_HELPER:
      return <SecurityHelperDialog />;
    default:
      return null;
  }
};

type SecurityTabsProps = {|
  activeTab: number,
  locationSearch: string,
  historyPush: string => void,
  qnaEnabled: boolean,
  submissionToolEnabled: boolean,
|};

const SecurityTabs = ({
  activeTab,
  locationSearch,
  historyPush,
  qnaEnabled,
  submissionToolEnabled,
}: SecurityTabsProps) =>
  (qnaEnabled || submissionToolEnabled) && (
    <Tabs
      active={activeTab}
      onUpdate={link => {
        if (link !== activeTab) historyPush(`${TAB_ROUTES[Number(link)]}${locationSearch}`);
      }}
    >
      <Tab header={translate('Documents')} id="document-security" />
      {qnaEnabled && <Tab header={translate('QnaSubjects')} id="subject-security" />}
      {submissionToolEnabled && <Tab header={translate('Submissions')} id="submission-security" />}
    </Tabs>
  );

type SecurityPageProps = {|
  dialogType: SecurityDialogType,
  permissions: PermissionsType,
  teams: Array<Team>,
  team: Team,
  dispatch: AppDispatch,
  location: Object,
  history: RouterHistory,
  submissionToolEnabled: boolean,
  qnaEnabled: boolean,
|};

export class SecurityPage extends React.Component<SecurityPageProps> {
  componentDidMount() {
    const { dispatch, location } = this.props;

    const locationProps = getLocationProps(location);
    const subteamId = Number(locationProps.subteamIds);
    const isGuest = locationProps.isGuest === '1';

    dispatch({ type: ACTION_TYPES.PAGE.SECTION_SET, data: PAGE_SECTIONS.SECURITY });
    dispatch({ type: ACTION_TYPES.SECURITY.DATA_FETCH_INITIAL, data: { subteamId, isGuest } });
    dispatch({ type: ACTION_TYPES.SECURITY.FETCH_USER_QNA_ROLE });

    isFooterVisible(dispatch);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.dispatch({ type: ACTION_TYPES.SECURITY.RESET_STATE });
  }

  handleScroll = () => {
    isFooterVisible(this.props.dispatch);
  };

  render() {
    const {
      dialogType,
      permissions,
      dispatch,
      team,
      teams,
      qnaEnabled,
      submissionToolEnabled,
      location,
      history,
    } = this.props;

    // TODO HOME-313: Show the loading spinner or redesign the loading state so it's consistent across all pages
    if (!team) return null;

    const activeTab = TAB_ROUTES.indexOf(location.pathname);

    const actionButtons = (
      <SecurityActions
        activeTab={activeTab}
        dispatch={dispatch}
        hasUnsavedData={false}
        permissions={permissions}
        teams={teams}
        team={team}
      />
    );

    return (
      <RoomLayout
        data-test-id="securityPage"
        actionButtons={actionButtons}
        title={PAGE_TITLES.SECURITY}
      >
        <SecurityHeader />

        <SecurityTabs
          activeTab={activeTab}
          locationSearch={location.search}
          historyPush={history.push}
          qnaEnabled={qnaEnabled}
          submissionToolEnabled={submissionToolEnabled}
        />

        <Route path={ROUTES.SECURITY} exact component={DocumentIndex} />

        {qnaEnabled && (
          <Route path={ROUTES.SECURITY_QNA_SECURITY} component={SubjectSecurityPage} />
        )}

        {submissionToolEnabled && (
          <Route
            path={ROUTES.SECURITY_TENDER_SUBMISSION}
            component={TenderSubmissionsSecurityPage}
          />
        )}

        {getDialog(dialogType)}
      </RoomLayout>
    );
  }
}

export const mapStateToProps = (state: AppStateType): Object => ({
  dialogType: state.security.documents.viewData.dialogType,
  loading: state.page.loadingBar.loading,
  team: state.security.documents.pageData.team,
  teams: state.security.documents.pageData.teams,
  permissions: state.page.userPermissions,
  qnaEnabled: state.session.qnaEnabled,
  submissionToolEnabled: state.session.submissionToolEnabled,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(SecurityPage);
