// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  Form,
  FormMultiGroup,
  forms,
  FormSection,
  FormSingleGroup,
  FormSubItem,
  TextInput,
} from '@ansarada/ace-react';
import FieldErrors from 'app/components/fieldErrors/FieldErrors';
import translate from 'app/i18n/translate';
import Dialog from 'app/components/dialog/Dialog';
import HelpLink from 'app/components/helpLink/HelpLink';
import ColourPicker from 'app/components/colourPicker/ColourPicker';
import * as url from 'app/uri/url';
import FormInputHelp from 'app/pages/teams/formInputHelp/FormInputHelp';
import { ACTION_TYPES, TEAM_DIALOG_TYPES } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import type { ThirdPartyIntegrations } from 'app/types/teamsTypes';
import type { AppStateType } from 'app/types/appStateTypes.js';
import store from 'app/store';
import { getFeature2Data } from 'app/utils/feature2';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { TeamFormType } from '../teamsTypes';
import * as actions from './teamFormDialogActions';
import * as teamFormDialodHelpers from './teamFormDialogHelpers';

type TeamFormPropsType = {
  teamForm: TeamFormType,
  dispatch: AppDispatch,
  colourPickerVisible: boolean,
  colourData?: Array<Array<string>>,
  session: SessionType,
  permissions: PermissionsType,
};

export const FormFieldName = (props: {
  formData: TeamFormType,
  dispatch: AppDispatch,
  inputRef?: () => ?HTMLInputElement,
}) => {
  const { formData, dispatch, inputRef } = props;
  const { data, errors } = formData;
  const name = data.isSubteam ? translate('SubteamName') : translate('TeamName');
  const [textValue, setTextValue] = React.useState(data.name);
  return (
    <FormSingleGroup contentType={forms.ContentType.Text} label={name}>
      <TextInput
        data-test-id="teamNameInput"
        inputRef={inputRef}
        maxLength={50}
        data-ansarada-ccd
        onChangeValue={value => {
          dispatch({
            type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
            data: { key: 'name', value },
          });
          setTextValue(value);
        }}
        value={textValue}
        autoComplete="off"
        status={errors.name ? 'Bad' : 'Good'}
      />

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <FieldErrors errors={errors.name} />
    </FormSingleGroup>
  );
};

export const FormFieldColour = (props: {
  formData: TeamFormType,
  colourPickerVisible: boolean,
  colourData: Array<Array<string>>,
  dispatch: AppDispatch,
}) => {
  const { formData, dispatch, colourPickerVisible, colourData } = props;
  const { data, errors } = formData;
  return (
    <FormSingleGroup contentType={forms.ContentType.Input} label={translate('Colour')}>
      {/* $FlowFixMe: No ColourPicker in ACE */}
      <ColourPicker
        colour={data.colour}
        onToggle={() => dispatch({ type: ACTION_TYPES.COLOURPICKER_TOGGLE })}
        onChange={e =>
          dispatch({
            type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
            data: { key: 'colour', value: e },
          })
        }
        isVisible={colourPickerVisible}
        colourData={colourData}
        dispatch={dispatch}
      />

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <FieldErrors errors={errors.colour} />
    </FormSingleGroup>
  );
};

export const FormFieldPermissions = (props: {
  formData: TeamFormType,
  session: SessionType,
  dispatch: AppDispatch,
}) => {
  const { formData, dispatch, session } = props;
  const { data, errors } = formData;

  const [thirdPartyIntegrationsAllowed, setThirdPartyIntegrationsAllowed] = React.useState<
    'all' | 'none' | 'partial' | 'hidden' | null,
  >(() => {
    const { thirdPartyIntegrationsDataRoom } = store.getState().teams.pageData;

    return teamFormDialodHelpers.getThirdPartyIntegrationsAllowedState(
      thirdPartyIntegrationsDataRoom,
      data.thirdPartyIntegrations,
    );
  });

  React.useEffect(() => {
    const enableThirdPartyIntegrations = getFeature2Data(
      'dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings',
    );
    if (enableThirdPartyIntegrations) {
      const { thirdPartyIntegrationsDataRoom } = store.getState().teams.pageData;
      const newThirdPartyIntegrationsAllowed = teamFormDialodHelpers.getThirdPartyIntegrationsAllowedState(
        thirdPartyIntegrationsDataRoom,
        data.thirdPartyIntegrations,
      );

      setThirdPartyIntegrationsAllowed(newThirdPartyIntegrationsAllowed);
    }
  }, [data.thirdPartyIntegrations, formData.data.thirdPartyIntegrations]);

  const handleCheckThirdPartyIntegrations = (checked: boolean) => {
    if (data.thirdPartyIntegrations) {
      const newThirdPartyIntegrations: ThirdPartyIntegrations[] = data.thirdPartyIntegrations.map(
        item => ({
          ...item,
          isAllowed: checked,
        }),
      );

      dispatch({
        type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
        data: { key: 'thirdPartyIntegrations', value: newThirdPartyIntegrations },
      });
    }
  };

  return (
    <FormMultiGroup contentType={forms.ContentType.Mixed} legend={translate('TeamPermissions')}>
      <FormSubItem>
        {/* $FlowFixMe ACE requires unnecessary value attribute */}
        <Checkbox
          data-test-id="fullTextSearch"
          name="chk-fullTextSearch"
          id="chk-fullTextSearch"
          label={translate('FullText')}
          checked={data.fullTextSearchAllowed}
          onChecked={checked =>
            dispatch({
              type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
              data: { key: 'fullTextSearchAllowed', value: checked },
            })
          }
          helpLink={
            /* $FlowFixMe: Not a ACE React component */
            <HelpLink helperLinkContent="fullTextSearch" dispatch={dispatch} />
          }
        />
        {/* $FlowFixMe: Not a ACE React component */}
        <FieldErrors errors={errors.fullTextSearchAllowed} />
        <FormInputHelp
          text={translate('FullTextText')}
          findMoreUrl={url.fullTextSearch}
          topic="fullTextSearch"
        />
      </FormSubItem>

      <FormSubItem>
        {/* $FlowFixMe ACE requires unnecessary value attribute */}
        <Checkbox
          data-test-id="bulkDownload"
          name="chk-bulkDownload"
          id="chk-bulkDownload"
          label={translate('BulkDownload_SentenceCase')}
          checked={data.bulkDownloadAllowed}
          onChecked={checked =>
            dispatch({
              type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
              data: { key: 'bulkDownloadAllowed', value: checked },
            })
          }
          helpLink={
            /* $FlowFixMe: Not a ACE React component */
            <HelpLink helperLinkContent="bulkDownload" dispatch={dispatch} />
          }
        />
        {/* $FlowFixMe: Not a ACE React component */}
        <FieldErrors errors={errors.bulkDownloadAllowed} />
        <FormInputHelp
          text={translate('BulkDownloadText')}
          findMoreUrl={url.bulkDownload}
          topic="bulkDownload"
        />
      </FormSubItem>
      {session.documentSecurityEnabled && session.dataRoomUseSod && (
        <FormSubItem>
          {/* $FlowFixMe ACE requires unnecessary value attribute */}
          <Checkbox
            data-test-id="secureOfficeDocuments"
            name="chk-sod"
            id="chk-sod"
            className="dr-secure-office-checkbox"
            label={translate('SecureOfficeTitle')}
            checked={data.useSod}
            onChecked={checked =>
              dispatch({
                type: ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE,
                data: { key: 'useSod', value: checked },
              })
            }
            helpLink={
              /* $FlowFixMe: Not a ACE React component */
              <HelpLink helperLinkContent="sod" dispatch={dispatch} />
            }
          />
          <FormInputHelp
            text={translate('SecureOfficeText')}
            findMoreUrl={url.manageSOD}
            topic="sod"
            warning={translate('SecureOfficeWarning')}
          />
          {/* $FlowFixMe: Not a ACE React component */}
          <FieldErrors errors={errors.useSod} />
        </FormSubItem>
      )}
      {!!thirdPartyIntegrationsAllowed &&
        thirdPartyIntegrationsAllowed !== 'hidden' &&
        getFeature2Data(
          'dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings',
        ) && (
          <FormSubItem>
            {/* $FlowFixMe ACE requires unnecessary value attribute */}
            <Checkbox
              data-test-id="thirdPartyIntegrations"
              name="chk-thirdPartyIntegrations"
              id="chk-thirdPartyIntegrations"
              label={translate('ThirdPartyIntegrations_SentenceCase')}
              checked={thirdPartyIntegrationsAllowed === 'all'}
              indeterminate={thirdPartyIntegrationsAllowed === 'partial'}
              onChecked={handleCheckThirdPartyIntegrations}
              helpLink={<HelpLink helperLinkContent="thirdPartyIntegrations" dispatch={dispatch} />}
            />
            {/* $FlowFixMe: Not a ACE React component */}
            <FieldErrors errors={errors.thirdPartyIntegrations} />
            <FormInputHelp
              text={translate('ThirdPartyIntegrationsText')}
              findMoreUrl={url.thirdpartyintegrations}
              topic="thirdPartyIntegrations"
            />
          </FormSubItem>
        )}
    </FormMultiGroup>
  );
};

export class TeamForm extends React.Component<TeamFormPropsType> {
  componentDidMount() {
    setTimeout(() => {
      if (this.teamNameInput) {
        this.teamNameInput.focus();
      }
    }, 200);
  }

  teamNameInput: ?HTMLButtonElement;

  render() {
    const {
      teamForm,
      dispatch,
      colourPickerVisible,
      colourData,
      session,
      permissions,
    } = this.props;
    const { data } = teamForm;
    return (
      <Form>
        <FormSection header="editing-team-form" assistiveHeader>
          {/* $FlowFixMe: Not a ACE React component or mixed component */}
          <FormFieldName
            inputRef={teamNameInput => {
              this.teamNameInput = teamNameInput;
            }}
            formData={teamForm}
            dispatch={dispatch}
          />
          {!data.isSubteam && (
            /* $FlowFixMe: Not a ACE React component or mixed component */
            <FormFieldColour
              formData={teamForm}
              colourData={colourData}
              colourPickerVisible={colourPickerVisible}
              dispatch={dispatch}
            />
          )}
          {/* $FlowFixMe: Not a ACE React component or mixed component */}
          {permissions.document.modify && (
            <FormFieldPermissions formData={teamForm} session={session} dispatch={dispatch} />
          )}
        </FormSection>
      </Form>
    );
  }
}

type TeamFormDialogPropsType = {
  teamForm: TeamFormType,
  colourPickerVisible: boolean,
  colourData?: Array<Array<string>>,
  session: SessionType,
  permissions: Object,
};

export const TeamFormDialog = (props: TeamFormDialogPropsType & { dispatch: AppDispatch }) => {
  const { teamForm, dispatch, colourPickerVisible, colourData, session, permissions } = props;
  const { isSubteam } = teamForm.data;
  let triggerButtonText;
  let hideSensitiveContent;

  switch (teamForm.data.type) {
    case TEAM_DIALOG_TYPES.EDIT:
      triggerButtonText = isSubteam ? translate('EditSubteam') : translate('EditTeam');
      hideSensitiveContent = true;
      break;
    case TEAM_DIALOG_TYPES.COPY:
      triggerButtonText = translate('CopyTeam');
      break;
    case TEAM_DIALOG_TYPES.CREATE:
    default:
      triggerButtonText = isSubteam ? translate('CreateSubteam') : translate('CreateTeam');
  }

  const errorMessage = isSubteam
    ? translate('SubteamNameValidation')
    : translate('TeamNameValidation');

  return (
    <Dialog
      data-test-id="teamFormDialog"
      triggerButton={{
        'data-test-id': 'drTeamsDialogAddTeamBtn',
        children: triggerButtonText,
        variant: 'Primary',
        onClick: () => dispatch(actions.teamFormValidate(teamForm.data, errorMessage)),
      }}
      sensitiveContent={hideSensitiveContent}
    >
      <TeamForm
        teamForm={teamForm}
        dispatch={dispatch}
        colourPickerVisible={colourPickerVisible}
        colourData={colourData}
        session={session}
        permissions={permissions}
      />
    </Dialog>
  );
};

export const mapStateToProps = (state: AppStateType): TeamFormDialogPropsType => ({
  teamForm: state.teams.viewData.teamForm,
  colourPickerVisible: state.teams.viewData.colourPickerOn,
  colourData: state.teams.viewData.colourPickerData,
  session: state.session,
  permissions: state.page.userPermissions,
});

export default connect<_, _, *, *>(mapStateToProps)(TeamFormDialog);
