// @flow

import { errorValidate } from 'app/utils/error';
import type { TeamFormData } from 'app/pages/teams/teamsTypes';
import { ACTION_TYPES } from 'app/constants';

export const teamFormValidate = (data: TeamFormData, message: string) => {
  const errors = {};
  if (!data.name) {
    errors.name = errorValidate(errors.name, message);
  }
  return Object.keys(errors).length
    ? {
        type: ACTION_TYPES.TEAMS.TEAM_FORM_ERRORS,
        data: errors,
      }
    : {
        type: ACTION_TYPES.TEAMS.TEAM_SAVE,
        data,
      };
};
