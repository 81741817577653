// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { FormSingleGroup, Label } from '@ansarada/ace-react';
import { AutocompleteLegacy } from 'app/components/autocompleteLegacy';
import type { DisplayType } from 'app/components/autocompleteLegacy';
import type { FilterItem } from 'app/components/multiSelect/multiSelectTypes';

type MultiSelectPropTypes = {
  dispatch: AppDispatch,
  filterDisplayData: DisplayType,
  filterName: string,
  items: Array<FilterItem>,
  filterLabel: string,
  update: (AppDispatch, string, Array<FilterItem>, string, string) => void,
  sensitiveContent?: boolean,
};

const MultiSelect = (props: MultiSelectPropTypes) => {
  const {
    dispatch,
    filterDisplayData,
    filterLabel,
    filterName,
    items,
    update,
    sensitiveContent,
  } = props;
  return (
    <FormSingleGroup label={<Label forEl={`${filterName}-input`} text={filterLabel} />}>
      {/* $FlowFixMe */}
      <AutocompleteLegacy
        data-test-id={filterName}
        id={filterName}
        multiSelect
        display={filterDisplayData}
        onUpdate={(value, msg) => update(dispatch, filterName, items, value, msg)}
        data-ansarada-ccd={sensitiveContent}
      />
    </FormSingleGroup>
  );
};

export default MultiSelect;
