// @flow

import {
  TableLegacy,
  TableBodyLegacy,
  TableDataLegacy,
  TableHeadLegacy,
  TableHeaderLegacy,
  TableRowLegacy,
  Icon,
} from '@ansarada/ace-react';
import { path, sort, comparator, reverse } from 'ramda';
import * as React from 'react';
import classNames from 'classnames';
import styles from './sortableTable.scss';

type ColumnType = {
  title: React.Node,
  key: string,
  // With dataIndex, you don't need to specify renderCell function. It could be 'team.name'
  // Also it will be used to get content for sorting column.
  dataIndex: string,
  renderCell?: (text: any, record: Object) => React.Node,
  width?: string,
  // If sorter is true, it will be sort with default sort function.
  // If you have more special requirement, you could have your own sort function.
  sorter?: boolean | ((a: any, b: any) => number),
  headerTestId?: string,
  sensitiveContent?: boolean,
};

type SortableTablePropType = {
  className?: string,
  columns: Array<ColumnType>,
  dataSource: Array<Object>,
  rowKey?: string,
  // If you set sortOrder for certain column, it will be used to sort.
  defaultSortColumnKey?: string,
  defaultSortOrder?: 'Descending' | 'Ascending',
  'data-test-id'?: string,
};

type SortableTableStateType = {
  sortOrder: 'Descending' | 'Ascending',
  sortColumn: ?ColumnType,
};

type SortableHeaderPropType = {
  column: ColumnType,
  sortOrder: 'Descending' | 'Ascending',
  isSorting: boolean,
  onClick: ColumnType => void,
  'data-test-id'?: string,
};

const SortableHeader = (props: SortableHeaderPropType) => {
  const { column, sortOrder, isSorting, onClick } = props;
  return (
    <TableHeaderLegacy width={column.width} data-test-id={props['data-test-id']}>
      <button type="button" onClick={() => onClick(column)} className={styles.sortableHeader}>
        {column.title}
        <span className={classNames({ [styles.isHidden]: !isSorting })}>
          <Icon
            glyph={sortOrder === 'Ascending' ? 'ControlSortUp' : 'ControlSortDown'}
            data-test-id={isSorting ? 'sortIconVisible' : undefined}
          />
        </span>
      </button>
    </TableHeaderLegacy>
  );
};

const getSortStateFromColumns = (
  columns: Array<ColumnType>,
  defaultSortColumnKey?: string,
  defaultSortOrder?: 'Descending' | 'Ascending',
) => {
  if (defaultSortColumnKey) {
    const sortColumn = columns.find(column => defaultSortColumnKey === column.key);
    return {
      sortColumn,
      sortOrder: defaultSortOrder || 'Ascending',
    };
  }
  return {
    sortOrder: 'Ascending',
    sortColumn: null,
  };
};

class SortableTable extends React.Component<SortableTablePropType, SortableTableStateType> {
  constructor(props: SortableTablePropType) {
    super(props);
    this.state = Object.assign(
      {},
      getSortStateFromColumns(props.columns, props.defaultSortColumnKey, props.defaultSortOrder),
    );
  }

  onHeaderClick = (column: ColumnType) => {
    const { sortColumn, sortOrder } = this.state;
    let newSortOrder = 'Ascending';
    if (column === sortColumn) {
      newSortOrder = sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
    }
    this.setState({
      sortColumn: column,
      sortOrder: newSortOrder,
    });
  };

  getSortedData() {
    const { dataSource } = this.props;
    if (this.state.sortColumn) {
      let sortFn = this.state.sortColumn.sorter;
      if (typeof sortFn === 'boolean' && sortFn) {
        const { dataIndex } = this.state.sortColumn;
        sortFn = comparator(
          (a, b) => path(dataIndex.split('.'), a) < path(dataIndex.split('.'), b),
        );
      }
      if (typeof sortFn === 'function') {
        return this.state.sortOrder === 'Ascending'
          ? sort(sortFn, dataSource)
          : reverse(sort(sortFn, dataSource));
      }
    }
    return dataSource;
  }

  isSortColumn(column: ColumnType) {
    const { sortColumn } = this.state;
    if (!sortColumn || !column) {
      return false;
    }
    return sortColumn.key === column.key;
  }

  render() {
    const { className, columns, rowKey = 'key' } = this.props;
    const { sortOrder } = this.state;
    return (
      <TableLegacy className={className} data-test-id={this.props['data-test-id']} variant="Normal">
        <TableHeadLegacy>
          <TableRowLegacy>
            {columns.map(column => {
              if (!column.sorter) {
                return (
                  <TableHeaderLegacy
                    key={column.key}
                    width={column.width}
                    data-test-id={column.headerTestId}
                  >
                    {column.title}
                  </TableHeaderLegacy>
                );
              }
              return (
                <SortableHeader
                  key={column.key}
                  data-test-id={column.headerTestId}
                  column={column}
                  isSorting={this.isSortColumn(column)}
                  sortOrder={sortOrder}
                  onClick={this.onHeaderClick}
                />
              );
            })}
          </TableRowLegacy>
        </TableHeadLegacy>
        <TableBodyLegacy>
          {this.getSortedData().map(record => (
            <TableRowLegacy key={record[rowKey]}>
              {columns.map(({ key, dataIndex, renderCell, sensitiveContent }) => {
                const text = path(dataIndex.split('.'), record);
                const content = renderCell ? renderCell(text, record) : text;
                return (
                  <TableDataLegacy key={key} data-ansarada-ccd={sensitiveContent}>
                    {content}
                  </TableDataLegacy>
                );
              })}
            </TableRowLegacy>
          ))}
        </TableBodyLegacy>
      </TableLegacy>
    );
  }
}

export default SortableTable;
