// @flow
import * as React from 'react';
import { Panel, Icon, icon } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import styles from './BillingCommencesAlert.scss';

const BillingCommencesAlert = () => {
  return (
    <Panel variant="Primary" className={styles.billingCommencesPanel}>
      <Icon glyph={icon.Glyphs.StatusInformation} size="Large" className={styles.cautionIcon} />
      <span className={styles.importantText}>{translate('Important')}</span>
      <span className={styles.billingCommencesText}>
        {translate('BillingCommencesWhenTheFirstGuestIsInvited')}
      </span>
    </Panel>
  );
};

export default BillingCommencesAlert;
