// @flow

import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingIndicator } from '@ansarada/ace-react';
import LoadingBar from 'app/components/loadingBar/LoadingBar';
import BannerMessages from 'app/components/bannerMessages/BannerMessages';
import ToastMessages from 'app/components/toast-message';
import ShellActionHeader from 'app/layout/shellActionHeader/ShellActionHeader';
import ShellBannerHeader from 'app/layout/shellBannerHeader/ShellBannerHeader';
import classnames from 'classnames';
import styles from './roomLayout.scss';

type OwnProps = {
  title: string,
  'data-test-id'?: string,
  children: React.Node,
  actionButtons?: React.Node,
  bannerHeader?: React.Node,
  loadingContent?: boolean,
  className?: string,
};

type StateProps = {
  dataRoomName: string,
  isLoading: boolean,
  dispatch: AppDispatch,
};

type Props = OwnProps & StateProps;

const setPageTitle = (title, dataRoomName) => {
  document.title = `${title} - ${dataRoomName}`;
};

class RoomLayout extends React.Component<Props> {
  componentDidMount() {
    setPageTitle(this.props.title, this.props.dataRoomName);
  }

  componentDidUpdate() {
    setPageTitle(this.props.title, this.props.dataRoomName);
  }

  render() {
    const {
      'data-test-id': testId,
      isLoading,
      actionButtons,
      bannerHeader,
      children,
      loadingContent,
      dispatch,
      className,
    } = this.props;

    return (
      <div
        className={classnames(
          className,
          `dr-spa ${styles.spa} ${isLoading ? 'dr-loading' : 'dr-loaded'}`,
        )}
        data-test-id={testId}
      >
        <ShellActionHeader actionButtons={actionButtons} />
        {bannerHeader && <ShellBannerHeader>{bannerHeader}</ShellBannerHeader>}
        <LoadingBar />
        <BannerMessages />
        <ToastMessages dispatch={dispatch} />
        <div className={styles.wrapper}>
          {loadingContent ? (
            <div className={styles.loadingContainer}>
              <LoadingIndicator dark />
            </div>
          ) : (
            <div className={styles.bodyContent}>{children}</div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: AppStateType): Object => ({
  isLoading: state.page.loadingBar.loading,
  dataRoomName: state.session.dataRoomName,
});

export default connect<AppStateType, AppDispatch, OwnProps, StateProps>(mapState)(RoomLayout);
