// @flow

import { getCopyToTeamData } from 'app/pages/security/helpers/securityHelpers';
import type { Team } from 'app/pages/security/securityTypes';
import { ACTION_TYPES, COPY_SECURITY_ACTIONS } from 'app/constants';

export const copySecuritySecurityAction = (teams: Array<Team>, team: Team) => ({
  type: ACTION_TYPES.SECURITY.COPY_SECURITY_INIT,
  data: {
    from: team,
    to: getCopyToTeamData(teams),
    nextAction: COPY_SECURITY_ACTIONS.REVIEW,
  },
});
