// @flow
import { Breadcrumb, Button, Message } from '@ansarada/ace-react';
import Link from 'app/components/aceReactRouterLink/AceReactRouterLink';
import { PageTitle } from 'app/components/typography/Typography';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import RoomLayout from 'app/layout/roomLayout';
import { links } from 'app/links';
import { EmailSection, RecipientsSection } from 'app/components/emailForm/EmailForm';
import type { AppStateType } from 'app/types/appStateTypes';
import { getLocationProps } from 'app/uri/url';
import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import classnames from 'classnames';
import LicenseMessage from 'app/components/licenseMessage/LicenseMessage';
import { getDialog } from 'app/utils/getDialog';
import * as verifyEmailDialogActions from 'app/pages/teams/verifyEmailDialog/verifyEmailDialogActions';
import { DataRoomType } from 'app/types/dataRoomTypes';
import type {
  SendInvitationPageType,
  SendInvitationPageState,
  OwnProps,
} from './sendInvitationTypes';
import {
  filterPeopleWithFilterType,
  sortByNameEmail,
  addTypeOnPerson,
  getEmailAddresses,
} from './sendInvitationHelper';
import styles from './SendInvitationPage.scss';

const INVITATION_LINK_TAG = '[invitation_link]';

export class SendInvitationPage extends React.Component<
  SendInvitationPageType,
  SendInvitationPageState,
> {
  constructor(props: SendInvitationPageType) {
    super(props);
    this.state = { editor: undefined };
  }

  componentDidMount() {
    const { dispatch, isEmailVerified, locationProps } = this.props;

    if (!isEmailVerified) {
      dispatch(verifyEmailDialogActions.toggleDialog(true));
    }

    dispatch({
      type: ACTION_TYPES.SEND_INVITATION.DATA_FETCH_INITIAL,
      data: locationProps,
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.SEND_INVITATION.RESET,
    });
  }

  componentDidUpdate(props: SendInvitationPageType) {
    if (props.locationProps?.isGuest === '1' && !props.isAllowInviteGuest) {
      props.history.push('/documents');
    }
  }

  onSelectPeopleIds = (peopleIds: Array<number>) => {
    this.props.dispatch({
      type: ACTION_TYPES.SEND_INVITATION.SELECT_PEOPLE,
      data: peopleIds,
    });
  };

  onFilterChange = (filterType: string) => {
    this.props.dispatch({
      type: ACTION_TYPES.SEND_INVITATION.CHANGE_FILTER,
      data: filterType,
    });
  };

  onAttachmentSelected = (files: Array<File>) => {
    this.props.dispatch({
      type: ACTION_TYPES.SEND_INVITATION.UPLOAD_ATTACHMENT,
      data: files,
    });
  };

  onRemoveAttachment = (attachmentId: number) => {
    this.props.dispatch({
      type: ACTION_TYPES.SEND_INVITATION.REMOVE_ATTACHMENT,
      data: attachmentId,
    });
  };

  onValidate = (values: Object) => {
    const errors = {};
    if (!values.subject) {
      errors.subject = translate('SendInvitationSubjectRequiredSentenceCase');
    }

    if (!values.selectedEmails) {
      errors.selectedEmails = translate('Required');
    }

    const messageText = this.state.editor ? this.state.editor.plainText : this.props.message;
    if (!messageText.includes(INVITATION_LINK_TAG)) {
      errors.message = translate('UserInvitationLinkTagIsMissing');
    }

    if (this.state.editor && !this.state.editor.plainText) {
      errors.message = translate('MessageEmpty');
    }

    return errors;
  };

  onSubmit = (values: Object) => {
    const {
      dispatch,
      selectedPeopleIds,
      attachments,
      message: defaultMessage,
      locationProps,
    } = this.props;
    const { editor } = this.state;
    dispatch({
      type: ACTION_TYPES.SEND_INVITATION.SUBMIT_INVITATION,
      data: {
        peopleIds: selectedPeopleIds,
        email: {
          subject: values.subject,
          attachmentIds: attachments.map(attachment => attachment.id),
          body: editor ? editor.html : defaultMessage,
        },
        isGuest: locationProps.isGuest === '1',
      },
    });
  };

  render() {
    const {
      history,
      locationProps,
      session,
      teams,
      people,
      selectedPeopleIds,
      isLoaded,
      attachments,
      subject,
      message,
      isSubmitting,
      isUploadingAttachment,
      hasBeenSent,
      licences,
      viewData,
      isEmailVerified,
      dataRoomType,
    } = this.props;
    const isIrlm = dataRoomType === DataRoomType.Irlm;

    const { qnaEnabled, isPaidCustomer } = session;
    const isGuest = locationProps.isGuest === '1';
    let pageTitleKey = 'SendInvites';
    if (isIrlm) {
      pageTitleKey = 'SendInvite_SentenceCase';
    } else if (locationProps.isGuest !== undefined) {
      pageTitleKey = isGuest
        ? `${session.translationTagNameNonAdminSide}_InviteHeading`
        : `${session.translationTagNameAdminSide}_InviteHeading`;
    }
    // $FlowFixMe flow think tagName might be missed in session.
    const pageTitle = translate(pageTitleKey);
    const teamType = isGuest
      ? session.translationTagNameNonAdminSide
      : session.translationTagNameAdminSide;

    // eslint-disable-next-line no-prototype-builtins
    const hasLicences = !!licences.allowed;

    const teamTypeLabel = translate(teamType);
    const breadcrumbItems = [
      {
        href: '',
        text: '',
      },
      {
        text: teamTypeLabel,
        onClick() {
          history.push(links.teams(isGuest));
        },
      },
      {
        href: '#',
        text: pageTitle,
      },
    ];
    const successMessage = (
      <>
        <h2 className={styles.successMessage} data-test-id="successMessage">
          {translate('InvitationSuccessMessage')}
        </h2>
        <Link to={links.teams(isGuest)}>{translate('BackToTeamsPage')}</Link>
      </>
    );
    const form = (
      <Formik
        initialValues={{
          subject,
          selectedEmails: '',
        }}
        validate={this.onValidate}
        onSubmit={this.onSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          ...formik
        }) => {
          const focusFirstError =
            !formik.isValidating && formik.isSubmitting
              ? ['subject', 'message', 'selectedEmails'].find(k => errors[k])
              : undefined;
          return (
            <>
              {!isIrlm && <Message>{translate('RecipientsOfEmailNote_Gen2')}</Message>}
              <EmailSection
                title={translate('InvitationEmail')}
                messageHint={translate('UserInvitationLinkNote_Gen2')}
                messageErrors={errors.message}
                attachments={attachments}
                onAttachmentSelected={this.onAttachmentSelected}
                onRemoveAttachment={this.onRemoveAttachment}
                isUploadingAttachment={isUploadingAttachment}
                message={message}
                isReadOnly={!isPaidCustomer}
                showHintMessage={!isIrlm}
                onEditorUpdate={(html: string, plainText: string) => {
                  this.setState({
                    editor: {
                      html,
                      plainText,
                    },
                  });
                }}
              />
              <RecipientsSection
                errors={touched.selectedEmails && errors.selectedEmails}
                teams={teams}
                people={people}
                selectedPeopleIds={selectedPeopleIds}
                selectedEmails={values.selectedEmails}
                showFilter
                onFilterChange={filterType => {
                  this.onFilterChange(filterType);
                  setFieldTouched('selectedEmails', true);
                }}
                onSelectPeopleIds={peopleIds => {
                  this.onSelectPeopleIds(peopleIds);
                  setFieldValue('selectedEmails', getEmailAddresses(people, peopleIds));
                  setFieldTouched('selectedEmails', true, !peopleIds.length);
                }}
                focus={focusFirstError === 'selectedEmails'}
                columnKeys={[
                  'email',
                  'roomRole',
                  qnaEnabled ? 'qnaRole' : undefined,
                  'dateSent',
                  'verified',
                  'loggedInToDate',
                  'disabled',
                ].filter(Boolean)}
                showHintMessage={!isIrlm}
              />
              {!isGuest && hasLicences && <LicenseMessage licences={licences} isDialog={false} />}

              <div className={styles.footer}>
                <Button
                  loading={isSubmitting}
                  variant="Primary"
                  data-test-id="sendInvitationButton"
                  onClick={handleSubmit}
                  disabled={!isEmailVerified}
                >
                  {!isGuest && hasLicences
                    ? translate('SendInviteToSubscription')
                    : translate('Send')}
                </Button>
              </div>
            </>
          );
        }}
      />
    );
    return (
      <RoomLayout
        title={pageTitle}
        loadingContent={!isLoaded}
        data-test-id="sendInvitationPage"
        className={classnames(isIrlm && styles.noBreadcrumb)}
      >
        {!isIrlm && <Breadcrumb items={breadcrumbItems} />}
        <PageTitle withSpacing>{pageTitle}</PageTitle>
        {hasBeenSent ? successMessage : form}

        {getDialog(viewData.dialogType)}
      </RoomLayout>
    );
  }
}

export function mapStateToProps(state: AppStateType, ownProps: OwnProps) {
  const {
    filterType,
    teams,
    people,
    userTypeMap,
    qnaRoleMap,
    selectedPeopleIds,
    isLoaded,
    attachments,
    subject,
    message,
    isSubmitting,
    isUploadingAttachment,
    hasBeenSent,
  } = state.sendInvitation;
  const { dataRoomType } = state.session;
  const { isAllowInviteGuest } = state.features;
  const filteredPeople = filterPeopleWithFilterType(
    people.map(person => addTypeOnPerson(person, userTypeMap, qnaRoleMap)),
    filterType,
  );

  return {
    locationProps: getLocationProps(ownProps.location),
    session: state.session,
    selectedPeopleIds,
    filterType,
    teams,
    people: sortByNameEmail(filteredPeople),
    isLoaded,
    attachments,
    subject,
    message,
    isSubmitting,
    isUploadingAttachment,
    hasBeenSent,
    licences: state.page.licences,
    viewData: state.teams.viewData,
    isEmailVerified: state.verifyEmail.isEmailVerified,
    dataRoomType,
    isAllowInviteGuest,
  };
}

export default connect<AppStateType, _, *, *>(mapStateToProps)(SendInvitationPage);
