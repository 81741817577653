// @flow

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Select, Option } from '@ansarada/ace-react';
import classnames from 'classnames';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import type { PermissionsType } from 'app/types/permissionsTypes';
import translate from 'app/i18n/translate';
import type {
  DocumentIndexItem,
  WatermarkType,
  TenderSubmissionsSecurityActionsType,
  FolderActionsType,
  TenderSubmissionSecurityContextType,
} from 'app/pages/security/securityTypes';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import { toggleDialog } from 'app/components/dialog/dialogActions';
import { bindActionCreators } from 'redux';
import { NavigationWarning } from 'app/components/navigationWarning/NavigationWarning';
import { isFooterVisible } from 'app/utils/dom';
import SaveChangesBar from 'app/pages/security/saveChangesBar/SaveChangesBar';
import WarningDialog from 'app/components/warningDialog/WarningDialog';
import { convertToNumber } from 'app/utils/helpers';
import style from '../documentIndex/documentIndex.scss';
import { DocumentIndexTable } from '../documentIndex/DocumentIndexTable';
import { TreeHeader } from '../documentIndex/treeHeader/TreeHeader';
import { mapToDocumentIndex } from '../helpers/treeHelpers';
import { setDialogType } from '../securityActions';
import formStyle from './TenderSubmissionsSecurityPage.scss';
import {
  expandFolder,
  initTenderSubmissionsData,
  setSecuritySettings,
  resetTenderSubmissionSecuritySettings,
  saveTenderSubmissionSecuritySettings,
  selectBidder,
} from './tenderSubmissionsSecurityActions';

type TenderSubmissionsSecurityProps = {
  index: Array<DocumentIndexItem>,
  watermarks: Array<WatermarkType>,
  actions: FolderActionsType &
    TenderSubmissionsSecurityActionsType & {
      setDialogType: Function,
      toggleDialog: Function,
      setSecuritySettings: Function,
    },
  dispatch: AppDispatch,
  loading: boolean,
  permissions: PermissionsType,
  session: SessionType,
  hasUnsavedData: boolean,
  context: TenderSubmissionSecurityContextType,
};

const initData = initDataAction => {
  useEffect(() => {
    initDataAction();
  }, [initDataAction]);
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function TenderSubmissionsSecurityPage(props: TenderSubmissionsSecurityProps) {
  const { permissions, index, session, hasUnsavedData, dispatch, context, actions } = props;
  const [warningDialogVisible, setWarningDialogVisible] = useState(false);
  const [localSelectedBidderId, setLocalSelectedBidderId] = useState(null);

  initData(actions.initTenderSubmissionsData);

  const prevProps = usePrevious({ index, hasUnsavedData });
  useEffect(() => {
    if (prevProps) {
      const changedItems = prevProps.index.length !== index.length;
      const nowHasUnsavedData = !prevProps.hasUnsavedData && hasUnsavedData;

      if (changedItems || nowHasUnsavedData) {
        isFooterVisible(dispatch);
      }
    }
  }, [index, hasUnsavedData, prevProps, dispatch]);

  const { bidders, selectedBidderId } = context;
  useEffect(() => {
    if (selectedBidderId) {
      setLocalSelectedBidderId(selectedBidderId.toString());
    }
  }, [selectedBidderId]);

  const selectBidderAction = bidderTeamId => {
    const { subteamId, team } = context;
    actions.selectBidder({
      session,
      bidderTeamId,
      subteamId,
      team,
    });
  };

  const switchBidder = newBidderTeamId => {
    setLocalSelectedBidderId(newBidderTeamId);
    if (hasUnsavedData) {
      setWarningDialogVisible(true);
    } else {
      selectBidderAction(convertToNumber(newBidderTeamId));
    }
  };

  const onConfirmSwitchBidder = () => {
    setWarningDialogVisible(false);
    selectBidderAction(convertToNumber(localSelectedBidderId));
  };

  const onCancelSwitchBidder = () => {
    setWarningDialogVisible(false);
    if (selectedBidderId) {
      setLocalSelectedBidderId(selectedBidderId.toString());
    }
  };

  return (
    index.length > 0 &&
    !!permissions && (
      <div data-test-id="tenderSubmissions" className={classnames(style.scrollWrapper)}>
        <WarningDialog
          visible={warningDialogVisible}
          header={translate('SwitchTeam_Title')}
          message={translate('SwitchTeam_WarningMessage')}
          confirmText={translate('SwitchTeam_Confirm')}
          isDanger
          isMedium
          onCancel={onCancelSwitchBidder}
          onConfirm={onConfirmSwitchBidder}
        />
        {bidders && !!bidders.length && localSelectedBidderId && (
          <Select
            data-ansarada-ccd
            className={formStyle.selectBidderFormInput}
            value={localSelectedBidderId}
            onChangeValue={switchBidder}
          >
            {bidders.map(item => (
              <Option key={item.teamId} value={item.teamId.toString()} text={item.teamName} />
            ))}
          </Select>
        )}
        <div className={style.container}>
          <TreeHeader
            setDialogType={actions.setDialogType}
            toggleDialog={actions.toggleDialog}
            documentSecurityEnabled={session.documentSecurityEnabled}
          />
          <WindowScroller>
            {({ height, onChildScroll, scrollTop }) => (
              <AutoSizer className="document-index-content" disableHeight>
                {({ width }) => (
                  <>
                    <div className="transition-cover" />
                    <DocumentIndexTable
                      width={width}
                      height={height}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      {...props}
                    />
                  </>
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        </div>
        {hasUnsavedData && (
          <React.Fragment>
            <SaveChangesBar
              onSave={actions.saveTenderSubmissionSecuritySettings}
              onReset={actions.resetTenderSubmissionSecuritySettings}
            />
            <NavigationWarning />
          </React.Fragment>
        )}
      </div>
    )
  );
}

const mapStateToProps = state => ({
  index: mapToDocumentIndex(
    state.security.tenderSubmissions.tree,
    state.security.tenderSubmissions.index,
    state.security.tenderSubmissions.expanded,
  ),
  watermarks: state.security.documents.pageData.watermarks,
  hasUnsavedData: !!Object.keys(state.security.tenderSubmissions.edited).length,
  loading: state.page.loadingBar.loading,
  permissions: state.page.userPermissions,
  session: state.session,
  context: state.security.tenderSubmissions.context,
});

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators(
    {
      expandFolder,
      setDialogType,
      toggleDialog,
      initTenderSubmissionsData,
      setSecuritySettings,
      resetTenderSubmissionSecuritySettings,
      saveTenderSubmissionSecuritySettings,
      selectBidder,
    },
    dispatch,
  ),
  dispatch,
});

export default connect<_, _, *, *, *>(
  mapStateToProps,
  mapDispatchToProps,
)(TenderSubmissionsSecurityPage);
