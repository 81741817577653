// @flow

import {
  Highlight,
  TableLegacy,
  TableBodyLegacy,
  TableDataLegacy,
  TableHeadLegacy,
  TableHeaderLegacy,
  TableRowLegacy,
} from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { QnaDetails } from 'app/pages/teamHistory/teamHistoryTypes';
import * as React from 'react';

type SubmissionTablePropType = {
  qnaDetails: QnaDetails,
  updatedFields: Array<string>,
  'data-test-id'?: string,
};

const TextMapping = {
  low: translate('Low'),
  medium: translate('Medium'),
  high: translate('High'),
  all: translate('Total'),
};

const highlightIfChanged = (content, hasChanged) =>
  hasChanged ? <Highlight data-test-id="highlight">{content}</Highlight> : content;

const caseInsensitiveIncludes = (arr: Array<string>, target: string) => {
  const lowerCaseTarget = target.toLowerCase();
  return arr.map(item => item.toLowerCase()).includes(lowerCaseTarget);
};

const SubmissionTable = (props: SubmissionTablePropType) => {
  const { qnaDetails, updatedFields } = props;
  return (
    <TableLegacy data-test-id={props['data-test-id']}>
      <TableHeadLegacy>
        <TableRowLegacy>
          <TableHeaderLegacy>{translate('Priority')}</TableHeaderLegacy>
          <TableHeaderLegacy>{translate('Total')}</TableHeaderLegacy>
          <TableHeaderLegacy>
            {highlightIfChanged(
              // $FlowFixMe Flow think it might be missing
              translate(`Per${qnaDetails.maxQuestionsFrequencyInterval}`),
              caseInsensitiveIncludes(updatedFields, 'QnaDetails.MaxQuestionsFrequencyInterval'),
            )}
          </TableHeaderLegacy>
        </TableRowLegacy>
      </TableHeadLegacy>
      <TableBodyLegacy>
        {['low', 'medium', 'high', 'all'].map(key => (
          <TableRowLegacy key={key}>
            <TableDataLegacy>{TextMapping[key]}</TableDataLegacy>
            <TableDataLegacy>
              {highlightIfChanged(
                // $FlowFixMe indexer property
                qnaDetails.maxQuestionsTotal[`${key}Priority`],
                caseInsensitiveIncludes(
                  updatedFields,
                  `QnaDetails.MaxQuestionsTotal.${key}Priority`,
                ),
              )}
            </TableDataLegacy>
            <TableDataLegacy>
              {highlightIfChanged(
                // $FlowFixMe indexer property
                qnaDetails.maxQuestionsFrequency[`${key}Priority`],
                caseInsensitiveIncludes(
                  updatedFields,
                  `QnaDetails.MaxQuestionsFrequency.${key}Priority`,
                ),
              )}
            </TableDataLegacy>
          </TableRowLegacy>
        ))}
      </TableBodyLegacy>
    </TableLegacy>
  );
};

export default SubmissionTable;
