// @flow

import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Autocomplete, AutocompleteItem, FormGridCell, FormGridRow } from '@ansarada/ace-react';
import type { LocationType } from 'app/layout/selfRegistration/selfRegistrationTypes';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import { LABELS } from 'app/layout/selfRegistration/validationSchema';

const createLocationAutocompleteItems = (locations: LocationType[]): AutocompleteItem[] =>
  locations.map(l => new AutocompleteItem(String(l.locationId), l.locationName));

export type PropsType = {
  setFieldValue: (field: string, value: any) => void,
  isShowError: (field: string) => boolean,
  values: any,
  dispatch: AppDispatch,
  locations: LocationType[],
  locationsLoading: boolean,
  errors: any,
  renderAs?: 'formGroup' | 'control',
};

export class LocationInput extends React.Component<PropsType> {
  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.RESET,
    });
  }

  searchLocations = debounce((searchTerm: string) => {
    this.props.dispatch({ type: ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.FETCH, data: searchTerm });
  }, 300);

  onSelected = (item: any) => {
    const { locations, setFieldValue } = this.props;
    const location = locations.find(l => l.locationId === Number(item.value));
    if (location) {
      setFieldValue('selectedLocation', location);
    }
  };

  onChangeValue = (value: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('location', value);
    if (value) {
      this.searchLocations(value);
    }
  };

  render() {
    const {
      isShowError,
      locations,
      locationsLoading,
      errors,
      values,
      renderAs = 'formGroup',
    } = this.props;

    const component = (
      <Autocomplete
        data-test-id="location"
        placeholder={translate('EnterYourLocationOrClosestCity')}
        items={createLocationAutocompleteItems(locations)}
        loading={locationsLoading}
        onSelected={this.onSelected}
        onChangeValue={this.onChangeValue}
        status={isShowError('location') ? 'Bad' : 'Good'}
        value={values.location}
        onChangeItems={() => {}}
        data-ansarada-ccd
      />
    );

    if (renderAs === 'formGroup') {
      return (
        <FormGridRow key="location">
          <FormGridCell
            labelText={LABELS.location}
            errors={isShowError('location') ? errors.location : ''}
            errorPosition="Bottom"
          >
            {component}
          </FormGridCell>
        </FormGridRow>
      );
    }

    return component;
  }
}

const mapStateToProps = (state: AppStateType) => ({
  locations: state.selfRegistration.locations,
  locationsLoading: state.selfRegistration.locationsLoading,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(LocationInput);
