"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SUBSCRIPTION_CONFIGURATION_BY_ASSET = exports.GET_SUBSCRIPTION_BY_ASSET = void 0;
var graphql_1 = require("graphql");
var graphql_request_1 = require("graphql-request");
exports.GET_SUBSCRIPTION_BY_ASSET = (0, graphql_1.parse)((0, graphql_request_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getSubscriptionByAsset($assetType: AssetType!, $assetId: String!) {\n    subscriptionByAsset: getSubscriptionByAsset(assetType: $assetType, assetId: $assetId) {\n      subscriptionStage\n      subscriptionType\n    }\n  }\n"], ["\n  query getSubscriptionByAsset($assetType: AssetType!, $assetId: String!) {\n    subscriptionByAsset: getSubscriptionByAsset(assetType: $assetType, assetId: $assetId) {\n      subscriptionStage\n      subscriptionType\n    }\n  }\n"]))));
exports.GET_SUBSCRIPTION_CONFIGURATION_BY_ASSET = (0, graphql_1.parse)((0, graphql_request_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getSubscriptionConfigurationByAssetQuery($assetType: AssetType!, $assetId: String!) {\n    subscriptionConfigurationByAsset: getSubscriptionConfigurationByAsset(\n      assetType: $assetType\n      assetId: $assetId\n    ) {\n      canUseAdvisorSelfServiceFlow\n      canUseAlwaysAutomationFlow\n    }\n  }\n"], ["\n  query getSubscriptionConfigurationByAssetQuery($assetType: AssetType!, $assetId: String!) {\n    subscriptionConfigurationByAsset: getSubscriptionConfigurationByAsset(\n      assetType: $assetType\n      assetId: $assetId\n    ) {\n      canUseAdvisorSelfServiceFlow\n      canUseAlwaysAutomationFlow\n    }\n  }\n"]))));
var templateObject_1, templateObject_2;
