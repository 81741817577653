// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { HelpLink as ACEHelpLink, Icon, icon } from '@ansarada/ace-react';
import { ACTION_TYPES } from 'app/constants';

type HelpLinkPropType = {
  dispatch: AppDispatch,
  helperLinkContent: string,
};

const HelpLink = (props: HelpLinkPropType) => {
  const { dispatch, helperLinkContent } = props;

  const onClick = () =>
    dispatch({
      type: ACTION_TYPES.TEAMS.FORM_HELP_TOGGLE,
      data: helperLinkContent,
    });

  return (
    <ACEHelpLink
      href="#"
      icon={
        <Icon
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          glyph={icon.Glyphs.StatusHelp}
          onClick={onClick}
        />
      }
    />
  );
};

export default HelpLink;
