// @flow

import * as React from 'react';
import translate from 'app/i18n/translate';
import type { FieldError } from 'app/types/errorTypes';

type FieldErrorsProps = {
  errors: Array<FieldError>,
};

export const FieldErrors = (props: FieldErrorsProps) => {
  const err =
    props.errors &&
    props.errors.map((error, i) => {
      const errorKey = `field-error-${i}`;
      const text = () => {
        const { code } = error;
        if (code) {
          const strs = code.split(',');
          if (strs.length === 1) {
            // $FlowFixMe we can't ensure the errors have translation keys
            return translate(strs[0]);
          }

          const replacements = strs.slice(1).reduce((acc, key, index) => {
            // $FlowFixMe we can't ensure the errors have translation keys
            acc[index] = translate(key);
            return acc;
          }, {});

          // $FlowFixMe we can't ensure the errors have translation keys
          return translate(strs[0], replacements);
        }
        return error.details;
      };
      return (
        <div key={errorKey} className="ace-form-input-state ace-form-error">
          {text()}
        </div>
      );
    });

  return err && err.length > 0 ? <div>{err}</div> : null;
};

export default FieldErrors;
