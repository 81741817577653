"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerKeys = exports.assetKeys = void 0;
exports.assetKeys = {
    customerByAsset: ['customer-by-asset'],
    subscriptionByAsset: ['subscription-by-asset'],
    subscriptionConfigurationByAsset: ['subscription-configuration-by-asset'],
};
exports.customerKeys = {
    transfer: ['customer-transfer'],
};
