"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.licenseDataObservable = void 0;
var react_1 = require("react");
var windowed_observable_1 = require("windowed-observable");
var react_redux_1 = require("react-redux");
// @ts-ignore
var constants_1 = require("app/constants");
var licenseReducer_1 = require("./reducers/licenseReducer");
exports.licenseDataObservable = new windowed_observable_1.Observable("".concat(constants_1.DATAROOM_SHELL_APP_NAME, "_").concat(constants_1.EVENT_NAME.DATA_ROOM.SET_LICENSING_DATA));
var Subscription = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        var licenseData = exports.licenseDataObservable.getLastEvent();
        var updateLicenseData = function (data) {
            dispatch(licenseReducer_1.actions.setLicenseData(data));
        };
        if (licenseData) {
            updateLicenseData(licenseData);
        }
        exports.licenseDataObservable.subscribe(updateLicenseData);
        return function () {
            exports.licenseDataObservable.unsubscribe(updateLicenseData);
        };
    }, []);
    return null;
};
exports.default = Subscription;
