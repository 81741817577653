"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_CUSTOMER_BY_ASSET = exports.GET_CUSTOMER_TRANSFER = void 0;
var graphql_1 = require("graphql");
var graphql_request_1 = require("graphql-request");
exports.GET_CUSTOMER_TRANSFER = (0, graphql_1.parse)((0, graphql_request_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getCustomerTransferQuery($id: String!) {\n    customer: getCustomer(id: $id) {\n      customerTransfer {\n        pendingUserFirstName\n        transferStatus\n        transferExpiryDate\n      }\n    }\n  }\n"], ["\n  query getCustomerTransferQuery($id: String!) {\n    customer: getCustomer(id: $id) {\n      customerTransfer {\n        pendingUserFirstName\n        transferStatus\n        transferExpiryDate\n      }\n    }\n  }\n"]))));
exports.GET_CUSTOMER_BY_ASSET = (0, graphql_1.parse)((0, graphql_request_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getCustomerByAsset($assetType: AssetType!, $assetId: String!) {\n    customerByAsset: getCustomerByAsset(assetType: $assetType, assetId: $assetId) {\n      customerId\n      isCustomerOwner\n    }\n  }\n"], ["\n  query getCustomerByAsset($assetType: AssetType!, $assetId: String!) {\n    customerByAsset: getCustomerByAsset(assetType: $assetType, assetId: $assetId) {\n      customerId\n      isCustomerOwner\n    }\n  }\n"]))));
var templateObject_1, templateObject_2;
