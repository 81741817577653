"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateDiff = exports.isFunction = void 0;
var isFunction = function (value) {
    return typeof value === 'function';
};
exports.isFunction = isFunction;
var dateDiff = function (dateFrom, dateTo) {
    var timeDiff = Math.abs(dateTo.getTime() - dateFrom.getTime());
    return Math.round(timeDiff / (24 * 60 * 60 * 1000));
};
exports.dateDiff = dateDiff;
