// @flow

import { Button, Radio, RadioGroup, TextArea } from '@ansarada/ace-react';
import FieldErrors from 'app/components/fieldErrors/FieldErrors';
import { ELEMENT_IDS } from 'app/constants';
import translate from 'app/i18n/translate';
import { purifyTag } from 'app/pages/teams/peopleFormDialog/peopleFormDialogHelpers';
import { IP_FROM } from 'app/pages/teams/teamsConstants';
import type { FieldError } from 'app/types/errorTypes';
import React from 'react';
import style from './ipAccessList.scss';

type Props = {
  className?: string,
  disabled?: boolean,
  ipAccessFrom: $Values<typeof IP_FROM>,
  userIpAccessList: string,
  remoteIp: string,
  errors?: Array<FieldError>,
  onChange: ({ key: string, value: string }) => void,
};

const IpAccessList = (props: Props) => {
  const {
    className,
    disabled = false,
    ipAccessFrom,
    userIpAccessList,
    remoteIp,
    errors,
    onChange,
  } = props;
  return (
    <div className={className}>
      <RadioGroup
        name="radioIpAccess"
        value={ipAccessFrom}
        onChangeValue={value => {
          onChange({ key: 'ipAccessFrom', value });
        }}
      >
        <Radio
          disabled={disabled}
          aria-describedby={ELEMENT_IDS.PEOPLE_FORM_IP_ACCESS}
          value={IP_FROM.ANY}
          label={translate('UserCanLogInFromAnyIp')}
          defaultChecked={false}
        />
        <Radio
          disabled={disabled}
          aria-describedby={ELEMENT_IDS.PEOPLE_FORM_IP_ACCESS}
          value={IP_FROM.SPECIFIED}
          label={translate('UserCanLogInOnlyFromIpsBelow')}
          defaultChecked={false}
        />
      </RadioGroup>
      {ipAccessFrom === IP_FROM.SPECIFIED && !disabled && (
        <div>
          <TextArea
            disabled={disabled}
            className={style.userIpAccessList}
            value={userIpAccessList}
            status={errors ? 'Bad' : 'Good'}
            onChangeValue={value => {
              onChange({ key: 'userIpAccessList', value });
            }}
          />
          {errors && <FieldErrors errors={errors} />}
          <ul className={style.ipAddressTips}>
            <li>{translate('EnterIpAddressesRanges')}</li>
            <li>{purifyTag(translate('ForRangesSeparateStartIp'))}</li>
            <li>{purifyTag(translate('YouCanAddDescriptionsAfterComma'))}</li>
          </ul>
          <Button
            disabled={disabled}
            data-test-id="currentIpAddress"
            variant="Link"
            onClick={() => {
              onChange({
                key: 'userIpAccessList',
                value: `${remoteIp}\n${userIpAccessList || ''}`,
              });
            }}
          >
            {translate('YourCurrentIpAddressSec2')}
          </Button>
          <span>{`(${remoteIp})`}</span>
        </div>
      )}
    </div>
  );
};

export default IpAccessList;
