// @flow

import { links } from 'app/links';
import TeamPanelActions from 'app/pages/teams/teamList/teamPanel/TeamPanelActions';
import { TeamPanelLozenge } from 'app/pages/teams/teamList/teamPanel/TeamPanelLozenge';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ExpanderPanel, TruncateText } from '@ansarada/ace-react';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import PeopleList from 'app/pages/teams/peopleList/PeopleList';
import * as actions from 'app/pages/teams/teamList/teamListActions';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { Team, Subteam } from 'app/types/teamsTypes';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { RouterHistory } from 'react-router-dom';
import { getFeature2Data } from 'app/utils/feature2';
import styles from './teamPanel.scss';

type TeamPanelPropTypes = {
  collapsedTeams: Object,
  countPeople?: number,
  dispatch: AppDispatch,
  filterActive: boolean,
  group: Subteam | Team,
  isGuest: boolean,
  isSecurityEdited: boolean,
  people?: Array<*>,
  permissions: PermissionsType,
  scrollIntoView: boolean,
  session: SessionType,
  team?: Team,
  teams: Array<Team>,
  userTypes: Array<*>,
  hasOtherTeam: boolean,
  history: RouterHistory,
  isDialogOpen: boolean,
  isQualifiedBidder: boolean,
  isEmailVerified: boolean,
  isPaidCustomer: boolean,
};

export const EmptyStateMessage = (props: {
  dispatch: AppDispatch,
  group: Subteam | Team,
  isSecurityEdited: boolean,
  permissions: $Shape<PermissionsType>,
  team?: Team,
  isGuest: boolean,
  session: SessionType,
  history: RouterHistory,
}) => {
  const { dispatch, group, isSecurityEdited, permissions, team, isGuest, session, history } = props;

  const teamExpanderPeopleMessage = translate('AddPeopleToTeam', { Team: group.name });

  const subteamExpanderPeopleMessage = translate('AddPeopleToSubteam', {
    Team: team && team.name ? team.name : '',
    Subteam: group.name,
  });

  const teamExpanderSecurityMessage = !permissions.document.modify
    ? translate('AddPeopleWillHaveTheSameAccess')
    : translate('ThisTeamCannotAccess', { Team: group.name });
  const subteamExpanderSecurityMessage = !permissions.document.modify
    ? translate('AddPeopleWillHaveTheSameAccess')
    : translate('ThisSubTeamCannotAccess', {
        Team: team && team.name ? team.name : '',
        Subteam: group.name,
      });

  const securityMessageBeforeAdding = translate('SecureByDefault');

  const addPeopleButton = (
    <Button
      className={styles.emptyStateButton}
      data-test-id="addPeopleMessage"
      onClick={() => {
        dispatch({
          type: ACTION_TYPES.DIALOG_TOGGLE,
          data: `${translate('AddPeopleTo')} ${group.name}`,
        });
        dispatch(
          actions.addPersonAction(group.isSubteam, group.teamId, group.subteamId, isGuest, session),
        );
      }}
    >
      {translate('AddPeople')}
    </Button>
  );

  if (!isSecurityEdited && permissions.userGroup.modify) {
    const path = links.security({ subteamIds: group.subteamId, isGuest });
    return (
      <div className={styles.emptyStateMessage}>
        {permissions.document.modify && <h3>{securityMessageBeforeAdding}</h3>}

        <p>{group.isSubteam ? subteamExpanderSecurityMessage : teamExpanderSecurityMessage}</p>

        {permissions.document.modify ? (
          <Button
            className={styles.emptyStateButton}
            data-test-id="setSecurityControlsMessage"
            onClick={() => history.push(path)}
          >
            {translate('ChangeTeamAccess')}
          </Button>
        ) : (
          addPeopleButton
        )}
      </div>
    );
  }

  if (isSecurityEdited && permissions.user.modify) {
    return (
      <div className={styles.emptyStateMessage}>
        <p data-ansarada-ccd>
          {group.isSubteam ? subteamExpanderPeopleMessage : teamExpanderPeopleMessage}
        </p>

        {addPeopleButton}
      </div>
    );
  }

  return null;
};

const EmptyStateMessageWithRouter = withRouter(EmptyStateMessage);

class TeamPanel extends React.Component<TeamPanelPropTypes> {
  componentDidMount() {
    const { dispatch } = this.props;

    if (this.props.scrollIntoView) {
      dispatch({ type: ACTION_TYPES.TEAMS.SET_FOCUS_TEAM, data: {} });
      if (this.panelRef) this.panelRef.scrollIntoView();
    }

    if (
      getFeature2Data(
        'dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings',
      )
    ) {
      dispatch({
        type:
          ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS
            .FETCH_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP,
        data: { subteamId: this.props.group.subteamId },
      });
    }
  }

  shouldComponentUpdate(nextProps: TeamPanelPropTypes) {
    return !nextProps.isDialogOpen;
  }

  panelRef: ?HTMLDivElement;

  render() {
    const {
      collapsedTeams,
      countPeople = 0,
      dispatch,
      filterActive,
      group,
      isGuest,
      isSecurityEdited,
      people,
      permissions,
      session,
      team,
      userTypes,
      history,
      isEmailVerified,
    } = this.props;

    if (!permissions) return null;

    const expanded = collapsedTeams[group.subteamId] === undefined;

    const peopleText =
      countPeople === 1 ? translate('PersonLowerCase') : translate('PeopleLowerCase');

    const hasPeople = countPeople > 0;

    const hasPeopleNotInvited = people && people.filter(person => !person.invited).length;

    return (
      <ExpanderPanel
        data-test-id={`teamPanel-${group.isSubteam ? group.subteamId : group.teamId}`}
        subTitle={group.isSubteam ? translate('SubTeam') : undefined}
        title={<TruncateText truncatePosition="Tail">{group.name}</TruncateText>}
        colour={group.colour}
        expanded={expanded}
        data-ansarada-ccd
        onExpand={() =>
          dispatch(
            actions.expanderToggle({
              collapsedTeams,
              expanded,
              group,
            }),
          )
        }
        lozenge={hasPeopleNotInvited ? <TeamPanelLozenge {...this.props} /> : undefined}
        summary={`${countPeople} ${peopleText}`}
        // $FlowFixMe TeamPanelActions is a wrapper of Dropdown
        dropdown={<TeamPanelActions key="group" {...this.props} hasPeople={hasPeople} />}
      >
        <div
          ref={el => {
            this.panelRef = el;
          }}
        >
          {hasPeople ? (
            <PeopleList
              people={people}
              userTypes={userTypes}
              dispatch={dispatch}
              permissions={permissions}
              session={session}
              isSubteam={group.isSubteam}
              isEmailVerified={isEmailVerified}
            />
          ) : (
            <EmptyStateMessageWithRouter
              dispatch={dispatch}
              group={group}
              permissions={permissions}
              filterActive={filterActive}
              isSecurityEdited={isSecurityEdited}
              team={team}
              isGuest={isGuest}
              session={session}
              history={history}
            />
          )}
        </div>
      </ExpanderPanel>
    );
  }
}

export default withRouter(TeamPanel);
