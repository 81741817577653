"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivatedLiveBanner = void 0;
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var mui_themes_1 = require("@ansarada/mui-themes");
var ace_react_1 = require("@ansarada/ace-react");
var useCheckShowActivateSubscriptionMessage_1 = require("app/hooks/useCheckShowActivateSubscriptionMessage");
var sessionReducer_1 = require("app/reducers/sessionReducer");
var react_redux_1 = require("react-redux");
var local_storage_1 = require("app/utils/local-storage");
var constants_1 = require("app/constants");
var ActivatedLiveBanner = function () {
    var session = (0, react_redux_1.useSelector)(sessionReducer_1.selectSession);
    var _a = (0, useCheckShowActivateSubscriptionMessage_1.useCheckShowActivateSubscriptionMessage)(), subscriptionStage = _a.subscriptionStage, isLoading = _a.isLoading, isCustomerOwner = _a.isCustomerOwner, hasPendingTransfer = _a.hasPendingTransfer, customerId = _a.customerId;
    var key = "AdvisorFreemium-ActivatedLiveNotification-".concat(session.userId);
    var _b = (0, react_1.useState)((0, local_storage_1.loadState)(key)), liveNotified = _b[0], setLiveNotified = _b[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var shouldShowActivatedLiveBanner = subscriptionStage === 'Activated' && !hasPendingTransfer;
    if (isLoading || !shouldShowActivatedLiveBanner || liveNotified) {
        return null;
    }
    function handleCloseLiveNotification() {
        (0, local_storage_1.saveState)(key, true);
        setLiveNotified(true);
    }
    function handleViewSubscription() {
        dispatch({
            type: constants_1.ACTION_TYPES.SUBSCRIPTION.MANAGE_DIALOG,
            data: {
                customerId: customerId,
            },
        });
    }
    return (react_1.default.createElement(material_1.ThemeProvider, { theme: mui_themes_1.themes.ESGLight },
        react_1.default.createElement(ace_react_1.Message, { variant: "success", close: react_1.default.createElement(ace_react_1.Close, { onUpdate: handleCloseLiveNotification }), "data-test-id": "activated-live-banner" },
            react_1.default.createElement(ace_react_1.Icon, { glyph: ace_react_1.icon.Glyphs.StatusSuccess, size: "Large" }),
            "You're now live.",
            isCustomerOwner ? (react_1.default.createElement(react_1.default.Fragment, null,
                ' ',
                "Billing has commenced.",
                react_1.default.createElement(ace_react_1.Button, { variant: "SubtleLink", className: "subtle-link", onClick: handleViewSubscription, "data-test-id": "view-subscription-btn" },
                    ' ',
                    "View Subscription"))) : (react_1.default.createElement(react_1.default.Fragment, null)))));
};
exports.ActivatedLiveBanner = ActivatedLiveBanner;
