// @flow

import * as React from 'react';
import { Button } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import styles from '../RegistrationPanel.scss';

export type EmptyStateProps = {
  setShowInfoDialogVisible: boolean => void,
};

const RegistrationEmptyState = ({ setShowInfoDialogVisible }: EmptyStateProps) => {
  return (
    <div className={styles.emptyState}>
      <p>{translate('RegistrationTextCopy')}</p>
      <Button
        variant="Default"
        data-test-id="btn-copy-registration-link"
        onClick={() => {
          setShowInfoDialogVisible(true);
        }}
      >
        {translate('CopyRegistrationLink')}
      </Button>
    </div>
  );
};

export default RegistrationEmptyState;
