// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import type { AppDispatch } from 'app/types/appStateTypes';
import { isFooterVisible } from 'app/utils/dom';
import MessageBox from 'app/components/messageBox/MessageBox';
import translate from 'app/i18n/translate';
import type { SessionType } from 'app/types/globalTypes';
import { ACTION_TYPES } from 'app/constants';
import { NavigationWarning } from 'app/components/navigationWarning/NavigationWarning';
import SaveChangesBar from 'app/pages/security/saveChangesBar/SaveChangesBar';
import { SubjectSecurityView } from './SubjectSecurityView';
import type { SubjectOrGroup } from './subjectSecurityPageTypes';
import styles from './subjectSecurityView.scss';

type SubjectSecurityPageProps = $Exact<{
  canManageQnaSecurity: boolean,
  canViewQnaSecurity: boolean,
  dispatch: AppDispatch,
  hasUnsavedData: boolean,
  loaded: boolean,
  session: SessionType,
  subjects: Array<SubjectOrGroup>,
}>;

export class SubjectSecurityPage extends React.PureComponent<SubjectSecurityPageProps> {
  componentDidUpdate(prevProps: SubjectSecurityPageProps) {
    // We might need to shift the footer up/down as items are expanded
    const changedItems = prevProps.subjects.length !== this.props.subjects.length;
    const nowHasChanges = !prevProps.hasUnsavedData && this.props.hasUnsavedData;

    if (changedItems || nowHasChanges) {
      isFooterVisible(this.props.dispatch);
    }
  }

  componentWillUnmount() {
    const { hasUnsavedData, dispatch } = this.props;
    if (hasUnsavedData) {
      dispatch({ type: ACTION_TYPES.SECURITY.CLEAR_CHANGES });
    }
  }

  render() {
    const {
      dispatch,
      loaded,
      session,
      subjects,
      canManageQnaSecurity,
      canViewQnaSecurity,
      hasUnsavedData,
    } = this.props;

    if (canViewQnaSecurity) {
      return (
        <div
          data-test-id="subjectSecurityTab"
          className={classnames(styles.scrollWrapper, styles.qnaSubjectWrapper)}
        >
          <SubjectSecurityView
            dispatch={dispatch}
            session={session}
            loaded={loaded}
            canManageQnaSecurity={canManageQnaSecurity}
            subjects={subjects}
          />

          {hasUnsavedData && (
            <React.Fragment>
              <SaveChangesBar
                onSave={() => {
                  dispatch({ type: ACTION_TYPES.SECURITY.SUBJECTS.SEND_CHANGES_TO_SERVER });
                }}
                onReset={() => dispatch({ type: ACTION_TYPES.SECURITY.CLEAR_CHANGES })}
              />
              <NavigationWarning />
            </React.Fragment>
          )}
        </div>
      );
    }

    return (
      <MessageBox
        data-test-id="subjectSecurityTab"
        buttonText={translate('LearnMore')}
        buttonAction={() => {
          window.open(
            'https://help.ansarada.com/collaborate-team-and-deal/manage-due-diligence-qanda/add-question-subjects',
            '_blank',
          );
        }}
      >
        {translate('NoQuestionPermission')}
      </MessageBox>
    );
  }
}

const canManageQnaSecuritySelector = (state: Object) => {
  const { currentUserQnaRole, userPermissions } = state.page;
  return (
    !!currentUserQnaRole &&
    userPermissions &&
    userPermissions.securityGroup.modify &&
    userPermissions.questionSubject.modify &&
    userPermissions.questionSubjectGroup.modify
  );
};

const canViewQnaSecuritySelector = (state: Object) => {
  const { currentUserQnaRole, userPermissions } = state.page;
  return (
    !!currentUserQnaRole &&
    userPermissions &&
    userPermissions.questionSubject.select &&
    userPermissions.questionSubjectGroup.select
  );
};

export const mapStateToProps = (state: Object): Object => ({
  session: state.session,
  loaded: state.security.subjects.loaded,
  hasUnsavedData: !!Object.keys(state.security.subjects.changes).length,
  canManageQnaSecurity: canManageQnaSecuritySelector(state),
  canViewQnaSecurity: canViewQnaSecuritySelector(state),
  subjects: state.security.subjects.displaySubjects.filter(
    subject => subject.isGroup || subject.visible,
  ),
});

export default connect<_, _, *, *>(mapStateToProps)(SubjectSecurityPage);
