// @flow

import { ACTION_TYPES, SITE_DIALOG_TYPES } from 'app/constants';

export const toggleDialog = (open: boolean) => {
  return {
    type: ACTION_TYPES.FREEMIUM.TOGGLE_VERIFY_EMAIL_DIALOG,
    data: {
      dialogType: SITE_DIALOG_TYPES.TOGGLE_VERIFY_EMAIL_DIALOG,
      open,
    },
  };
};

export const closeMessage = () => {
  return {
    type: ACTION_TYPES.FREEMIUM.CLOSE_VERIFY_EMAIL_MESSAGE,
  };
};

export const resendVerificationLink = () => {
  return {
    type: ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.RESEND_VERIFICATION_LINK,
  };
};
