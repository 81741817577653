// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { Form, form } from '@ansarada/ace-react';
import { multiSelectUpdate } from 'app/components/multiSelect/multiSelectHelpers';
import translate from 'app/i18n/translate';
import MultiSelect from 'app/components/multiSelect/MultiSelect';
import { ACTION_TYPES } from 'app/constants';
import type { FilterData } from 'app/pages/teams/filter/filterTypes';

type FilterType = {
  dispatch: AppDispatch,
  filterData: FilterData,
  isGuest: boolean,
  qnaEnabled: boolean,
};

const Filter = (props: FilterType) => {
  const { dispatch, filterData, isGuest, qnaEnabled } = props;
  const { status } = filterData.filterItems;
  const statusItems = status.map(s => ({
    id: s.charAt(0).toLowerCase() + s.slice(1),
    // $FlowFixMe we can't be certain the status is in the translations
    text: translate(s),
  }));
  const userTypeItems = isGuest
    ? filterData.filterItems.guestUserTypeItems
    : filterData.filterItems.nonGuestUserTypeItems;

  const onUpdate = multiSelectUpdate({
    type: ACTION_TYPES.TEAMS.FILTER_UPDATE,
    isGuest,
  });

  return (
    <Form variant={form.Variants.Filter} className="teams-filter-container">
      <MultiSelect
        dispatch={dispatch}
        filterName="teamsFilter"
        filterLabel={translate('Team')}
        filterDisplayData={filterData.filters.teamsFilter}
        items={filterData.filterItems.teamsItems}
        update={onUpdate}
        sensitiveContent
      />
      <MultiSelect
        dispatch={dispatch}
        filterName="userTypeFilter"
        filterLabel={translate('RoomRole_SentenceCase')}
        filterDisplayData={filterData.filters.userTypeFilter}
        items={userTypeItems}
        update={onUpdate}
      />
      {qnaEnabled && (
        <MultiSelect
          dispatch={dispatch}
          filterName="qnaFilter"
          filterLabel={translate('QnaRole')}
          filterDisplayData={filterData.filters.qnaFilter}
          items={filterData.filterItems.qnaItems}
          update={onUpdate}
        />
      )}
      <MultiSelect
        dispatch={dispatch}
        filterName="fullNameFilter"
        filterLabel={translate('FullName')}
        filterDisplayData={filterData.filters.fullNameFilter}
        items={filterData.filterItems.fullNameItems}
        update={onUpdate}
        sensitiveContent
      />
      <MultiSelect
        dispatch={dispatch}
        filterName="emailFilter"
        filterLabel={translate('Email')}
        filterDisplayData={filterData.filters.emailFilter}
        items={filterData.filterItems.emailItems}
        update={onUpdate}
        sensitiveContent
      />
      <MultiSelect
        dispatch={dispatch}
        filterName="jobTitleProfessionFilter"
        filterLabel={translate('JobTitleProfession')}
        filterDisplayData={filterData.filters.jobTitleProfessionFilter}
        items={filterData.filterItems.jobTitleProfessionItems}
        update={onUpdate}
      />
      <MultiSelect
        dispatch={dispatch}
        filterName="statusFilter"
        filterLabel={translate('Status')}
        filterDisplayData={filterData.filters.statusFilter}
        items={statusItems}
        update={onUpdate}
      />
    </Form>
  );
};

export default Filter;
