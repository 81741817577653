// @flow
import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  Form,
  FormMultiGroup,
  forms,
  FormSection,
  FormSingleGroup,
  Message,
  Option,
  Radio,
  RadioGroup,
  Select,
  TextArea,
  Tooltip,
} from '@ansarada/ace-react';
import { isAdministrator } from 'app/utils/helpers';
import {
  makeTeamsItems,
  filterTeamsItem,
  fetchSubteamIdForAdmin,
} from 'app/pages/teams/teamsHelpers';
import translate from 'app/i18n/translate';
import classnames from 'classnames';
import FieldErrors from 'app/components/fieldErrors/FieldErrors';
import HelpLink from 'app/components/helpLink/HelpLink';
import FormInputHelp from 'app/pages/teams/formInputHelp/FormInputHelp';
import * as actions from 'app/pages/teams/peopleFormDialog/peopleFormDialogActions';
import * as url from 'app/uri/url';
import { IP_FROM, TERMS_OF_ACCESS } from 'app/pages/teams/teamsConstants';
import { ACTION_TYPES, ELEMENT_IDS, PEOPLE_DIALOG_TYPES, USER_TYPE_CODES } from 'app/constants';
import type { PeopleFormType } from 'app/pages/teams/teamsTypes';
import IpAccessList from 'app/components/ipAccessList/IpAccessList';
import LicenseMessage from 'app/components/licenseMessage/LicenseMessage';
import { getFeature2Data } from 'app/utils/feature2';
import { PremiumAuthorization } from 'app/components/premiumAuthorization';
import FromGroupSendInvitationAuthorization from './components/FromGroupSendInvitationAuthorization';
import FormSingleGroupEmailAuthorization from './components/FormSingleGroupEmailAuthorization';
import InviteGuestBannerAuthorization from './components/InviteGuestBannerAuthorization';
import style from '../peopleFormDialog.scss';

export const FormSingleGroupEmailCreate = (props: { formData: Object, dispatch: AppDispatch }) => {
  const { formData, dispatch } = props;
  return (
    <FormSingleGroup
      contentType={forms.ContentType.Text}
      label={translate('EnterOneOrMoreEmailAddresses')}
      className={style.formSingleGroupEmail}
    >
      <Tooltip defaultOpen={false} id="tooltip" text={translate('TheSafestWayToEnterEmails')}>
        <TextArea
          data-ansarada-ccd
          data-test-id="textEmails"
          className={classnames(style.peopleFormInput, style.peopleEmailInputCreate)}
          status={formData.errors.emails ? 'Bad' : 'Good'}
          defaultValue=""
          onChangeValue={value => dispatch(actions.generateEmailArray(value))}
        />
      </Tooltip>

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <div data-ansarada-ccd>
        <FieldErrors errors={formData.errors.emails} />
      </div>
    </FormSingleGroup>
  );
};

export const FormGroupSameEmail = (props: { formData: Object, dispatch: AppDispatch }) => {
  const { formData, dispatch } = props;
  const sameEmailText = translate('CreatePeopleWithSameEmail');
  return (
    <FormSingleGroup contentType={forms.ContentType.Checkbox}>
      {/* $FlowFixMe ACE requires unnecessary value attribute */}
      <Checkbox
        name="same-email-checkbox"
        id="same-email-checkbox"
        label={sameEmailText}
        className={style.peopleFormInput}
        checked={formData.data.sameEmail}
        onChecked={checked =>
          dispatch(
            actions.changeValue({
              key: 'sameEmail',
              value: checked,
            }),
          )
        }
      />
    </FormSingleGroup>
  );
};

export const FormGroupRoomRole = (props: {
  formData: Object,
  isGuest: boolean,
  dispatch: AppDispatch,
  userTypes: Array<Object>,
}) => {
  const { formData, isGuest, dispatch, userTypes } = props;
  const { data, errors } = formData;
  const errorText = translate('UserTypeError');
  const newErrors = errors.userTypeCode
    ? [
        {
          ...errors.userTypeCode,
          details: errorText,
        },
      ]
    : [];
  return (
    <FormSingleGroup label={translate('DataroomRole')} contentType={forms.ContentType.Select}>
      <Select
        disabled={isGuest}
        data-test-id="userTypeSelect"
        className={style.peopleFormFieldWithHelper}
        value={data.userTypeCode}
        onChangeValue={value =>
          dispatch(
            actions.changeValue({
              key: 'userTypeCode',
              value,
            }),
          )
        }
      >
        <Option value="" text={translate('None')} />
        {userTypes.map(({ userType, isGuest: isUserGuest, userTypeCode: userCode }) => {
          if (isUserGuest === isGuest) {
            const disabled = isAdministrator(userCode) && data.isSubteam;
            return <Option disabled={disabled} key={userCode} value={userCode} text={userType} />;
          }

          return false;
        })}
      </Select>

      {/* $FlowFixMe: Not a ACE React component */}
      <HelpLink helperLinkContent="roomRole" dispatch={dispatch} />

      <FormInputHelp
        text={translate('RoomRoleHelp')}
        findMoreUrl={url.roomRole}
        topic="roomRole"
        className={style.peopleFormInput}
        info={data.isSubteam && translate('RoomRoleAdminSubteamHelp')}
      />

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <FieldErrors errors={newErrors} />
    </FormSingleGroup>
  );
};

export const FormGroupQnaRole = (props: {
  formData: Object,
  dispatch: AppDispatch,
  userTypes: Array<Object>,
}) => {
  const { formData, dispatch, userTypes } = props;
  const { data } = formData;
  return (
    <FormSingleGroup
      contentType={forms.ContentType.Select}
      label={translate('QnaRole_SentenceCase')}
    >
      <Select
        data-test-id="qnaRoleSelect"
        className={style.peopleFormInput}
        value={data.qnaRoleCode || ''}
        onChangeValue={value => dispatch(actions.changeValue({ key: 'qnaRoleCode', value }))}
      >
        <Option key="none" value="" text="None" />
        {userTypes.map(userType =>
          userType.userTypeCode === data.userTypeCode
            ? userType.qnaRoles.map(qnaRole => (
                <Option key={qnaRole.Code} value={qnaRole.Code} text={qnaRole.Name} />
              ))
            : undefined,
        )}
      </Select>
    </FormSingleGroup>
  );
};

export const FormGroupEditTeam = (props: {
  formData: Object,
  dispatch: AppDispatch,
  teams: Array<Object>,
}) => {
  const { formData, dispatch, teams } = props;
  const { data, errors } = formData;
  const teamsItems = makeTeamsItems(teams);
  const subteamIdForAdmin = fetchSubteamIdForAdmin(teams, data.teamId);
  return (
    <FormSingleGroup contentType={forms.ContentType.Select} label={translate('Team')}>
      <Select
        data-ansarada-ccd
        data-test-id="teamSelect"
        className={style.peopleFormInput}
        value={data.subteamId ? data.subteamId : String(subteamIdForAdmin)}
        onChangeValue={value => {
          const teamsItem = filterTeamsItem(teamsItems, value);
          dispatch(actions.changeValue({ key: 'teamId', value: teamsItem.teamId }));
          dispatch(actions.changeValue({ key: 'subteamId', value: teamsItem.subteamId }));
        }}
      >
        {isAdministrator(data.userTypeCode)
          ? teams.map(item => (
              <Option key={item.primarySubteamId} value={item.primarySubteamId} text={item.name} />
            ))
          : teamsItems.map(item => (
              <Option key={item.subteamId} value={item.subteamId} text={item.text} />
            ))}
      </Select>

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <FieldErrors errors={errors.teamId} />
    </FormSingleGroup>
  );
};

export const FormSingleGroupEditTenderSubmission = (props: {
  formData: PeopleFormType,
  dispatch: AppDispatch,
}) => {
  const { formData, dispatch } = props;

  return (
    <FormSingleGroup contentType={forms.ContentType.Checkbox}>
      {/* $FlowFixMe ACE requires unnecessary value attribute */}
      <Checkbox
        name="editAfterTheCloseDate"
        id="editAfterTheCloseDate"
        label={translate('EditAfterCloseDate')}
        className={style.peopleFormInput}
        checked={formData.data.canEditTenderSubmissionAfterClose}
        onChecked={checked =>
          dispatch(
            actions.changeValue({ key: 'canEditTenderSubmissionAfterClose', value: checked }),
          )
        }
      />
    </FormSingleGroup>
  );
};

export const FormGroupTermsOfAccess = (props: { formData: Object, dispatch: AppDispatch }) => {
  const { formData, dispatch } = props;
  const { data, errors } = formData;

  const onTermsOfAccessUpdate = value =>
    dispatch(
      actions.changeValue({
        key: 'termsOfAccess',
        value: Number(value),
      }),
    );

  return (
    <FormMultiGroup
      contentType={forms.ContentType.Radio}
      legend={translate('AcceptTermsOfAccess_SentenceCase')}
      legendId={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
    >
      <RadioGroup
        name="radio-option"
        value={data.termsOfAccess.toString()}
        onChangeValue={onTermsOfAccessUpdate}
        className={style.peopleFormInput}
      >
        <Radio
          aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
          value={TERMS_OF_ACCESS.ONCE.toString()}
          label={translate('OnceOnlyAndWhenTermsAreUpdated')}
          defaultChecked={false}
        />
        <Radio
          aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
          value={TERMS_OF_ACCESS.EVERY.toString()}
          label={translate('OnEveryLogin')}
          defaultChecked={false}
        />
        <Radio
          aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
          value={TERMS_OF_ACCESS.NEVER.toString()}
          label={translate('Never')}
          defaultChecked={false}
        />
      </RadioGroup>

      {/* $FlowFixMe: FieldErrors is not an ACE component */}
      <FieldErrors errors={errors.termsOfAccess} />
    </FormMultiGroup>
  );
};

export const FormGroupIPAccessList = (props: {
  formData: PeopleFormType,
  dispatch: AppDispatch,
  remoteIp: string,
}) => {
  const { formData, dispatch, remoteIp } = props;
  const { data, errors } = formData;
  const onChange = newData => {
    dispatch(actions.changeValue(newData));
  };

  return (
    <FormMultiGroup
      contentType={forms.ContentType.Radio}
      legend={translate('IpAccessList_SentenceCase')}
      legendId={ELEMENT_IDS.PEOPLE_FORM_IP_ACCESS}
    >
      {/* $FlowFixMe: IpAccessList is not ace component */}
      <IpAccessList
        className={style.peopleFormInput}
        errors={errors.userIpAccessList}
        onChange={onChange}
        ipAccessFrom={data.ipAccessFrom || IP_FROM.ANY}
        remoteIp={remoteIp}
        userIpAccessList={data.userIpAccessList || ''}
      />
    </FormMultiGroup>
  );
};

type PeopleFormPropsType = {
  dispatch: AppDispatch,
  peopleForm: Object,
  teams: Array<Object>,
  userTypes: Array<Object>,
  isGuest: boolean,
  useTermsOfAccess: boolean,
  remoteIp: string,
  isUserIpAccessListAllowed: boolean,
  qnaEnabled: boolean,
  submissionToolEnabled: boolean,
  isAdding: boolean,
  hasLicences: boolean,
  licences: Object,
  isEmailVerified: boolean,
};

export class PeopleForm extends React.PureComponent<PeopleFormPropsType> {
  componentDidMount() {
    const { isUserIpAccessListAllowed, peopleForm, dispatch } = this.props;
    if (isUserIpAccessListAllowed && peopleForm.data.type !== PEOPLE_DIALOG_TYPES.CREATE) {
      dispatch({
        type: ACTION_TYPES.TEAMS.PEOPLE_FETCH_IPLIST,
        data: { userId: peopleForm.data.personId },
      });
    }
  }

  render() {
    const {
      dispatch,
      userTypes,
      isGuest,
      teams,
      useTermsOfAccess,
      peopleForm,
      remoteIp,
      isUserIpAccessListAllowed,
      qnaEnabled,
      submissionToolEnabled,
      isAdding,
      hasLicences,
      licences,
      isEmailVerified,
    } = this.props;
    const { showInvitationOnEdit, type } = peopleForm.data;

    return (
      <Form>
        <FormSection
          header="editing-people-form"
          assistiveHeader
          className={style.peopleFormWrapper}
        >
          {isGuest && isEmailVerified && <InviteGuestBannerAuthorization />}

          {!isGuest && isAdding && hasLicences && (
            /* $FlowFixMe: LicenseMessage is not ace component */
            <LicenseMessage
              licences={licences}
              isDialog
              isGuest={isGuest}
              flag={getFeature2Data('home-533-display-count-of-seats-used')}
            />
          )}

          {/* $FlowFixMe: FormGroupRoomRole is not ace component */}
          <FormGroupRoomRole
            formData={peopleForm}
            dispatch={dispatch}
            isGuest={isGuest}
            userTypes={userTypes}
          />

          {qnaEnabled && (
            /* $FlowFixMe: FormGroupQnaRole is not ace component */
            <FormGroupQnaRole formData={peopleForm} dispatch={dispatch} userTypes={userTypes} />
          )}

          {type === PEOPLE_DIALOG_TYPES.CREATE && (
            /* $FlowFixMe: FormSingleGroupEmailCreate is not ace component */
            <FormSingleGroupEmailCreate formData={peopleForm} dispatch={dispatch} />
          )}

          {type === PEOPLE_DIALOG_TYPES.CREATE && (
            /* $FlowFixMe: FormGroupSameEmail is not ace component */
            <FormGroupSameEmail formData={peopleForm} dispatch={dispatch} />
          )}

          {type !== PEOPLE_DIALOG_TYPES.CREATE && (
            <FormSingleGroupEmailAuthorization
              isGuest={isGuest}
              isEmailVerified={isEmailVerified}
              peopleForm={peopleForm}
              dispatch={dispatch}
            />
          )}
          <PremiumAuthorization skipVerify={!isGuest}>
            {showInvitationOnEdit && isEmailVerified && (
              <FormSingleGroup contentType={forms.ContentType.Message}>
                <Message className={style.resendEmailInformation}>
                  {translate('EditPersonFormInvitationMessage')}
                </Message>
              </FormSingleGroup>
            )}
          </PremiumAuthorization>

          {type === PEOPLE_DIALOG_TYPES.EDIT && (
            /* $FlowFixMe: FormGroupEditTeam is not ace component */
            <FormGroupEditTeam formData={peopleForm} dispatch={dispatch} teams={teams} />
          )}

          {isUserIpAccessListAllowed && (
            /* $FlowFixMe: FormGroupIPAccessList is not ace component */
            <FormGroupIPAccessList
              formData={peopleForm}
              dispatch={dispatch}
              teams={teams}
              remoteIp={remoteIp}
            />
          )}

          {useTermsOfAccess && (
            /* $FlowFixMe: FormGroupTermsOfAccess is not ace component */
            <FormGroupTermsOfAccess formData={peopleForm} dispatch={dispatch} />
          )}

          {(type === PEOPLE_DIALOG_TYPES.CREATE || showInvitationOnEdit) && (
            /* $FlowFixMe: FormGroupSendInvitation is not ace component */
            <FromGroupSendInvitationAuthorization
              isGuest={isGuest}
              isEmailVerified={isEmailVerified}
              data={peopleForm.data}
              dispatch={dispatch}
            />
          )}

          {submissionToolEnabled && peopleForm.data.userTypeCode !== USER_TYPE_CODES.ADM && (
            /* $FlowFixMe: FormSingleEditTenderSubmission is not ace component */
            <FormSingleGroupEditTenderSubmission formData={peopleForm} dispatch={dispatch} />
          )}
        </FormSection>
      </Form>
    );
  }
}

const mapStateToProps = (state: Object): Object => ({
  peopleForm: state.teams.viewData.peopleForm,
  userTypes: state.page.userTypes,
  isGuest: state.teams.props.isGuest,
  isUserIpAccessListAllowed: state.session.isUserIpAccessListAllowed,
  remoteIp: state.page.remoteIp,
  teams: state.teams.pageData.teams,
  useTermsOfAccess: state.session.dataRoomUseTermsOfAccess,
  qnaEnabled: state.session.qnaEnabled,
  submissionToolEnabled: state.session.submissionToolEnabled,
  licences: state.page.licences,
  isTendersFreemium: !!state.license?.isTendersFreemium,
});

export default connect<_, _, *, *>(mapStateToProps)(PeopleForm);
