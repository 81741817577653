// @flow

import { links } from 'app/links';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Icon, icon } from '@ansarada/ace-react';
import { PageTitle } from 'app/components/typography/Typography';
import translate from 'app/i18n/translate';
import type { RouterHistory } from 'react-router-dom';
import type { SessionType } from 'app/types/globalTypes';
import type { Team, Subteam } from '../securityTypes';
import styles from './securityHeader.scss';

const SubteamName = (props: { subteams: ?Array<Subteam> }) => {
  if (!props.subteams || props.subteams.length === 0) return null;
  return (
    <h4 className={styles.subTeamHeader}>
      <Icon glyph={icon.Glyphs.ObjectsArrowindent} text=" " />
      {props.subteams[0].name}
    </h4>
  );
};

export const SecurityHeader = (props: {
  history: RouterHistory,
  team: Team,
  isGuest: boolean,
  session: SessionType,
}) => {
  if (!props.team) return null;

  const { history, team, isGuest, session } = props;

  const side = isGuest
    ? session.translationTagNameNonAdminSide
    : session.translationTagNameAdminSide;

  const breadcrumbItems = [
    {
      href: '', // TODO HOME-105: Remove this with the next version of ace-react
      text: '',
    },
    {
      onClick: () => history.push(links.teams(isGuest)),
      text: translate(side),
      'data-test-id': 'securityBackButton',
    },
    {
      href: '',
      text: translate('EditSecurity_SentenceCase'),
    },
  ];

  return (
    <React.Fragment>
      <Breadcrumb items={breadcrumbItems} />

      <PageTitle withSpacing data-ansarada-ccd>
        {`${translate('EditSecurityFor')} ${team.name}`}
      </PageTitle>

      <SubteamName subteams={team.subteams} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: Object): Object => ({
  team: state.security.documents.pageData.team,
  isGuest: state.security.props.isGuest,
  session: state.session,
});

export default withRouter(connect<_, _, *, *>(mapStateToProps)(SecurityHeader));
