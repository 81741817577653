// @flow

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import {
  Form,
  FormSection,
  FormSingleGroup,
  forms,
  Select,
  Option,
  Dialog,
  Button,
  TextArea,
  Radio,
  RadioGroup,
  FormSubItem,
  Checkbox,
  FormMultiGroup,
  TextInput,
} from '@ansarada/ace-react';
import * as url from 'app/uri/url';
import translate from 'app/i18n/translate';
import type { AppStateType, AppDispatch } from 'app/types/appStateTypes';
import type {
  InvitePayloadType,
  RegistrantType,
  NewTeamPayloadType,
} from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import { makeTeamsItems, filterTeamsItem, pickRandomColour } from 'app/pages/teams/teamsHelpers';
import HelpLink from 'app/components/helpLink/HelpLink';
import FormInputHelp from 'app/pages/teams/formInputHelp/FormInputHelp';
import { ACTION_TYPES } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import type { PermissionsType } from 'app/types/permissionsTypes';
import validationSchema from 'app/pages/teams/teamList/registrationPanel/registrantList/registrantActions/inviteRegistrationDialog/validationSchema';
import { isEmptyObject } from 'app/utils/object';
import { getFeature2Data } from 'app/utils/feature2';
import * as teamFormDialodHelpers from 'app/pages/teams/teamFormDialog/teamFormDialogHelpers';
import store from 'app/store';
import VerifyEmailBanner from 'app/pages/teams/verifyEmailBanner/VerifyEmailBanner';
import InviteGuestBannerAuthorization from 'app/pages/teams/peopleFormDialog/peopleForm/components/InviteGuestBannerAuthorization';
import styles from './InviteRegistrationDialog.scss';
import ColourPicker from './colourPicker/ColourPicker';
import SendInvitationCheckboxAuthorization from './components/SendInvitationCheckboxAuthorization';

export type PropsType = {
  registrant: RegistrantType,
  setInviteDialogVisible: (RegistrantType, boolean) => void,
  inviteRegistrationLoading: boolean,
  teams: Array<Object>,
  qnaRoles: Array<Object>,
  dispatch: AppDispatch,
  session: SessionType,
  colourData: Array<Array<string>>,
  isEmailVerified: boolean,
  permissions: PermissionsType,
};

const FieldError = ({ error }: { error: string }) => (
  <div className="ace-form-input-state ace-form-error">{error}</div>
);

function isNil(value: any) {
  return value === undefined || value === null;
}

const InviteRegistrationDialog = ({
  registrant,
  setInviteDialogVisible,
  inviteRegistrationLoading,
  teams,
  qnaRoles,
  dispatch,
  session,
  colourData,
  isEmailVerified,
  permissions,
}: PropsType) => {
  const formRef = useRef<FormikProps<any>>();
  const { Id, Email, AdditionalEmails, Company, RequestedBySubTeamID } = registrant;

  const teamsItems = makeTeamsItems(teams);
  const closeDialog = () => setInviteDialogVisible(registrant, false);
  const emails = [Email, ...AdditionalEmails].join('\n');

  const { thirdPartyIntegrationsDataRoom } = store.getState().teams.pageData;

  const thirdPartyIntegrationsAllowed = teamFormDialodHelpers.getThirdPartyIntegrationsAllowedState(
    thirdPartyIntegrationsDataRoom,
    thirdPartyIntegrationsDataRoom
      ? thirdPartyIntegrationsDataRoom.filter(item => item.isAllowed)
      : [],
  );

  const initialValues = {
    qnaRole: '',
    teamOption: 'new',
    teamName: Company ?? '',
    colour: pickRandomColour(),
    teamId: '',
    subteamId: '',
    fullTextSearchAllowed: true,
    bulkDownloadAllowed: true,
    useSod: true,
    sendEmailInvitations: false,
    thirdPartyIntegrationsAllowed,
    thirdPartyIntegrations: thirdPartyIntegrationsDataRoom,
    selectedTeamId: 0,
  };

  useEffect(() => {
    if (!formRef.current || !teamsItems.length) {
      return;
    }
    const { setFieldValue } = formRef.current;

    const subTeam = teamsItems.find(x => x.subteamId === RequestedBySubTeamID);
    const teamId = subTeam?.subteamId;

    if (!isNil(teamId)) {
      setFieldValue('teamOption', 'existing');
    }

    setFieldValue('selectedTeamId', teamId);
    setFieldValue('teamId', subTeam?.teamId);
    setFieldValue('subteamId', subTeam?.subteamId);
  }, [RequestedBySubTeamID, teamsItems]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({
        handleBlur,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        validateForm,
        setTouched,
      }) => {
        const isShowError = (fieldName: string) => touched[fieldName] && errors[fieldName];
        const onTextBlur = (field: string, e: any) => {
          setFieldValue(field, e.target.value.trim());
          handleBlur(e);
        };

        const handleCheckThirdPartyIntegrations = (checked: boolean) => {
          const newThirdPartyIntegrations = values.thirdPartyIntegrations.map(item => ({
            ...item,
            isAllowed: checked,
          }));
          setValues({
            ...values,
            thirdPartyIntegrationsAllowed: checked,
            thirdPartyIntegrations: newThirdPartyIntegrations,
          });
        };

        const handleSubmit = async () => {
          const err = await validateForm();
          if (!isEmptyObject(err)) {
            const fields = Object.keys(err).reduce((acc, val) => {
              acc[val] = true;
              return acc;
            }, {});
            setTouched(fields);
          } else {
            let invitePayload: InvitePayloadType = {
              id: Id,
              qnaRoleCode: values.qnaRole || null,
              sendEmailInvitations: values.sendEmailInvitations,
            };
            let newTeamPayload: ?NewTeamPayloadType = null;
            if (values.teamOption === 'new') {
              newTeamPayload = {
                isGuest: true,
                name: values.teamName,
                fullTextSearchAllowed: values.fullTextSearchAllowed,
                bulkDownloadAllowed: values.bulkDownloadAllowed,
                useSod: values.useSod,
                type: 'Create',
                colour: values.colour,
                thirdPartyIntegrationsAllowed: values.thirdPartyIntegrationsAllowed,
                thirdPartyIntegrations: values.thirdPartyIntegrations,
              };
            } else {
              invitePayload = {
                ...invitePayload,
                teamId: values.teamId,
                subteamId: values.subteamId,
              };
            }

            dispatch({
              type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.FETCH,
              data: {
                newTeamPayload,
                invitePayload,
              },
            });
          }
        };

        return (
          <Dialog
            title={translate('AcceptRegistration')}
            onClose={closeDialog}
            footerPrimaryActions={[
              <Button
                key="cancel"
                onClick={closeDialog}
                disabled={inviteRegistrationLoading}
                data-test-id="cancel"
              >
                {translate('Cancel')}
              </Button>,
              <Button
                variant="Primary"
                key="invite"
                /* $FlowFixMe */
                onClick={handleSubmit}
                loading={inviteRegistrationLoading}
                data-test-id="invite-registration"
              >
                {values.sendEmailInvitations ? translate('AddAndInvite') : translate('Add')}
              </Button>,
            ]}
          >
            <InviteGuestBannerAuthorization />

            {!isEmailVerified && <VerifyEmailBanner />}

            <Form>
              <FormSection header="invite-registration-form" assistiveHeader>
                <FormSingleGroup contentType={forms.ContentType.Text} label={translate('Email')}>
                  <TextArea
                    data-ansarada-ccd
                    data-test-id="textEmail"
                    className={styles.inviteFormInput}
                    value={emails}
                    onChangeValue={() => {}}
                    key="textEmail"
                    disabled
                  />
                </FormSingleGroup>

                <FormSingleGroup
                  label={translate('DataroomRole')}
                  contentType={forms.ContentType.Select}
                >
                  <Select
                    disabled
                    data-test-id="userTypeSelect"
                    value="GUE"
                    onChangeValue={() => {}}
                    className={styles.inviteFormInput}
                  >
                    <Option disabled key="GUE" value="GUE" text={translate('Guest')} />
                  </Select>
                </FormSingleGroup>

                {session.qnaEnabled && (
                  <FormSingleGroup
                    contentType={forms.ContentType.Select}
                    label={translate('QnaRole_SentenceCase')}
                  >
                    {/* $FlowFixMe */}
                    <Select
                      data-test-id="qnaRoleSelect"
                      className={styles.inviteFormInput}
                      onChangeValue={value => {
                        setFieldValue('qnaRole', value);
                      }}
                      key="qnaRoleSelect"
                      data-ansarada-ccd
                      /* $FlowFixMe */
                      name="qnaRole"
                    >
                      <Option key="none" value="" text={translate('None')} />
                      {qnaRoles.map(role => {
                        const { qnaRoleCode, qnaRoleName } = role;
                        return <Option key={qnaRoleCode} value={qnaRoleCode} text={qnaRoleName} />;
                      })}
                    </Select>
                  </FormSingleGroup>
                )}

                {!values.selectedTeamId && (
                  <FormSingleGroup contentType={forms.ContentType.Text} label="">
                    <RadioGroup
                      aria-describedby={translate('CreateTeam')}
                      layout="Block"
                      name={translate('CreateTeam')}
                      onChangeValue={value => setFieldValue('teamOption', value)}
                      value={values.teamOption}
                    >
                      <Radio label={translate('CreateNewTeam')} value="new" onChecked={() => {}} />
                      <Radio
                        label={translate('AddToExistingTeam')}
                        value="existing"
                        onChecked={() => {}}
                      />
                    </RadioGroup>
                  </FormSingleGroup>
                )}

                {values.teamOption === 'new' ? (
                  <>
                    <FormSingleGroup
                      contentType={forms.ContentType.Text}
                      label={translate('TeamName')}
                    >
                      <TextInput
                        data-ansarada-ccd
                        data-test-id="teamName"
                        className={styles.inviteFormInput}
                        value={values.teamName}
                        onChangeValue={value => setFieldValue('teamName', value)}
                        onBlur={e => onTextBlur('teamName', e)}
                        name="teamName"
                      />
                      {isShowError('teamName') && <FieldError error={errors.teamName} />}
                    </FormSingleGroup>

                    <FormSingleGroup
                      contentType={forms.ContentType.Input}
                      label={translate('Colour')}
                    >
                      <ColourPicker
                        colour={values.colour}
                        onChange={value => setFieldValue('colour', value)}
                        colourData={colourData}
                      />
                    </FormSingleGroup>

                    {permissions.document.modify && (
                      <FormMultiGroup
                        contentType={forms.ContentType.Mixed}
                        legend={translate('TeamPermissions')}
                      >
                        <FormSubItem>
                          <Checkbox
                            data-test-id="fullTextSearch"
                            name="chk-fullTextSearch"
                            id="chk-fullTextSearch"
                            label={translate('FullText')}
                            checked={values.fullTextSearchAllowed}
                            onChecked={checked => setFieldValue('fullTextSearchAllowed', checked)}
                            helpLink={
                              /* $FlowFixMe: Not a ACE React component */
                              <HelpLink helperLinkContent="fullTextSearch" dispatch={dispatch} />
                            }
                            value=""
                          />
                          <FormInputHelp
                            text={translate('FullTextText')}
                            findMoreUrl={url.fullTextSearch}
                            topic="fullTextSearch"
                          />
                        </FormSubItem>

                        <FormSubItem>
                          <Checkbox
                            data-test-id="bulkDownload"
                            name="chk-bulkDownload"
                            id="chk-bulkDownload"
                            label={translate('BulkDownload_SentenceCase')}
                            checked={values.bulkDownloadAllowed}
                            onChecked={checked => setFieldValue('bulkDownloadAllowed', checked)}
                            helpLink={
                              /* $FlowFixMe: Not a ACE React component */
                              <HelpLink helperLinkContent="bulkDownload" dispatch={dispatch} />
                            }
                            value=""
                          />
                          <FormInputHelp
                            text={translate('BulkDownloadText')}
                            findMoreUrl={url.bulkDownload}
                            topic="bulkDownload"
                          />
                        </FormSubItem>
                        {session.documentSecurityEnabled && session.dataRoomUseSod && (
                          <FormSubItem>
                            <Checkbox
                              data-test-id="secureOfficeDocuments"
                              name="chk-sod"
                              id="chk-sod"
                              className="dr-secure-office-checkbox"
                              label={translate('SecureOfficeTitle')}
                              checked={values.useSod}
                              onChecked={checked => setFieldValue('useSod', checked)}
                              helpLink={
                                /* $FlowFixMe: Not a ACE React component */
                                <HelpLink helperLinkContent="sod" dispatch={dispatch} />
                              }
                              value=""
                            />
                            <FormInputHelp
                              text={translate('SecureOfficeText')}
                              findMoreUrl={url.manageSOD}
                              topic="sod"
                              warning={translate('SecureOfficeWarning')}
                            />
                          </FormSubItem>
                        )}
                        {thirdPartyIntegrationsAllowed !== 'hidden' &&
                          getFeature2Data(
                            'dil-1034-add-third-party-integration-checkbox-for-registration-form',
                          ) && (
                            <FormSubItem>
                              {/* $FlowFixMe ACE requires unnecessary value attribute */}
                              <Checkbox
                                data-test-id="thirdPartyIntegrations"
                                name="chk-thirdPartyIntegrations"
                                id="chk-thirdPartyIntegrations"
                                label={translate('ThirdPartyIntegrations_SentenceCase')}
                                checked={values.thirdPartyIntegrationsAllowed}
                                onChecked={handleCheckThirdPartyIntegrations}
                                helpLink={
                                  <HelpLink
                                    helperLinkContent="thirdPartyIntegrations"
                                    dispatch={dispatch}
                                  />
                                }
                                value=""
                              />
                              {/* $FlowFixMe: Not a ACE React component */}
                              <FormInputHelp
                                text={translate('ThirdPartyIntegrationsText')}
                                findMoreUrl={url.thirdpartyintegrations}
                                topic="thirdPartyIntegrations"
                              />
                            </FormSubItem>
                          )}
                      </FormMultiGroup>
                    )}
                  </>
                ) : (
                  <FormSingleGroup contentType={forms.ContentType.Select} label={translate('Team')}>
                    {/* $FlowFixMe */}
                    <Select
                      data-ansarada-ccd
                      data-test-id="teamSelect"
                      className={styles.inviteFormInput}
                      name="teamId"
                      disabled={!!values.selectedTeamId}
                      value={values.selectedTeamId}
                      onChangeValue={value => {
                        if (value) {
                          const teamsItem = filterTeamsItem(teamsItems, value);
                          if (teamsItem) {
                            setFieldValue('teamId', teamsItem.teamId);
                            setFieldValue('subteamId', teamsItem.subteamId);
                          }
                        } else {
                          setFieldValue('teamId', '');
                          setFieldValue('subteamId', '');
                        }
                      }}
                    >
                      <Option key="None" value="" text={translate('None')} />
                      {teamsItems.map(item => (
                        <Option key={item.subteamId} value={item.subteamId} text={item.text} />
                      ))}
                    </Select>
                    {isShowError('teamId') && <FieldError error={errors.teamId} />}
                  </FormSingleGroup>
                )}

                <SendInvitationCheckboxAuthorization
                  isEmailVerified={isEmailVerified}
                  checked={values.sendEmailInvitations}
                  onChecked={checked => setFieldValue('sendEmailInvitations', checked)}
                />
              </FormSection>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export const mapStateToProps = (state: AppStateType) => ({
  qnaRoles: state.page.qnaRoles,
  teams: state.teams.pageData.teams,
  session: state.session,
  colourData: state.teams.viewData.colourPickerData,
  isEmailVerified: state.verifyEmail.isEmailVerified,
  permissions: state.page.userPermissions,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(InviteRegistrationDialog);
