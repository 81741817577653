// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { VerifyEmailData } from 'app/pages/teams/teamsTypes';
import type { QueryType } from 'app/components/emailForm/EmailFormTypes';
import { Button, Dialog, Hero, Icon } from '@ansarada/ace-react';
import { getLocationProps } from 'app/uri/url';
import * as verifyEmailDialogActions from 'app/pages/teams/verifyEmailDialog/verifyEmailDialogActions';
import translate from 'app/i18n/translate';
import DialogImage from './mail.png';
import styles from './verifyEmailDialog.scss';

type OwnProps = {
  location: Object,
};

export type VerifyEmailDialogType = {
  dispatch: AppDispatch,
  verifyEmailData: VerifyEmailData,
  session: SessionType,
  locationProps: QueryType,
} & OwnProps;

export const VerifyEmailDialog = (props: VerifyEmailDialogType) => {
  if (!props.verifyEmailData.isOpenVerifyDialog) {
    return null;
  }

  const { dispatch, session, location, locationProps, verifyEmailData } = props;

  const { translationTagNameNonAdminSide, translationTagNameAdminSide } = session;

  const isGuest = locationProps.isGuest === '1';
  const isEmailPeoplePage = location.pathname.includes('/emailPeople');

  const teamTypeTranslateKey = isGuest
    ? translationTagNameNonAdminSide
    : translationTagNameAdminSide;

  const teamTypeLabel = translate(teamTypeTranslateKey).toLowerCase();

  const heroTitle = isEmailPeoplePage
    ? translate('BeforeYouCanEmail', {
        teamType: teamTypeLabel,
      })
    : translate('BeforeYouCanSendInvites', {
        teamType: teamTypeLabel,
      });

  const closeDialog = () => {
    dispatch(verifyEmailDialogActions.toggleDialog(false));
  };

  const resendVerificationLink = () => {
    dispatch(verifyEmailDialogActions.resendVerificationLink());
  };

  return (
    <Dialog
      closeAltText="close"
      size="Large"
      title={translate('VerifyYourEmail')}
      onClose={closeDialog}
      footerPrimaryActions={[
        <Button
          data-test-id="close"
          key="btnClose"
          onClick={closeDialog}
          className={styles.btnClose}
        >
          {translate('Close')}
        </Button>,
      ]}
      footerSecondaryActions={
        verifyEmailData.isEmailSent ? (
          <div className={styles.btnLink} data-test-id="sent">
            Sent <Icon glyph="StatusEmailSent" className={styles.iconEmail} />
          </div>
        ) : (
          [
            <Button
              data-test-id="resend"
              key="btnResend"
              variant="Link"
              className={styles.btnLink}
              loading={verifyEmailData.isSubmitting}
              onClick={resendVerificationLink}
            >
              {translate('ResendVerificationLink')}
            </Button>,
          ]
        )
      }
    >
      <div className={classnames({ [styles.heroSection]: true })}>
        <Hero
          alt="Ansarada"
          title={heroTitle}
          content={[
            <p key="content">
              {translate('WeHaveSentTheVerificationLinkTo', {
                email: session.userProfileEmail,
              })}
            </p>,
          ]}
          src={DialogImage}
        />
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: Object, ownProps: OwnProps): Object => ({
  verifyEmailData: state.verifyEmail,
  session: state.session,
  locationProps: getLocationProps(ownProps.location),
});

export default withRouter(connect<_, _, *, *>(mapStateToProps)(VerifyEmailDialog));
