// @flow

import * as React from 'react';
import classnames from 'classnames';
import * as R from 'ramda';
import { Column, Table, defaultTableRowRenderer } from 'react-virtualized';
import { DIMENSIONS } from 'app/constants';
import { isItemSecurable } from 'app/pages/security/helpers/securityHelpers';
import Watermarks from 'app/pages/security/watermarks/Watermarks';
import SecurityPolicyToggles from 'app/pages/security/securityPolicyToggles/SecurityPolicyToggles';
import AccessToggle from 'app/pages/security/accessToggle/AccessToggle';
import Document from 'app/pages/security/documentIndex/document/Document';
import Folder from 'app/pages/security/documentIndex/folder/Folder';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { FolderActionsType, WatermarkType } from 'app/pages/security/securityTypes';
import type { SessionType } from 'app/types/globalTypes';
import type { DocumentIndexProps } from './DocumentIndex';

type DocumentIndexTableProps = DocumentIndexProps & {
  width: number,
  height: number,
  scrollTop: number,
  onScroll: Function,
};

const renderSecurityAccess = (
  cell: Object,
  permissions: PermissionsType,
  setSecuritySettings: Function,
) => {
  const security = cell.cellData && cell.cellData.length && cell.cellData[0];
  if (!security) {
    return '';
  }
  const { allowAccess, subteamId, documentIndexItemId } = security;
  const onToggle = () => {
    setSecuritySettings({
      documentIndexItemId,
      subteamId,
      value: !allowAccess,
      attr: 'allowAccess',
    });
  };

  return (
    <AccessToggle
      id={cell.rowData.id}
      isAllowed={allowAccess}
      disabled={!(permissions.securityGroup.modify && permissions.document.modify)}
      data-test-id="securityAccessButton"
      onToggle={onToggle}
    />
  );
};

const SecurityPolicy = (
  cell: Object,
  permissions: PermissionsType,
  dataRoomUseSod: boolean,
  setSecuritySettings: Function,
) => {
  const security = R.pathOr({}, ['cellData', 0], cell);
  const { isFolder } = cell.rowData;
  const fileExt = cell.rowData.fileExtension ? cell.rowData.fileExtension : '';
  const { isSecurable } = cell.rowData;
  const useSod = dataRoomUseSod && security.useSod;
  const itemIsSecurable = isFolder || isItemSecurable(fileExt, isSecurable, useSod);

  return (
    itemIsSecurable && (
      <SecurityPolicyToggles
        documentIndexItemId={security.documentIndexItemId}
        subteamId={security.subteamId}
        securityPolicy={{
          allowPrinting: security.allowPrinting,
          allowEditing: security.allowEditing,
          allowSaving: security.allowSaving,
          allowCopying: security.allowCopying,
          tracking: security.tracking,
        }}
        permissions={permissions}
        itemIsSecurable={itemIsSecurable}
        fileExt={fileExt}
        useSod={useSod}
        setSecuritySettings={setSecuritySettings}
      />
    )
  );
};

const renderSecurityWatermark = (
  watermarks: Array<WatermarkType>,
  cell: Object,
  permissions: PermissionsType,
  session: SessionType,
  setSecuritySettings: Function,
) => {
  const security = cell.cellData && cell.cellData.length && cell.cellData[0];
  return (
    security &&
    cell.rowData.isWatermarkable && (
      <Watermarks
        documentIndexItemId={security.documentIndexItemId}
        subteamId={security.subteamId}
        value={security.watermarkId}
        watermarks={watermarks}
        permissions={permissions}
        session={session}
        setSecuritySettings={setSecuritySettings}
      />
    )
  );
};

const DocumentName = (actions: FolderActionsType, cell: Object) => {
  const item = cell.rowData;
  return item.isFolder ? (
    <Folder
      key={`folder-${item.documentIndexItemId}`}
      name={item.name}
      expandable={item.expandable}
      expanded={item.expanded}
      actions={actions}
      folderId={item.id}
      isDisabled={item.isDisabled}
    />
  ) : (
    <Document
      key={`document-${item.documentIndexItemId}`}
      name={item.name}
      extension={item.fileExtension}
      isDisabled={item.isDisabled}
    />
  );
};

const DocumentRow = (props: Object) => {
  const item = props.rowData;
  const className = classnames(
    props.className,
    'ace-tree-group',
    `ace-tree-level-${item.depth}`,
    `ace-tree-branch-${item.expanded ? 'expanded' : 'collapsed'}`,
    'e2e-document-row',
    `e2e-document-row--${item.isFolder ? 'folder' : 'document'}`,
    `e2e-document-row-id--${item.documentIndexItemId}`,
  );
  return defaultTableRowRenderer({
    ...props,
    className,
  });
};

export const DocumentIndexTable = (props: DocumentIndexTableProps) => (
  <Table
    autoHeight
    width={props.width}
    height={props.height}
    gridClassName="ace-tree-grid"
    rowHeight={DIMENSIONS.TABLE_ROW_HEIGHT}
    rowRenderer={DocumentRow}
    rowCount={props.index.length}
    rowGetter={({ index }) => props.index[index]}
    overscanRowCount={15}
    onScroll={props.onScroll}
    scrollTop={props.scrollTop}
  >
    <Column
      className="dr-spa-document-security-document-name"
      dataKey="securities"
      width={200}
      flexGrow={1}
      cellRenderer={cell => DocumentName(props.actions, cell)}
    />
    <Column
      width={85}
      dataKey="securities"
      flexShrink={0}
      className="dr-spa-document-security-document-access"
      cellRenderer={cell =>
        renderSecurityAccess(cell, props.permissions, props.actions.setSecuritySettings)
      }
    />

    {props.session.documentSecurityEnabled && (
      <Column
        className="dr-spa-document-security-policy"
        width={215}
        flexShrink={0}
        dataKey="securities"
        cellRenderer={cell =>
          SecurityPolicy(
            cell,
            props.permissions,
            props.session.dataRoomUseSod,
            props.actions.setSecuritySettings,
          )
        }
      />
    )}

    <Column
      width={170}
      flexShrink={0}
      dataKey="securities"
      cellRenderer={cell =>
        renderSecurityWatermark(
          props.watermarks,
          cell,
          props.permissions,
          props.session,
          props.actions.setSecuritySettings,
        )
      }
    />
  </Table>
);
