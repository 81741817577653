// @flow

import * as React from 'react';
import { ExpanderPanel } from '@ansarada/ace-react';
import { connect } from 'react-redux';
import translate, { translatePlural } from 'app/i18n/translate';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import { ACTION_TYPES } from 'app/constants';
import type {
  RegistrantType,
  RegistrationSettingsType,
} from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import RegistrantList from 'app/pages/teams/teamList/registrationPanel/registrantList/RegistrantList';

import DeclineRegistrationDialog from 'app/pages/teams/teamList/registrationPanel/registrantList/registrantActions/declineRegistrationDialog/DeclineRegistrationDialog';
import DeleteRegistrationDialog from 'app/pages/teams/teamList/registrationPanel/registrantList/registrantActions/deleteRegistrationDialog/DeleteRegistrationDialog';
import { STATUSES } from 'app/pages/teams/teamList/registrationPanel/constants';
import type { TeamsState } from 'app/pages/teams/teamsTypes';
import styles from './RegistrationPanel.scss';
import RegistrationEmptyState from './registrationEmptyState/RegistrationEmptyState';
import RegistrationPanelActions from './registrationPanelActions/RegistrationPanelActions';
import RegistrationInfoDialog from './registrationInfoDialog/RegistrationInfoDialog';
import InviteRegistrationDialog from './registrantList/registrantActions/inviteRegistrationDialog/InviteRegistrationDialog';
import RegistrationDetailsDialog from './registrantList/registrantActions/registrationDetailsDialog/RegistrationDetailsDialog';
import RegistrationToolDialog from './selfRegistrationToolDialog';

export type PropsType = {
  dispatch: AppDispatch,
  registrants: RegistrantType[],
  getRegistrantsLoading: boolean,
  registrationSettings: RegistrationSettingsType,
  getRegistrationSettingsLoading: boolean,
  declineRegistrationLoading: boolean,
  deleteRegistrationLoading: boolean,
  inviteRegistrationLoading: boolean,
  declineDialogVisible: boolean,
  deleteDialogVisible: boolean,
  inviteDialogVisible: boolean,
  expanded: boolean,
  teams: TeamsState,
};

type StateType = {
  showInfoDialog: boolean,
  showRegistrantDetailsDialog: boolean,
  registrant: RegistrantType,
  showToolDialog: boolean,
};

const defaultRegistrant = {
  Id: 1,
  FirstName: '',
  LastName: '',
  FullName: '',
  Email: '',
  PhoneNumber: '',
  LocationId: -1,
  LocationName: '',
  Company: '',
  JobTitleId: -1,
  JobTitleName: '',
  JobTitleGroupOther: '',
  JobTitleOther: '',
  Status: STATUSES.PEN,
  AdditionalEmails: [],
  InsertDateUtc: '',
  teams: [],
};

export class RegistrationPanelComponent extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      showInfoDialog: false,
      showRegistrantDetailsDialog: false,
      registrant: defaultRegistrant,
      showToolDialog: false,
    };
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.RESET,
    });
  }

  setShowInfoDialogVisible = (visible: boolean) => {
    this.setState({
      showInfoDialog: visible,
    });
  };

  setRegistrantDetailsDialogVisible = (registrant: RegistrantType, visible: boolean) => {
    this.setState({
      showRegistrantDetailsDialog: visible,
      registrant: visible ? registrant : defaultRegistrant,
    });
  };

  setShowToolDialogVisible = (visible: boolean) => {
    this.setState({ showToolDialog: visible });
  };

  setDeclineDialogVisible = (registrant: RegistrantType, visible: boolean) => {
    this.setState({ registrant: visible ? registrant : defaultRegistrant });
    this.props.dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.TOGGLE_DIALOG,
      data: visible,
    });
  };

  setDeleteDialogVisible = (registrant: RegistrantType, visible: boolean) => {
    this.setState({ registrant });
    this.props.dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.TOGGLE_DIALOG,
      data: visible,
    });
  };

  setInviteDialogVisible = (registrant: RegistrantType, visible: boolean) => {
    this.setState({ registrant });
    this.props.dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.TOGGLE_DIALOG,
      data: visible,
    });
  };

  declineRegistration = () => {
    const { registrant } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.FETCH,
      data: registrant.Id,
    });
  };

  deleteRegistration = () => {
    const { registrant } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.FETCH,
      data: registrant.Id,
    });
  };

  toggleExpander = () => {
    this.props.dispatch({
      type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.TOGGLE,
    });
  };

  render() {
    const { showInfoDialog, registrant, showRegistrantDetailsDialog, showToolDialog } = this.state;
    const {
      getRegistrantsLoading,
      registrants,
      getRegistrationSettingsLoading,
      registrationSettings,
      declineRegistrationLoading,
      deleteRegistrationLoading,
      inviteRegistrationLoading,
      declineDialogVisible,
      deleteDialogVisible,
      inviteDialogVisible,
      dispatch,
      expanded,
      teams,
    } = this.props;

    if (getRegistrantsLoading || getRegistrationSettingsLoading) return <></>;

    const url = registrationSettings.SharedSelfRegistrationLink;

    return (
      <>
        <ExpanderPanel
          className={styles.registrationPanel}
          data-test-id="registration-expander"
          title={translate('GuestUserRegistration')}
          data-ansarada-ccd
          expanded={expanded}
          onExpand={() => {
            this.toggleExpander();
          }}
          summary={`${registrants.length} ${translatePlural(
            registrants.length,
            'Registration_LowerCase',
            'Registrations_LowerCase',
          )}`}
          dropdown={
            <RegistrationPanelActions
              key="group"
              setShowInfoDialogVisible={this.setShowInfoDialogVisible}
              registrationSettings={registrationSettings}
              registrants={registrants}
              setShowToolDialogVisible={this.setShowToolDialogVisible}
            />
          }
          registrants={registrants}
        >
          {registrants.length === 0 ? (
            <RegistrationEmptyState
              link={url}
              setShowInfoDialogVisible={this.setShowInfoDialogVisible}
            />
          ) : (
            <RegistrantList
              teams={teams}
              registrants={registrants}
              setDeclineDialogVisible={this.setDeclineDialogVisible}
              setDeleteDialogVisible={this.setDeleteDialogVisible}
              setInviteDialogVisible={this.setInviteDialogVisible}
              setViewDetailsDialogVisible={this.setRegistrantDetailsDialogVisible}
            />
          )}
        </ExpanderPanel>

        {registrationSettings.IsEnabled && showInfoDialog && (
          <RegistrationInfoDialog
            url={url}
            setShowInfoDialogVisible={this.setShowInfoDialogVisible}
            dispatch={dispatch}
          />
        )}
        {declineDialogVisible && (
          <DeclineRegistrationDialog
            declineRegistration={this.declineRegistration}
            declineRegistrationLoading={declineRegistrationLoading}
            registrant={registrant}
            setDeclineDialogVisible={this.setDeclineDialogVisible}
          />
        )}
        {deleteDialogVisible && (
          <DeleteRegistrationDialog
            deleteRegistration={this.deleteRegistration}
            deleteRegistrationLoading={deleteRegistrationLoading}
            registrant={registrant}
            setDeleteDialogVisible={this.setDeleteDialogVisible}
          />
        )}
        {inviteDialogVisible && (
          <InviteRegistrationDialog
            inviteRegistrationLoading={inviteRegistrationLoading}
            registrant={registrant}
            setInviteDialogVisible={this.setInviteDialogVisible}
          />
        )}
        {showRegistrantDetailsDialog && (
          <RegistrationDetailsDialog
            registrant={registrant}
            closeDialog={() => this.setRegistrantDetailsDialogVisible(defaultRegistrant, false)}
            invite={() => this.setInviteDialogVisible(registrant, true)}
            decline={() => this.setDeclineDialogVisible(registrant, true)}
          />
        )}
        {showToolDialog && (
          <RegistrationToolDialog setShowToolDialogVisible={this.setShowToolDialogVisible} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppStateType) => ({
  registrants: state.manageRegistration.registrants,
  getRegistrantsLoading: state.manageRegistration.getRegistrantsLoading,
  registrationSettings: state.manageRegistration.registrationSettings,
  getRegistrationSettingsLoading: state.manageRegistration.getRegistrationSettingsLoading,
  declineRegistrationLoading: state.manageRegistration.declineRegistrationLoading,
  deleteRegistrationLoading: state.manageRegistration.deleteRegistrationLoading,
  inviteRegistrationLoading: state.manageRegistration.inviteRegistrationLoading,
  declineDialogVisible: state.manageRegistration.declineDialogVisible,
  deleteDialogVisible: state.manageRegistration.deleteDialogVisible,
  inviteDialogVisible: state.manageRegistration.inviteDialogVisible,
  expanded: state.manageRegistration.expanded,
  teams: state.teams,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(RegistrationPanelComponent);
