import * as React from 'react';
import {
  Button,
  Dialog,
  FormGridRow,
  FormGridCell,
  TextInput,
  Icon,
  FormattedContentBlock,
} from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import toastActions from 'app/components/toast-message/actions';
import type { AppDispatch } from 'app/types/appStateTypes';
import styles from './RegistrationInfoDialog.scss';

export type RegistrationInfoDialogType = {
  url: string,
  setShowInfoDialogVisible: ((boolean => boolean) | boolean) => void,
  dispatch: AppDispatch,
};

const RegistrationInfoDialog = ({
  url,
  setShowInfoDialogVisible,
  dispatch,
}: RegistrationInfoDialogType) => {
  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    if (!document.body) {
      return;
    }
    textField.textContent = url;
    document.body.appendChild(textField);

    textField.select();
    document.execCommand('copy');
    textField.remove();
  };
  const closeDialog = () => setShowInfoDialogVisible(false);

  return (
    <Dialog
      closeAltText="close"
      disableDefaultClose={false}
      onClose={closeDialog}
      size="Medium"
      title={translate('CopySharedLinkToClipboard')}
    >
      <FormattedContentBlock>
        <p>{translate('CopiedSharedLinkInfo')}</p>
        <FormGridRow className={styles.actionRow}>
          <FormGridCell hideLabel>
            <TextInput readOnly value={url} data-ansarada-ccd />
          </FormGridCell>
          <FormGridCell autoFit>
            <Button
              data-test-id="btn-copy-link"
              key="copy-link"
              size="Medium"
              icon={<Icon glyph="ActionCopy" />}
              onClick={() => {
                copyToClipboard();

                toastActions(dispatch).add({
                  id: 'copy-link',
                  message: translate('LinkCopiedToClipboard'),
                  status: 'success',
                  autoHide: true,
                  contentType: 'TextContent',
                });
                closeDialog();
              }}
            />
          </FormGridCell>
        </FormGridRow>
        <p />
      </FormattedContentBlock>
    </Dialog>
  );
};

export default RegistrationInfoDialog;
