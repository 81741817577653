// @flow

import * as React from 'react';
import { FormGridCell, TextInput } from '@ansarada/ace-react';
import { Field } from 'formik';

type FieldProps = {
  name: string,
  hint: string,
  label: string,
  'data-test-id': string,
  maxLength?: number,
  readOnly?: boolean,
};

type TextInputFocusProps = {
  name: string,
  errors?: Array<string>,
  value: string,
  setFieldValue: Function,
  onBlur: Function,
  focus: boolean,
  maxLength?: number,
  readOnly?: boolean,
};

class TextInputFocus extends React.Component<TextInputFocusProps> {
  componentDidUpdate(prevProps: TextInputFocusProps) {
    if (prevProps.focus && this.inputRef) {
      this.inputRef.focus();
    }
  }

  inputRef: ?HTMLInputElement;

  render() {
    const { name, errors, value, setFieldValue, onBlur, maxLength, readOnly } = this.props;
    return (
      <TextInput
        name={name}
        value={value}
        onChangeValue={newValue => setFieldValue(name, newValue)}
        onBlur={onBlur}
        inputRef={ref => {
          this.inputRef = ref;
        }}
        maxLength={maxLength}
        readOnly={readOnly}
        status={errors ? 'Bad' : undefined}
        data-ansarada-ccd
      />
    );
  }
}

const TextInputField = ({
  name,
  hint,
  label,
  'data-test-id': testId,
  maxLength,
  readOnly,
}: FieldProps) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const { value, onBlur } = field;
        const { setFieldValue } = form;
        const { touched, errors, isValidating, isSubmitting } = form;
        const isFirstError = Object.keys(errors)[0] === name;
        const shouldFocus = !isValidating && isSubmitting && isFirstError;
        return (
          <FormGridCell
            labelText={label}
            errors={errors[name] && touched[name] ? errors[name] : undefined}
            hint={errors[name] && touched[name] ? undefined : hint}
            data-test-id={testId}
          >
            <TextInputFocus
              name={name}
              value={value}
              errors={touched[name] ? errors[name] : undefined}
              onBlur={onBlur}
              maxLength={maxLength}
              setFieldValue={setFieldValue}
              focus={shouldFocus}
              readOnly={readOnly}
            />
          </FormGridCell>
        );
      }}
    />
  );
};

export default TextInputField;
