"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasExpiredTransfer = void 0;
var hasExpiredTransfer = function (customerTransfer) {
    if (!customerTransfer)
        return false;
    var today = new Date();
    var transferExpiryAsDate = customerTransfer.transferExpiryDate
        ? new Date(customerTransfer.transferExpiryDate)
        : today;
    return transferExpiryAsDate < today;
};
exports.hasExpiredTransfer = hasExpiredTransfer;
