// @flow

import * as React from 'react';
import { Router, Route } from 'react-router-dom';
import NotificationPage from 'app/pages/notification/NotificationPage';
import SecurityPage from 'app/pages/security/SecurityPage';
import TeamsPage from 'app/pages/teams/TeamsPage';
import PersonHistoryPage from 'app/pages/personHistory/PersonHistoryPage';
import TeamHistoryPage from 'app/pages/teamHistory/teamHistoryPage';
import { ROUTES } from 'app/constants';
import SendInvitationPage from 'app/pages/sendInvitation/SendInvitationPage';
import EditPeoplePage from 'app/pages/editPeople/EditPeoplePage';
import EmailPeoplePage from 'app/pages/emailPeople/EmailPeoplePage';
import RequestAccessPage from 'app/pages/requestAccess/RequestAccessPage';
import ExternalEventsHandler from 'app/ExternalEventsHandler';
import { getFeature2Data } from 'app/utils/feature2';

const Routes = () => (
  <Router history={window.DATAROOM_SHELL_HISTORY}>
    <Route path={ROUTES.TEAMS} component={TeamsPage} />
    <Route path={ROUTES.SECURITY} component={SecurityPage} />
    <Route path={ROUTES.NOTIFICATIONS} component={NotificationPage} />
    <Route path={ROUTES.TEAM_HISTORY} component={TeamHistoryPage} />
    <Route path={ROUTES.PERSON_HISTORY} component={PersonHistoryPage} />
    <Route path={ROUTES.SEND_INVITATION} component={SendInvitationPage} />
    <Route path={ROUTES.EDIT_PEOPLE} component={EditPeoplePage} />
    <Route path={ROUTES.EMAIL_PEOPLE} component={EmailPeoplePage} />
    {getFeature2Data('deals-217-guest-registration-request') && (
      <Route path={ROUTES.REQUEST_ACCESS} component={RequestAccessPage} />
    )}
    <ExternalEventsHandler />
  </Router>
);

export default Routes;
