"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToCamelCase = exports.camelCase = void 0;
var camelCase = function (str) {
    var newStr = str.replace(/[-_\s.]+(.)?/g, function (_, c) { return (c ? c.toUpperCase() : ''); });
    return newStr.substring(0, 1).toLowerCase() + newStr.substring(1);
};
exports.camelCase = camelCase;
var objectToCamelCase = function (obj) {
    if (obj === null || obj === undefined) {
        return obj;
    }
    var newObj = Array.isArray(obj) ? [] : {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        var newKey = (0, exports.camelCase)(key);
        if (typeof value === 'object') {
            newObj[newKey] = (0, exports.objectToCamelCase)(value);
        }
        else {
            newObj[newKey] = value;
        }
    }
    return newObj;
};
exports.objectToCamelCase = objectToCamelCase;
