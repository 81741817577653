// @flow

import { Asset, asset, Icon } from '@ansarada/ace-react';
import type { Attachments } from 'app/components/emailForm/EmailFormTypes';
import * as React from 'react';
import styles from './attachments.scss';
import FileSelectButton from './FileSelectButton';

type Props = {
  attachments: Attachments,
  isUploadingAttachment: boolean,
  onFileSelected: (files: Array<File>) => void,
  onRemove: (attachmentId: number) => void,
};

const getFullFileName = (fileName, extension) => {
  return extension ? `${fileName}.${extension}` : fileName;
};

const getDocumentType = extension => {
  switch (extension && typeof extension === 'string' && extension.toLowerCase()) {
    case 'pdf':
      return 'acrobat';
    case 'mp3':
    case 'wav':
    case 'mid':
      return 'audio';
    case 'gz':
    case 'zip':
    case 'rar':
    case 'zipx':
      return 'archive';
    case 'xls':
    case 'xlsx':
    case 'xltx':
    case 'xlsb':
    case 'xlt':
    case 'xlw':
    case 'xsl':
    case 'xlsm':
    case 'xlst':
      return 'excel';
    case 'crd':
      return 'contact';
    case 'eml':
    case 'msg':
    case 'oft':
      return 'email';
    case 'bmp':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
    case 'mht':
    case 'mhmt':
    case 'tiff':
      return 'image';
    case 'dwg':
    case 'psd':
    case 'indd':
    case 'dxf':
    case 'shx':
      return 'layoutanddesign';
    case 'potm':
    case 'ppsm':
    case 'pptm':
    case 'potx':
    case 'pptx':
    case 'pot':
    case 'pps':
      return 'powerpoint';
    case 'txt':
    case 'log':
    case 'rtf':
    case 'csv':
      return 'text';
    case 'avi':
    case 'mov':
    case 'mpg':
    case 'mp4':
      return 'video';
    case 'vdx':
    case 'vst':
    case 'vsx':
    case 'vsd':
    case 'vss':
    case 'vtx':
      return 'visio';
    case 'doc':
    case 'docx':
    case 'docm':
    case 'dot':
    case 'dotm':
      return 'word';
    case 'css':
    case 'js':
    case 'dtd':
    case 'cp':
    case 'xml':
      return 'code';
    default:
      return 'generic';
  }
};

const AttachmentList = (props: Props) => {
  const { attachments, onFileSelected, onRemove, isUploadingAttachment } = props;
  return (
    <div>
      <FileSelectButton
        text="Choose your file"
        onFilesSelected={onFileSelected}
        uploading={isUploadingAttachment}
      />
      <ul data-test-id="attachmentList" className={styles.list}>
        {attachments.map(attachment => (
          <li key={attachment.id} data-test-id="attachment" data-ansarada-ccd>
            <Asset
              fileType={getDocumentType(attachment.fileExtension)}
              name={getFullFileName(attachment.fileName, attachment.fileExtension)}
              className={styles.asset}
              variant={asset.Variants.File}
              data-test-id="attachmentName"
            />
            <button
              type="button"
              className={styles.removeButton}
              data-test-id="removeButton"
              onClick={() => onRemove(attachment.id)}
            >
              <Icon glyph="ActionRemove" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AttachmentList;
