// @flow

import * as React from 'react';
import { AceText, Icon } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { STATUS_ICONS, STATUS_TEXTS } from 'app/pages/teams/teamList/registrationPanel/constants';
import styles from './RegistrantStatus.scss';

export type PropsType = {
  status: string,
};

const RegistrantStatus = ({ status }: PropsType) => {
  return (
    <span data-test-id="registrant-status">
      <Icon text="disabled" glyph={STATUS_ICONS[status]} className={styles.icon} />
      <AceText
        text={STATUS_TEXTS[status] ? translate(STATUS_TEXTS[status]) : translate('Unknown')}
      />
    </span>
  );
};

export default RegistrantStatus;
