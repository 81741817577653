// @flow

import { FormattedContentBlock } from '@ansarada/ace-react';
import * as React from 'react';
import Dialog from 'app/components/dialog/Dialog';
import translate from 'app/i18n/translate';

const SecurityHelperDialog = () => (
  <Dialog data-test-id="securityHelperDialog" closeButtonText={translate('GotIt')}>
    <FormattedContentBlock>
      <h4>{translate('SecurityControlsOn')}</h4>
      <p>{translate('SecurityControlsOnDescription')}</p>
      <p>{translate('SecurityControlsOnExtraDescription')}</p>
      <h4>{translate('SecurityControlsOff')}</h4>
      <p>{translate('SecurityControlsOffDescription')}</p>
    </FormattedContentBlock>
  </Dialog>
);

export default SecurityHelperDialog;
