// @flow

import type { TranslationKeys } from 'app/i18n/translate';

export const getMessageCode = (code: string): TranslationKeys => {
  switch (code) {
    case 'UserGroupNameExistsException':
      return 'TeamNameAlreadyExists';
    case 'SecurityGroupNameAlreadyExistsException':
      return 'SubTeamNameAlreadyExists';
    case 'SecurityGroupContainsUsersException':
    case 'UserGroupContainsUsersException':
      return 'DeletingTeamContainsPeople';
    default:
      /* other cases include
        DuplicatedPrimarySecurityGroupsException
        NoPrimarySecurityGroupException
        InvalidUserDataException
        which a generic error message is returned
        */
      return 'GlobalUnexpectedErrorMessage';
  }
};
