// @flow

import * as React from 'react';
import { RadioGroup, Radio } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';

import { EMAIL_IMPORTANCE } from 'app/components/emailForm/constants';

export type EmailImportanceTypes = $Values<typeof EMAIL_IMPORTANCE>;

type Props = {|
  value: EmailImportanceTypes,
  onUpdate: (value: string) => void,
|};

const Importance = (props: Props) => {
  const { value, onUpdate } = props;
  return (
    <RadioGroup name="emailImportance" value={value} onChangeValue={onUpdate}>
      <Radio
        data-test-id="importanceLow"
        label={translate('Low')}
        value={EMAIL_IMPORTANCE.LOW}
        defaultChecked={false}
      />
      <Radio
        data-test-id="importanceNormal"
        label={translate('Normal')}
        value={EMAIL_IMPORTANCE.NORMAL}
        defaultChecked={false}
      />
      <Radio
        data-test-id="importanceHigh"
        label={translate('High')}
        value={EMAIL_IMPORTANCE.HIGH}
        defaultChecked={false}
      />
    </RadioGroup>
  );
};

export default Importance;
