// @flow

import { links } from 'app/links';
import { withRouter } from 'react-router-dom';
import WarningDialog from 'app/components/warningDialog/WarningDialog';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { RouterHistory } from 'react-router-dom';
import * as React from 'react';
import classNames from 'classnames';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import {
  icon,
  Button,
  Icon,
  TableRowLegacy,
  TableDataLegacy,
  DropdownGroup,
  DropdownAction,
  Link,
  AceText,
} from '@ansarada/ace-react';
import * as url from 'app/uri/url';
import Dropdown from 'app/components/dropdown/Dropdown';
import { ACTION_TYPES, EMPTY_NAME_CHAR, USER_TYPE_CODES } from 'app/constants';
import translate from 'app/i18n/translate';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SessionType } from 'app/types/globalTypes';
import EnableGuestAccessAuthorization from 'app/components/enableGuestAccessAuthorization/EnableGuestAccessAuthorization';
import { SubmissionPermissionInfo } from './submissionPermissionInfo/SubmissionPermissionInfo';

import styles from './person.scss';
import SendPersonInviteDropdownAction from './components/SendPersonInviteDropdownAction';
import SendInviteForPersonNotInvited from './components/SendInviteForPersonNotInvited';

export const PersonDropdown = (props: {
  person: Object,
  history: RouterHistory,
  dispatch: AppDispatch,
  permissions: PermissionsType,
  session: SessionType,
  isEmailVerified: boolean,
  onDelete: () => void,
}) => {
  const { person, dispatch, session, permissions, onDelete, history, isEmailVerified } = props;
  const { userId } = session;

  const personStatusText = person.disabled ? translate('EnableAccess') : translate('DisableAccess');

  const showDeletePersonAction = userId !== person.personId;

  return (
    <Dropdown
      data-test-id={`dropdown-person-${person.personId}`}
      trigger={
        <Button
          size="Small"
          variant="Ghost"
          data-test-id="dropdownButton"
          icon={<Icon text="control-menu" glyph={icon.Glyphs.ControlMenuContextual} />}
        />
      }
    >
      {permissions.user.modify && (
        <React.Fragment>
          <DropdownGroup>
            <DropdownAction
              data-test-id="editPerson"
              onClick={() => {
                dispatch({
                  type: ACTION_TYPES.DIALOG_TOGGLE,
                  data: translate('EditPerson'),
                });
                dispatch({ type: ACTION_TYPES.TEAMS.PEOPLE_FORM_EDIT, data: person });
              }}
            >
              {translate('EditPerson')}
            </DropdownAction>

            <EnableGuestAccessAuthorization>
              {({ isAllowEnableGuestAccess }) => (
                <DropdownAction
                  data-test-id="toggleStatus"
                  onClick={() => {
                    dispatch({ type: ACTION_TYPES.TEAMS.PERSON_TOGGLE_DISABLE, data: person });
                  }}
                  disabled={person.isGuest && person.disabled && !isAllowEnableGuestAccess}
                >
                  {personStatusText}
                </DropdownAction>
              )}
            </EnableGuestAccessAuthorization>

            {showDeletePersonAction && (
              <DropdownAction data-test-id="deletePerson" onClick={onDelete}>
                {translate('DeletePerson')}
              </DropdownAction>
            )}
          </DropdownGroup>
          {!person.disabled && (
            <SendPersonInviteDropdownAction
              history={history}
              person={person}
              isEmailVerified={isEmailVerified}
            />
          )}
        </React.Fragment>
      )}

      {permissions.user.select && (
        <DropdownGroup>
          <DropdownAction
            data-test-id="viewPersonHistory"
            onClick={() => {
              history.push(links.personHistory(person.personId, person.isGuest));
            }}
          >
            {translate('History')}
          </DropdownAction>
        </DropdownGroup>
      )}
    </Dropdown>
  );
};

type PersonProps = {
  person: Object,
  dispatch: AppDispatch,
  permissions: PermissionsType,
  session: SessionType,
  history: RouterHistory,
  isEmailVerified: boolean,
};
type PersonState = {
  warningDialogVisible: boolean,
};
class Person extends React.Component<PersonProps, PersonState> {
  constructor(props: PersonProps) {
    super(props);
    this.state = {
      warningDialogVisible: false,
    };
  }

  onDelete = () => {
    this.setState({
      warningDialogVisible: true,
    });
  };

  onConfirmDeletePerson = () => {
    const { dispatch, person } = this.props;
    this.hideWarningDialog();
    dispatch({ type: ACTION_TYPES.TEAMS.PERSON_CONFIRM_DELETE, data: person });
  };

  hideWarningDialog = () => {
    this.setState({
      warningDialogVisible: false,
    });
  };

  render() {
    const { person, dispatch, permissions, session, history, isEmailVerified } = this.props;
    const classnamesName = classNames(styles.personDetailsName, {
      [styles.disabled]: person.disabled,
    });
    const classnamesColumn = classNames(styles.personDetailsColumn, {
      'ace-table-disabled': person.disabled,
    });

    return (
      <TableRowLegacy
        key={person.personId}
        data-test-type="person"
        data-test-person-id={person.personId}
        className={styles.personDetailsContainer}
      >
        <TableDataLegacy className={classnamesName} data-ansarada-ccd>
          {person.name !== EMPTY_NAME_CHAR && (
            <span className={styles.name}>
              {person.name}
              <br />
            </span>
          )}
          <Link className={styles.mail} data-test-id="mail" href={`mailto:${person.email}`}>
            {person.email}
          </Link>
          <br />
          {/* .filter below filters undefined values */}
          {[person.industry, person.title].filter(x => x).join(', ')}
        </TableDataLegacy>

        <TableDataLegacy className={classnamesColumn}>
          <p data-test-id="userType">{person.userType}</p>
        </TableDataLegacy>
        {session.qnaEnabled && (
          <TableDataLegacy className={classnamesColumn}>
            <p data-test-id="qnaRole">{person.qnaRole}</p>
          </TableDataLegacy>
        )}
        <TableDataLegacy className={classnamesColumn} data-test-id="details">
          {person.disabled && (
            <span data-test-id="personDisabled">
              <Icon text="disabled" glyph={icon.Glyphs.StatusDisabled} />
              <AceText text={` ${translate('Disabled')}`} />
              <br />
            </span>
          )}
          {!person.isVerified && !person.invited && (
            <SendInviteForPersonNotInvited history={history} person={person} />
          )}
          {!person.isVerified && person.invited && (
            <span data-test-id="person-invited">
              <Icon text="invited" glyph={icon.Glyphs.StatusEmailSent} />
              <AceText text={` ${translate('Invited')}`} />
            </span>
          )}
          {person.isVerified && !!person.loginCount && !!person.lastLoginDate && (
            <p data-test-id="login-count">
              {person.excludeFromReports ? (
                person.loginCount
              ) : (
                <Link
                  className={classNames(!!person.disabled && 'ace-table-disabled')}
                  href={url.generateLegacyURL('UserActivityReport_New.asp', session, '', {
                    UserId: person.personId,
                  })}
                >
                  {person.loginCount}
                </Link>
              )}
              &nbsp;
              {person.loginCount > 1 ? translate('LoginsLowerCase') : translate('LoginLowerCase')}
              &nbsp;(last: {distanceInWordsToNow(person.lastLoginDate)} ago)
            </p>
          )}
          {session.submissionToolEnabled && person.userTypeCode !== USER_TYPE_CODES.ADM && (
            <SubmissionPermissionInfo
              canEditTenderSubmissionAfterClose={person.canEditTenderSubmissionAfterClose}
            />
          )}
        </TableDataLegacy>

        <TableDataLegacy className="ace-table-cell-control">
          <PersonDropdown
            dispatch={dispatch}
            person={person}
            permissions={permissions}
            session={session}
            onDelete={this.onDelete}
            history={history}
            isEmailVerified={isEmailVerified}
          />
        </TableDataLegacy>
        <WarningDialog
          sensitiveContent
          visible={this.state.warningDialogVisible}
          header={translate('DeletePersonHeader', { email: person.email })}
          message={translate('DeletePersonMessage', { email: person.email })}
          confirmText={translate('DeletePersonConfirm')}
          isDanger
          isMedium
          onCancel={this.hideWarningDialog}
          onConfirm={this.onConfirmDeletePerson}
        />
      </TableRowLegacy>
    );
  }
}

export default withRouter(Person);
