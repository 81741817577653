// @flow

import * as React from 'react';
import { SwatchesPicker } from 'react-color';
import styles from 'app/components/colourPicker/colourPicker.scss';

export type PropsType = {
  colourData?: Array<Array<string>>,
  colour: string, // this is a hex string
  onChange: string => void,
};

const ColourPicker = (props: PropsType) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const { onChange, colour, colourData } = props;

  return (
    <div data-test-id="colourPicker">
      <div
        role="button"
        tabIndex="0"
        className="dr-colour-picker-swatch"
        onClick={() => setIsVisible(true)}
      >
        <div className={styles.color} style={{ backgroundColor: colour }} />
      </div>

      {isVisible ? (
        <div className={styles.popover}>
          <div
            role="button"
            tabIndex="0"
            className={styles.cover}
            onClick={() => setIsVisible(false)}
          />
          <SwatchesPicker
            colors={colourData}
            color={colour}
            width="335px"
            onChange={e => {
              onChange(e.hex);
              setIsVisible(false);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColourPicker;
