// @flow

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import Subscription from 'app/Subscription';

type PageContainerType = {
  history: RouterHistory,
};

class ExternalEventsHandler extends React.Component<PageContainerType> {
  componentDidMount() {
    this.onNavigationScrollTop();
    this.onNavigationUpdateIntercom();
  }

  onNavigationScrollTop() {
    this.props.history.listen(() => {
      // Currenly, all our routes that are hooked up to URL navigation require the page to be scrolled
      // to the top, if we introduce a page or subpage (eg. for tabbed navigation) then we should
      // change this to only scroll when necesary, see here for more info:
      // https://reacttraining.com/react-router/web/guides/scroll-restoration
      window.scrollTo(0, 0);
    });
  }

  onNavigationUpdateIntercom() {
    this.props.history.listen(() => {
      // "For single paged apps.... You will need to call Intercom("update") in order to initiate a
      // "ping" every time a URL changes."  See here:
      // https://www.intercom.com/help/install-intercom-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app
      if (typeof window.Intercom === 'function') {
        window.Intercom('update');
      }
    });
  }

  render() {
    return (
      <>
        <Subscription />
      </>
    );
  }
}

export default withRouter(ExternalEventsHandler);
