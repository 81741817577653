// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Panel, panel, Button } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import style from './saveChangesBar.scss';

export const SaveChangesBar = (props: {
  isSaving: boolean,
  isFooterVisible: boolean,
  onSave: () => void,
  onReset: () => void,
}) => (
  <Panel
    data-test-id="saveChangesBar"
    variant={panel.Variants.Secondary}
    className={`${style.footer} ${props.isFooterVisible ? style.footerFollow : style.footerBottom}`}
  >
    <Button
      className="button-legacy-support"
      data-test-id="securityFooterResetButton"
      onClick={props.onReset}
    >
      {translate('Reset')}
    </Button>

    <Button
      loading={props.isSaving}
      variant="Primary"
      className="button-legacy-support"
      data-test-id="securityFooterSaveButton"
      onClick={props.onSave}
    >
      {translate('Save')}
    </Button>
  </Panel>
);

const mapStateToProps = (state: Object): Object => ({
  isFooterVisible: state.page.isFooterVisible,
  isSaving:
    state.security.documents.treeData.isSaving ||
    state.security.subjects.isSaving ||
    state.security.tenderSubmissions.isSaving,
});

export default connect<_, _, *, *>(mapStateToProps)(SaveChangesBar);
