// @flow

import * as React from 'react';
import { Icon, icon, Dropdown, Button, DropdownGroup, DropdownAction } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { RegistrantType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';
import { STATUSES } from 'app/pages/teams/teamList/registrationPanel/constants';

export type PropsType = {
  registrant: RegistrantType,
  setDeclineDialogVisible: (RegistrantType, boolean) => void,
  setDeleteDialogVisible: (RegistrantType, boolean) => void,
  setInviteDialogVisible: (RegistrantType, boolean) => void,
  setViewDetailsDialogVisible: (RegistrantType, boolean) => void,
};

const RegistrantActions = ({
  registrant,
  setDeclineDialogVisible,
  setDeleteDialogVisible,
  setInviteDialogVisible,
  setViewDetailsDialogVisible,
}: PropsType) => {
  const { Status } = registrant;

  return (
    <>
      <Dropdown
        defaultOpen={false}
        trigger={
          <Button
            size="Small"
            variant="Ghost"
            data-test-id="registration"
            icon={<Icon text="control-menu" glyph={icon.Glyphs.ControlMenuContextual} />}
          />
        }
      >
        <DropdownGroup>
          <DropdownAction
            data-test-id="view-registration"
            icon={<Icon glyph={icon.Glyphs.StatusInformation} text="" />}
            onClick={() => setViewDetailsDialogVisible(registrant, true)}
          >
            {translate('ViewDetails')}
          </DropdownAction>
          {[STATUSES.PEN, STATUSES.DEC].includes(Status) && (
            <DropdownAction
              data-test-id="accept-registration"
              icon={<Icon glyph={icon.Glyphs.ActionEnable} text="" />}
              onClick={() => setInviteDialogVisible(registrant, true)}
            >
              {translate('AcceptRegistration')}
            </DropdownAction>
          )}
        </DropdownGroup>
        <DropdownGroup>
          {Status === STATUSES.PEN && (
            <DropdownAction
              data-test-id="decline-registration"
              icon={<Icon glyph={icon.Glyphs.ActionDisable} text="" />}
              onClick={() => setDeclineDialogVisible(registrant, true)}
            >
              {translate('DeclineRegistration')}
            </DropdownAction>
          )}
          <DropdownAction
            data-test-id="delete-registration"
            icon={<Icon glyph={icon.Glyphs.ActionRemove} text="" />}
            onClick={() => setDeleteDialogVisible(registrant, true)}
          >
            {translate('DeleteRegistration')}
          </DropdownAction>
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default RegistrantActions;
