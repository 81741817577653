// @flow

import * as React from 'react';
import { FormGridCell, TextArea } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import styles from './AdditionalRecipients.scss';

type Props = {|
  errors: string,
  name: string,
  value: string,
  onUpdate: (value: string) => void,
|};

const AdditionalRecipients = (props: Props) => {
  const { errors, onUpdate, name, value } = props;
  const messageHint = translate('AdditionalRecipientsHint');
  return (
    <fieldset>
      <legend>
        <h3 className={styles.sectionTitle}>{translate('AdditionalRecipients_SentenceCase')}</h3>
      </legend>

      <FormGridCell
        data-test-id="additionalRecipientsLabel"
        labelText={translate('Emails')}
        errors={errors}
        hint={messageHint && !errors ? messageHint : undefined}
      >
        <TextArea
          data-test-id="additionalRecipients"
          name={name}
          value={value}
          onChangeValue={onUpdate}
          data-ansarada-ccd
        />
      </FormGridCell>
    </fieldset>
  );
};

export default AdditionalRecipients;
