// @flow

import IpAccessList from 'app/components/ipAccessList/IpAccessList';
import { ELEMENT_IDS, USER_TYPE_CODES } from 'app/constants';
import { PERSON_STATUS, TERMS_OF_ACCESS } from 'app/pages/teams/teamsConstants';
import * as url from 'app/uri/url';
import type { Teams } from 'app/types/teamsTypes';
import * as React from 'react';
import { Option, Radio, RadioGroup, Toggle } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type {
  CheckedValue,
  IpAccessValue,
  OptionalCheckedValue,
} from 'app/pages/editPeople/editPeopleTypes';
import CheckableSelectField from 'app/pages/editPeople/propertyForm/CheckableSelectField';
import CheckableField from './CheckableField';
import styles from './propertyForm.scss';

type PropsType = {
  isGuest: boolean,
  qnaEnabled: boolean,
  roomRole: CheckedValue<string>,
  qnaRole: CheckedValue<string>,
  subTeam: CheckedValue<string>,
  userTypes: Array<*>,
  teams: Teams,
  activityReport: CheckedValue<boolean>,
  status: CheckedValue<number>,
  isUserIpAccessListAllowed: boolean,
  remoteIp: string,
  ipAccessList: CheckedValue<IpAccessValue>,
  onRoomRoleChange: (OptionalCheckedValue<string>) => void,
  onQnaRoleChange: (OptionalCheckedValue<string>) => void,
  onSubTeamChange: (OptionalCheckedValue<string>) => void,
  onActivityReportChange: (OptionalCheckedValue<boolean>) => void,
  onStatusChange: (OptionalCheckedValue<number>) => void,
  onIpAccessListChange: (OptionalCheckedValue<IpAccessValue>) => void,
  errors: Object,
  useTermsOfAccess: boolean,
  termsOfAccess: CheckedValue<string>,
  onTermsOfAccessChange: (OptionalCheckedValue<string>) => void,
  isAllowEnableGuestAccess: boolean,
};

const PropertyForm = (props: PropsType) => {
  const {
    roomRole,
    qnaRole,
    qnaEnabled,
    isGuest,
    userTypes,
    onRoomRoleChange,
    onQnaRoleChange,
    subTeam,
    teams,
    onSubTeamChange,
    activityReport,
    status,
    onActivityReportChange,
    onStatusChange,
    isUserIpAccessListAllowed,
    remoteIp,
    ipAccessList,
    onIpAccessListChange,
    errors,
    useTermsOfAccess,
    termsOfAccess,
    onTermsOfAccessChange,
    isAllowEnableGuestAccess,
  } = props;
  return (
    <>
      <CheckableSelectField
        label={translate('RoomRole_SentenceCase')}
        checked={roomRole.checked}
        value={roomRole.value}
        onChange={onRoomRoleChange}
        className={styles.alignCenter}
        data-test-id="roomRoleField"
        helpText={translate('RoomRoleHelp')}
        helpUrl={url.roomRole}
        error={errors.roomRole}
      >
        {userTypes.map(({ userType, isGuest: isUserGuest, userTypeCode: userCode }) => {
          if (isUserGuest === isGuest) {
            return <Option key={userCode} value={userCode} text={userType} />;
          }
          return false;
        })}
      </CheckableSelectField>
      {qnaEnabled && (
        <CheckableSelectField
          label={translate('QnaRole_SentenceCase')}
          disabled={!roomRole.checked}
          checked={qnaRole.checked}
          value={qnaRole.value}
          onChange={onQnaRoleChange}
          className={styles.alignCenter}
          data-test-id="qnaRoleField"
        >
          {userTypes.map(userType =>
            userType.userTypeCode === roomRole.value
              ? userType.qnaRoles.map(({ Code, Name }) => (
                  <Option key={Code} value={Code} text={Name} />
                ))
              : undefined,
          )}
        </CheckableSelectField>
      )}
      <CheckableSelectField
        label={translate('Team')}
        checked={subTeam.checked}
        value={subTeam.value}
        onChange={onSubTeamChange}
        className={styles.alignCenter}
        data-test-id="teamField"
        error={errors.team}
      >
        {teams.map(({ subteamId, name, subteams }) => (
          // $FlowFixMe
          <React.Fragment key={subteamId}>
            <Option key={subteamId} value={String(subteamId)} text={name} />
            {roomRole.value !== USER_TYPE_CODES.ADM &&
              subteams.map(subteam => (
                <Option
                  key={subteam.subteamId}
                  value={String(subteam.subteamId)}
                  text={`${name}: ${subteam.name}`}
                />
              ))}
          </React.Fragment>
        ))}
      </CheckableSelectField>
      {useTermsOfAccess && (
        <CheckableField
          label={translate('AcceptTermsOfAccess_SentenceCase')}
          checked={termsOfAccess.checked}
          onChange={onTermsOfAccessChange}
          data-test-id="termsOfAccessField"
        >
          <RadioGroup
            name="radioTerms"
            value={termsOfAccess.value}
            onChangeValue={value => onTermsOfAccessChange({ value })}
          >
            <Radio
              aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
              value={TERMS_OF_ACCESS.ONCE.toString()}
              label={translate('OnceOnlyAndWhenTermsAreUpdated')}
              disabled={!termsOfAccess.checked}
              defaultChecked={false}
            />
            <Radio
              aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
              value={TERMS_OF_ACCESS.EVERY.toString()}
              label={translate('OnEveryLogin')}
              disabled={!termsOfAccess.checked}
              defaultChecked={false}
            />
            <Radio
              aria-describedby={ELEMENT_IDS.PEOPLE_FORM_TERMS_OF_ACCESS}
              value={TERMS_OF_ACCESS.NEVER.toString()}
              label={translate('Never')}
              disabled={!termsOfAccess.checked}
              defaultChecked={false}
            />
          </RadioGroup>
        </CheckableField>
      )}
      <CheckableField
        label={translate('ShowActivityInReports_SentenceCase')}
        checked={activityReport.checked}
        onChange={onActivityReportChange}
        data-test-id="activityReportField"
      >
        <Toggle
          id="activityReportToggle"
          onChecked={value => onActivityReportChange({ value })}
          disabled={!activityReport.checked}
          checked={activityReport.value}
        />
      </CheckableField>
      <CheckableField
        label={translate('Status')}
        checked={status.checked}
        onChange={onStatusChange}
        data-test-id="statusField"
      >
        <RadioGroup
          name="statusRadio"
          value={status.value.toString()}
          onChangeValue={value => onStatusChange({ value: Number(value) })}
        >
          <Radio
            disabled={!status.checked || (isGuest && !isAllowEnableGuestAccess)}
            value={PERSON_STATUS.ENABLED.toString()}
            label={translate('Enabled')}
            defaultChecked={false}
          />

          <Radio
            disabled={!status.checked}
            value={PERSON_STATUS.DISABLED.toString()}
            label={translate('Disabled')}
            defaultChecked={false}
          />
        </RadioGroup>
      </CheckableField>
      {isUserIpAccessListAllowed && (
        <CheckableField
          label={translate('IpAccessList_SentenceCase')}
          checked={ipAccessList.checked}
          onChange={onIpAccessListChange}
          data-test-id="ipAccessListField"
        >
          <IpAccessList
            disabled={!ipAccessList.checked}
            userIpAccessList={ipAccessList.value.userIpAccessList}
            remoteIp={remoteIp}
            ipAccessFrom={ipAccessList.value.ipAccessFrom}
            errors={errors.ipAccessList}
            onChange={({ key, value }) => {
              onIpAccessListChange({
                value: {
                  ...ipAccessList.value,
                  [key]: value,
                },
              });
            }}
          />
        </CheckableField>
      )}
    </>
  );
};

export default PropertyForm;
