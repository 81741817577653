// @flow

import { links } from 'app/links';
import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import type { RouterHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Breadcrumb, Button, Message } from '@ansarada/ace-react';
import classnames from 'classnames';
import Link from 'app/components/aceReactRouterLink/AceReactRouterLink';
import { PageTitle } from 'app/components/typography/Typography';
import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import RoomLayout from 'app/layout/roomLayout';
import type { SessionType } from 'app/types/globalTypes';
import type { ViewData } from 'app/pages/teams/teamsTypes';
import { getLocationProps, logout } from 'app/uri/url';
import { getDialog } from 'app/utils/getDialog';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import { EmailSection, RecipientsSection } from 'app/components/emailForm/EmailForm';
import type { QueryType, PeopleWithRole } from 'app/components/emailForm/EmailFormTypes';
import AdditionalRecipients from 'app/components/emailForm/additionalRecipients/AdditionalRecipients';
import * as verifyEmailDialogActions from 'app/pages/teams/verifyEmailDialog/verifyEmailDialogActions';
import { DataRoomType } from 'app/types/dataRoomTypes';
import type { EmailPeopleState } from './emailPeopleTypes';
import { sortByNameEmail, getEmailAddresses } from '../sendInvitation/sendInvitationHelper';
import styles from '../sendInvitation/SendInvitationPage.scss';

type OwnProps = {|
  location: Object,
|};

type EmailPeoplePageType = {|
  dispatch: AppDispatch,
  session: SessionType,
  locationProps: QueryType,
  people: PeopleWithRole,
  history: RouterHistory,
  isEmailVerified: boolean,
  viewData: ViewData,
|} & EmailPeopleState;

export class EmailPeoplePage extends React.Component<EmailPeoplePageType> {
  componentDidMount() {
    const { dispatch, isEmailVerified, session } = this.props;

    if (!session.isPaidCustomer) {
      logout(session);
    }

    if (!isEmailVerified) {
      dispatch(verifyEmailDialogActions.toggleDialog(true));
    }

    dispatch({
      type: ACTION_TYPES.EMAIL_PEOPLE.DATA_FETCH_INITIAL,
      data: this.props.locationProps,
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: ACTION_TYPES.EMAIL_PEOPLE.RESET,
    });
  }

  onAttachmentSelected = (files: Array<File>) => {
    this.props.dispatch({
      type: ACTION_TYPES.EMAIL_PEOPLE.UPLOAD_ATTACHMENT,
      data: files,
    });
  };

  onRemoveAttachment = (attachmentId: number) => {
    this.props.dispatch({
      type: ACTION_TYPES.EMAIL_PEOPLE.REMOVE_ATTACHMENT,
      data: attachmentId,
    });
  };

  onValidate = (values: Object) => {
    const errors = {};
    if (!values.subject) {
      errors.subject = translate('EmailPeopleSubjectRequiredSentenceCase');
    }
    if (!values.message) {
      errors.message = translate('MessageEmpty');
    }
    if (!values.selectedEmails && !values.additionalRecipients) {
      errors.selectedEmails = translate('Required');
    }
    if (values.additionalRecipients) {
      const recipients = this.transformAdditionalRecipients(values.additionalRecipients)
        .split(',')
        .filter(email => email);
      if (recipients.find(recipient => !isEmail(recipient)))
        errors.additionalRecipients = translate('InvalidEmailAddress');
    }
    return errors;
  };

  transformAdditionalRecipients = (emails: string) => {
    return emails.replace(/(\r\n|\r|\n|\s+)/g, ',').replace(/,{2,}/g, ',');
  };

  onSubmit = (values: Object) => {
    const { dispatch, attachments, message: defaultMessage } = this.props;
    const { subject, message, selectedPeopleIds, additionalRecipients, importance } = values;
    dispatch({
      type: ACTION_TYPES.EMAIL_PEOPLE.SUBMIT_EMAIL,
      data: {
        peopleIds: selectedPeopleIds,
        additionalRecipients: this.transformAdditionalRecipients(additionalRecipients)
          .split(',')
          .filter(email => email),
        email: {
          importance,
          subject,
          attachmentIds: attachments.map(attachment => attachment.id),
          body: message || defaultMessage,
        },
      },
    });
  };

  render() {
    const {
      attachments,
      hasBeenSent,
      history,
      importance,
      isLoaded,
      isSubmitting,
      isUploadingAttachment,
      locationProps,
      message,
      subject,
      teams,
      isEmailVerified,
      viewData,
      session,
    } = this.props;
    const isIrlm = session.dataRoomType === DataRoomType.Irlm;

    const { translationTagNameNonAdminSide, translationTagNameAdminSide } = this.props.session;

    const people = sortByNameEmail(this.props.people);

    const isGuest = locationProps.isGuest === '1';

    const teamTypeTranslateKey = isGuest
      ? translationTagNameNonAdminSide
      : translationTagNameAdminSide;

    const teamTypeLabel = translate(teamTypeTranslateKey);

    const pageTitle = isIrlm
      ? translate('EmailPeople')
      : // $FlowFixMe flow cannot be sure that the team type translation key is in the session
        translate(`${teamTypeTranslateKey}_EmailHeading`);

    const breadcrumbItems = [
      {
        href: '',
        text: '',
      },
      {
        text: teamTypeLabel,
        onClick() {
          history.push(links.teams(isGuest));
        },
      },
      {
        href: '#',
        text: pageTitle,
      },
    ];

    const successMessage = (
      <>
        <h2 className={styles.successMessage} data-test-id="successMessage">
          {translate('EmailSuccessMessage')}
        </h2>
        <Link to={`/room/teams?isGuest=${isGuest ? 1 : 0}`}>{translate('BackToTeamsPage')}</Link>
      </>
    );

    const form = (
      <Formik
        initialValues={{
          subject,
          importance,
          message,
          people,
          selectedEmails: '',
          additionalRecipients: '',
        }}
        validate={this.onValidate}
        onSubmit={this.onSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          ...formik
        }) => {
          const focusFirstError =
            !formik.isValidating && formik.isSubmitting
              ? ['subject', 'message', 'selectedEmails'].find(k => errors[k])
              : undefined;
          return (
            <>
              <EmailSection
                title={translate('Email')}
                showImportance
                showHintMessage={!isIrlm}
                importance={values.importance}
                onImportanceUpdate={value => {
                  setFieldValue('importance', value);
                }}
                messageErrors={touched.message && errors.message}
                attachments={attachments}
                onAttachmentSelected={this.onAttachmentSelected}
                onRemoveAttachment={this.onRemoveAttachment}
                isUploadingAttachment={isUploadingAttachment}
                message={message}
                messageHint={translate('EmailMessage')}
                onEditorUpdate={(html: string) => {
                  setFieldValue('message', html);
                  setFieldTouched('message', true);
                }}
              />

              <Message>{translate('RecipientsOfEmailNote_Gen2')}</Message>

              <RecipientsSection
                errors={touched.selectedEmails && errors.selectedEmails}
                teams={teams}
                people={people}
                selectedPeopleIds={values.selectedPeopleIds || []}
                selectedEmails={values.selectedEmails}
                showFilter={false}
                onSelectPeopleIds={peopleIds => {
                  setFieldValue('selectedPeopleIds', peopleIds);
                  setFieldValue('selectedEmails', getEmailAddresses(people, peopleIds));
                  setFieldTouched('selectedEmails', true, !peopleIds.length);
                }}
                focus={focusFirstError === 'selectedEmails'}
                columnKeys={['email', 'disabled']}
                disabledRowSelectable
              />

              <AdditionalRecipients
                name="additionalRecipients"
                value={values.additionalRecipients}
                errors={touched.additionalRecipients && errors.additionalRecipients}
                onUpdate={value => {
                  setFieldValue('additionalRecipients', value);
                }}
              />

              <div className={styles.footer}>
                <Button
                  loading={isSubmitting}
                  variant="Primary"
                  data-test-id="sendEmailButton"
                  onClick={handleSubmit}
                  disabled={!isEmailVerified}
                >
                  {translate('Send')}
                </Button>
              </div>
            </>
          );
        }}
      />
    );

    return (
      <RoomLayout
        title={pageTitle}
        loadingContent={!isLoaded}
        data-test-id="emailPeoplePage"
        className={classnames(isIrlm && styles.noBreadcrumb)}
      >
        {!isIrlm && <Breadcrumb items={breadcrumbItems} />}
        <PageTitle withSpacing>{pageTitle}</PageTitle>

        {hasBeenSent ? successMessage : form}

        {getDialog(viewData.dialogType)}
      </RoomLayout>
    );
  }
}

export function mapStateToProps(state: AppStateType, ownProps: OwnProps) {
  const {
    attachments,
    hasBeenSent,
    importance,
    isLoaded,
    isSubmitting,
    isUploadingAttachment,
    message,
    people,
    selectedPeopleIds,
    subject,
    teams,
  } = state.emailPeople;

  return {
    attachments,
    hasBeenSent,
    importance,
    isLoaded,
    isSubmitting,
    isUploadingAttachment,
    locationProps: getLocationProps(ownProps.location),
    isEmailVerified: state.verifyEmail.isEmailVerified,
    viewData: state.teams.viewData,
    message,
    people,
    selectedPeopleIds,
    session: state.session,
    subject,
    teams,
  };
}

export default connect<AppStateType, _, *, *>(mapStateToProps)(EmailPeoplePage);
