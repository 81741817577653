// @flow

import translate from 'app/i18n/translate';
import { errorValidate } from 'app/utils/error';
import { IP_FROM } from 'app/pages/teams/teamsConstants';

const handleEmailData = (data: Object) =>
  data.email || data.email === ''
    ? Object.assign({}, data, {
        emails: data.email === '' ? [] : [data.email],
      })
    : data;

export const ipToNumber = (strIP: string): number =>
  strIP
    .split('.')
    .reverse()
    .map(segment => Number(segment))
    .reduce((sum, segmentValue, index) => sum + segmentValue * 256 ** index);

export const isIpAddress = (strIP: string) => {
  const regExpIp = /^(\d{1,3}\.){3}\d{1,3}$/;
  if (regExpIp.test(strIP)) {
    return strIP.split('.').every(segment => Number(segment) <= 255);
  }
  return false;
};

export const purifyTag = (text: string = '') => text.replace(/<\/?\w*\d*>/gi, '');

const isValidIpRangeList = (
  ctl: string,
  ipAddressInvalidMessage: string,
  ipAddressInvalidRangeMessage: string,
  ipLimitRangeMessage: string,
) => {
  const aIpRangeLine = ctl.split('\n');
  let ipRangeLine: string = '';
  let errorMessage: string = '';
  const DESCRIPTION_MAX_LEN = 100; // limit ip range descriptions to 100 chars

  for (let i = 0; i < aIpRangeLine.length; i += 1) {
    ipRangeLine = aIpRangeLine[i].replace(/\r/g, '').trim();

    if (ipRangeLine.length > 0) {
      const aIpRange = ipRangeLine.split(',');
      const ipRange = aIpRange[0].trim();
      const ipRangeDescription = ipRangeLine.substr(aIpRange[0].length + 1).trim();

      const aIp = ipRange.split('-');
      const ipFrom = aIp[0].trim();
      const ipTo = ipRange.substr(aIp[0].length + 1).trim();

      if (!isIpAddress(ipFrom)) {
        // check start ip
        errorMessage = ipAddressInvalidMessage.replace('[IpAddress]', ipFrom);
      } else if (aIp.length > 1 && !isIpAddress(ipTo)) {
        // check end ip
        errorMessage = ipAddressInvalidMessage.replace('[IpAddress]', ipTo);
      } else if (aIp.length > 1 && ipToNumber(ipFrom) > ipToNumber(ipTo)) {
        // check that end ip is greater than or equal to the start ip
        errorMessage = ipAddressInvalidRangeMessage.replace('[IpRange]', ipRange);
      } else if (ipRangeDescription.length > DESCRIPTION_MAX_LEN) {
        // check that description is not too long
        errorMessage = ipLimitRangeMessage
          .replace('[IpRange]', ipRange)
          .replace('[NumOfChars]', DESCRIPTION_MAX_LEN.toLocaleString())
          .replace('[UsedNumOfChars]', ipRangeDescription.length.toLocaleString());
      }

      if (errorMessage) break;
    }
  }
  return errorMessage;
};

type IpAccessList = {
  ipAccessFrom: $Values<typeof IP_FROM>,
  userIpAccessList: string,
};
export const validateIpList = ({ ipAccessFrom, userIpAccessList }: IpAccessList) => {
  let errors;
  if (ipAccessFrom === IP_FROM.SPECIFIED) {
    if (!userIpAccessList || !userIpAccessList.trim()) {
      errors = errorValidate(errors, translate('IpAccessListCanNotBeEmpty'));
    } else {
      const IP_ACCESS_LIST_MAX_LENGTH = 3000;
      if (userIpAccessList.length > IP_ACCESS_LIST_MAX_LENGTH) {
        errors = errorValidate(
          errors,
          translate('IpUserAccessListLimit', {
            MaxNumberOfCharacters: IP_ACCESS_LIST_MAX_LENGTH.toLocaleString(),
            EnteredNumberOfCharacters: userIpAccessList.length.toLocaleString(),
          }),
        );
      }
      const errorMessage = isValidIpRangeList(
        userIpAccessList,
        translate('IpAddressInvalidIpAccessList'),
        translate('IpAddressInvalidRangeIpAccessList'),
        translate('IpLimitRangeIpAccessList'),
      );
      if (errorMessage) {
        errors = errorValidate(errors, errorMessage);
      }
    }
  }
  return errors;
};

export const validatePeopleForm = (data: Object) => {
  const newData = handleEmailData(data);
  const emailsLen = newData.emails.length;
  const errors = {};

  if (!emailsLen) {
    errors.emails = errorValidate(errors.emails, translate('EmailCanNotBeEmpty'));
  } else {
    newData.emails.forEach(email => {
      if (!email.includes('@')) {
        let msg;
        if (emailsLen === 1) {
          msg = translate('InvalidEmailAddress');
        } else {
          msg = translate('EmailsNotValid', { Email: email });
        }
        errors.emails = errorValidate(errors.emails, msg);
      }
    });
  }
  const ipListError = validateIpList(newData);
  if (ipListError) {
    errors.userIpAccessList = ipListError;
  }
  return Object.keys(errors).length ? errors : undefined;
};
