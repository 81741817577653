// @flow

import * as React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import translate from 'app/i18n/translate';
import WarningDialog from 'app/components/warningDialog/WarningDialog';

export const NavigationWarning = () => (
  <NavigationPrompt when>
    {({ onConfirm, onCancel }) => (
      <WarningDialog
        visible
        header={translate('NavigateAwayWarning')}
        message={translate('NavigateAwayWarningMessage')}
        confirmText={translate('NavigateAwayConfirm')}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )}
  </NavigationPrompt>
);
