// @flow
import { Panel, Icon, icon, LoadingIndicator } from '@ansarada/ace-react';
import * as React from 'react';
import { connect } from 'react-redux';
import toastActions from 'app/components/toast-message/actions';
import translate from 'app/i18n/translate';
import type { SessionType } from 'app/types/globalTypes';
import type { AppDispatch } from 'app/types/appStateTypes';
import { resendVerificationLink } from 'app/services/person';
import styles from './verifyEmailBanner.scss';

export type VerifyEmailBannerType = {
  session: SessionType,
  dispatch: AppDispatch,
};

export const VerifyEmailBanner = (props: VerifyEmailBannerType) => {
  const [isResendingEmail, setIsResendingEmail] = React.useState(false);
  const { session, dispatch } = props;

  const handleResendEmailVerification = async () => {
    if (isResendingEmail) {
      return;
    }

    setIsResendingEmail(true);
    const result = await resendVerificationLink(session);
    setIsResendingEmail(false);

    const hasError = result?.Errors?.length > 0;
    const toastMessage = hasError ? result.Errors[0].Title : translate('EmailHasBeenSent');
    const toastStatus = hasError ? 'none' : 'success';

    toastActions(dispatch).add({
      id: 'resend-verification-email',
      message: toastMessage,
      status: toastStatus,
      autoHide: true,
      contentType: 'TextContent',
    });
  };

  return (
    <Panel className={styles.verifyEmailBanner}>
      <Icon glyph={icon.Glyphs.StatusSuccess} size="XLarge" />
      <p className={styles.verifyEmailText}>{translate('VerifyYourEmailAddressToInviteUsers')}</p>
      <button className={styles.resendEmailButton} onClick={handleResendEmailVerification}>
        {translate('ResendEmail')}
      </button>
      {isResendingEmail && (
        <LoadingIndicator dark size={20} className={styles.sendingEmailIndicator} />
      )}
    </Panel>
  );
};

const mapStateToProps = (state: Object): Object => ({
  session: state.session,
});

export default connect<_, _, *, *>(mapStateToProps)(VerifyEmailBanner);
