// @flow

import type { PeopleWithRole } from 'app/components/emailForm/EmailFormTypes';
import type { Teams } from 'app/types/teamsTypes';
import * as React from 'react';
import { FormGrid, FormGridCell, FormGridRow, Option, Select } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import PeopleSelector from 'app/components/peopleSelector/PeopleSelector';
import styles from 'app/pages/sendInvitation/SendInvitationPage.scss';
import { FILTER_TYPES } from 'app/components/emailForm/constants';
import TextCopy from 'app/components/emailForm/textCopy/TextCopy';
import type { ColumnKeyType } from 'app/components/peopleSelector/peopleSelectorHelper';
import recipientsSectionStyles from './RecipientsSection.scss';

type Props = {|
  errors: Array<string>,
  teams: Teams,
  people: PeopleWithRole,
  selectedPeopleIds: Array<number>,
  selectedEmails: string,
  showFilter: true | false,
  onFilterChange?: Function,
  onSelectPeopleIds: (Array<number>) => void,
  focus: boolean,
  columnKeys?: Array<ColumnKeyType>,
  disabledRowSelectable?: boolean,
  showHintMessage?: boolean,
|};

class RecipientsSection extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.focus) {
      if (this.fieldset && this.fieldset.scrollIntoView) {
        this.fieldset.scrollIntoView();
      }
    }
  }

  fieldset: ?HTMLFieldSetElement;

  render() {
    const {
      errors,
      showFilter,
      onFilterChange,
      teams,
      people,
      selectedPeopleIds,
      onSelectPeopleIds,
      selectedEmails,
      columnKeys,
      disabledRowSelectable,
      showHintMessage = true,
    } = this.props;

    return (
      <fieldset
        ref={ref => {
          this.fieldset = ref;
        }}
      >
        <legend data-test-id="selectedEmails">
          <h3 className={styles.sectionTitle}>{translate('Recipients')}</h3>
        </legend>

        {errors && (
          <div className={styles.errorForRecipients} data-test-id="recipientsError">
            {errors}
          </div>
        )}

        {showFilter && (
          <FormGrid>
            <FormGridRow>
              <FormGridCell
                labelText={translate('Filter')}
                hint={showHintMessage ? translate('OnlyEnabledUsersCanBeInvitedSec2') : undefined}
              >
                <Select
                  onChangeValue={value => {
                    if (onFilterChange) onFilterChange(value);
                  }}
                  data-test-id="filter"
                  className={recipientsSectionStyles.statusFilter}
                >
                  <Option value={FILTER_TYPES.ALL} text={translate('All')} />
                  <Option
                    value={FILTER_TYPES.LOGGED_IN_TO_DATE}
                    text={translate('LoggedInToDate_SentenceCase')}
                  />
                  <Option
                    value={FILTER_TYPES.NEVER_LOGGED_IN}
                    text={translate('NeverLoggedIn_SentenceCase')}
                  />
                  <Option value={FILTER_TYPES.VERIFIED} text={translate('Verified_Users_')} />
                  <Option
                    value={FILTER_TYPES.NOT_VERIFIED}
                    text={translate('NotVerified_Users__SentenceCase')}
                  />
                  <Option value={FILTER_TYPES.INVITED} text={translate('Invited_Users_')} />
                  <Option
                    value={FILTER_TYPES.NOT_INVITED}
                    text={translate('NotInvited_Users__SentenceCase')}
                  />
                </Select>
              </FormGridCell>
            </FormGridRow>
          </FormGrid>
        )}

        <PeopleSelector
          teams={teams}
          people={people}
          selectedKeys={selectedPeopleIds}
          onSelect={onSelectPeopleIds}
          columnKeys={columnKeys}
          disabledRowSelectable={disabledRowSelectable}
        />

        <TextCopy text={selectedEmails} triggerText={translate('CopyEmailsOfSelectedUsersSec2')} />
      </fieldset>
    );
  }
}

export default RecipientsSection;
