// @flow

import { Option, Select } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { OptionalCheckedValue } from 'app/pages/editPeople/editPeopleTypes';
import styles from 'app/pages/editPeople/propertyForm/propertyForm.scss';
import * as React from 'react';
import CheckableField from './CheckableField';

type PropTypes = {
  label: string,
  value: string,
  disabled?: boolean,
  checked: boolean,
  onChange: (OptionalCheckedValue<*>) => void,
  children?: React.ChildrenArray<React.Element<typeof Option> | void | false>,
  'data-test-id'?: string,
  helpText?: string,
  helpUrl?: string,
  error?: string,
  className?: string,
};

const CheckableSelectField = (props: PropTypes) => {
  const {
    label,
    disabled = false,
    checked,
    onChange,
    'data-test-id': testId,
    children,
    error,
    className,
  } = props;
  return (
    <CheckableField
      label={label}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      data-test-id={testId}
      helpText={props.helpText}
      helpUrl={props.helpUrl}
      error={error}
      className={className}
    >
      <Select
        className={styles.select}
        value={props.value}
        onChangeValue={value => onChange({ value })}
        status={error ? 'Bad' : undefined}
        data-ansarada-ccd={label === translate('Team') || undefined}
      >
        <Option value="" text={translate('None')} />
        {children}
      </Select>
    </CheckableField>
  );
};

export default CheckableSelectField;
