// @flow
import React from 'react';
import { Tooltip, Message, Icon, icon, Button, FormattedContentBlock } from '@ansarada/ace-react';
import classnames from 'classnames';

import translate from 'app/i18n/translate';

import style from './licenseMessage.scss';

type Props = {
  isDialog: boolean,
  licences: {
    consumed: number,
    allowed: ?number,
  },
};

type LicenceProps = {
  isDialog: boolean,
  licences: {
    consumed: number,
    allowed: ?number,
  },
};

const LicenseMessage = (props: LicenceProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { consumed, allowed } = props.licences;
  const licencesOver = consumed >= (allowed || 0);
  const isIntercomAvailable = typeof window.Intercom === 'function';
  return (
    <Message style={{ padding: '7px 11px' }}>
      <div
        className={classnames({
          [style.cautionMessageWrapper]: true,
          [style.isDialog]: props.isDialog,
        })}
      >
        <div>{translate('LicenceSectionPlan')}</div>
        <div
          className={classnames({
            [style.availableLicenceLimit]: true,
            [style.reachedLicenceLimit]: licencesOver,
            [style.isDialogLicense]: props.isDialog,
          })}
        >
          {consumed}
          <span className={style.licenceSeperator}>/</span>
          {allowed}
          <span className={style.licenceTooltip}>
            <Tooltip
              id="tooltip"
              open={isOpen}
              position="Right"
              text={
                // $FlowFixMe Only string is accepted which is not same with documentation
                <FormattedContentBlock>
                  {translate('LicenceTooltip')}
                  <ul>
                    <li>{translate('LicenceTooltipStep1')}</li>
                    <li>{translate('LicenceTooltipStep2')}</li>
                    <li>{translate('LicenceTooltipStep3')}</li>
                  </ul>
                </FormattedContentBlock>
              }
              onTrigger={open => setIsOpen(open)}
            >
              <Icon glyph={icon.Glyphs.StatusInformation} />
            </Tooltip>
          </span>
        </div>
        <div className={style.licencePack}>{translate('LicencePack')}</div>
        {licencesOver && (
          <Message
            variant="error"
            className={classnames({
              [style.cautionErrorMessage]: true,
              [style.isDialogErrorMessage]: props.isDialog,
            })}
          >
            <div>{translate('LicenceErrorMessage')}</div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Button
              variant="Link"
              onClick={() => {
                if (isIntercomAvailable) {
                  window.Intercom('show');
                } else {
                  window.open('https://www.ansarada.com/contact', '_blank');
                }
              }}
            >
              {translate('LicenceErrorHelp')}
              {translate('LicenceChatHelp')}
            </Button>
          </Message>
        )}
        <div className={style.licencePrompt}>
          <Icon
            className={style.licencePromptIcon}
            glyph={icon.Glyphs.DocumentEmail}
            size="XLarge"
          />
          {translate('LicencePrompt')}
        </div>
      </div>
    </Message>
  );
};

const LicenseMessageVal = (props: Props) => {
  return <LicenseMessage {...props} />;
};

export default LicenseMessageVal;
