"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleAlwaysInviteGuestUpgradeDialog = exports.toggleTenderFreemiumInviteGuestUpgradeDialog = void 0;
var constants_1 = require("app/constants");
var toggleTenderFreemiumInviteGuestUpgradeDialog = function (dispatch, open) {
    dispatch({
        type: constants_1.ACTION_TYPES.TEAMS.TOGGLE_TENDER_FREEMIUM_INVITE_GUEST_UPGRADE_DIALOG,
        data: { open: open },
    });
};
exports.toggleTenderFreemiumInviteGuestUpgradeDialog = toggleTenderFreemiumInviteGuestUpgradeDialog;
var toggleAlwaysInviteGuestUpgradeDialog = function (dispatch, open) {
    dispatch({ type: constants_1.ACTION_TYPES.TEAMS.TOGGLE_ALWAYS_INVITE_GUEST_UPGRADE_DIALOG, data: { open: open } });
};
exports.toggleAlwaysInviteGuestUpgradeDialog = toggleAlwaysInviteGuestUpgradeDialog;
