// @flow

import * as React from 'react';
import { Button, Dialog, FormattedContentBlock } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import type { RegistrantType } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationTypes';

export type PropsType = {
  declineRegistration: () => void,
  declineRegistrationLoading: boolean,
  registrant: RegistrantType,
  setDeclineDialogVisible: (RegistrantType, boolean) => void,
};

const DeclineRegistrationDialog = ({
  declineRegistration,
  declineRegistrationLoading,
  registrant,
  setDeclineDialogVisible,
}: PropsType) => {
  const closeDialog = () => setDeclineDialogVisible(registrant, false);

  return (
    <Dialog
      title={translate('DeclineRegistration')}
      onClose={closeDialog}
      footerPrimaryActions={[
        <Button
          key="cancel"
          onClick={closeDialog}
          data-test-id="cancel"
          disabled={declineRegistrationLoading}
        >
          {translate('Cancel')}
        </Button>,
        <Button
          variant="Caution"
          key="decline"
          onClick={declineRegistration}
          data-test-id="decline-registration"
          loading={declineRegistrationLoading}
        >
          {translate('Decline')}
        </Button>,
      ]}
    >
      <FormattedContentBlock data-ansarada-ccd>
        {translate('AreYouSureToDeclineRegistration', {
          registrant: registrant.Email,
        })}
      </FormattedContentBlock>
    </Dialog>
  );
};

export default DeclineRegistrationDialog;
