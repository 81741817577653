// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import {
  TableLegacy,
  TableHeadLegacy,
  TableBodyLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
} from '@ansarada/ace-react';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SessionType } from 'app/types/globalTypes';
import Person from './person/Person';

type PeoplePropsType = {
  people?: Array<*>,
  userTypes: Array<*>,
  dispatch: AppDispatch,
  permissions: PermissionsType,
  session: SessionType,
  isEmailVerified: boolean,
};

export const denormalisePerson = (person: Object, userTypes: Array<*>): Object => {
  const personUserType = userTypes.find(userType => userType.userTypeCode === person.userTypeCode);

  const personQnaRole =
    personUserType &&
    personUserType.qnaRoles &&
    personUserType.qnaRoles.find(qnaRole => qnaRole.Code === person.qnaRoleCode);

  return {
    ...person,
    userType: personUserType ? personUserType.userType : '',
    qnaRole: personQnaRole ? personQnaRole.Name : '',
  };
};

const PeopleList = (props: PeoplePropsType) => {
  const { dispatch, people, permissions, session, userTypes, isEmailVerified } = props;

  return (
    <TableLegacy>
      <TableHeadLegacy className="ace-assistive">
        <TableRowLegacy>
          <TableHeaderLegacy>User</TableHeaderLegacy>
          <TableHeaderLegacy>Status</TableHeaderLegacy>
          {session.qnaEnabled && <TableHeaderLegacy>Roles</TableHeaderLegacy>}
          <TableHeaderLegacy>Logins</TableHeaderLegacy>
          <TableHeaderLegacy>
            <span className="ace-assistive">Actions</span>
          </TableHeaderLegacy>
        </TableRowLegacy>
      </TableHeadLegacy>

      <TableBodyLegacy>
        {!!people &&
          people.map(person => (
            <Person
              key={person.personId}
              dispatch={dispatch}
              person={denormalisePerson(person, userTypes)}
              permissions={permissions}
              session={session}
              isEmailVerified={isEmailVerified}
            />
          ))}
      </TableBodyLegacy>
    </TableLegacy>
  );
};

export default PeopleList;
