// @flow

import type { People } from 'app/types/personTypes';
import * as R from 'ramda';
import translate from 'app/i18n/translate';
import type { FormType } from 'app/pages/editPeople/editPeopleTypes';
import type { PeopleWithRole } from 'app/components/emailForm/EmailFormTypes';
import { validateIpList } from 'app/pages/teams/peopleFormDialog/peopleFormDialogHelpers';
import { IP_FROM } from 'app/pages/teams/teamsConstants';
import { parseIpAccessListFromString } from 'app/services/person';
import { makeTeamsItems } from 'app/pages/teams/teamsHelpers';
import type { Teams } from 'app/types/teamsTypes';

export const validateForm = ({
  roomRole,
  qnaRole,
  subTeam,
  activityReport,
  termsOfAccess,
  status,
  ipAccessList,
  selectedPeopleIds,
}: FormType) => {
  const errors = {};
  if (roomRole.checked && !roomRole.value) {
    errors.roomRole = translate('SelectUserType');
  }
  if (subTeam.checked && !subTeam.value) {
    errors.team = translate('SelectTeam');
  }
  if (ipAccessList.checked) {
    const ipListError = validateIpList(ipAccessList.value);
    if (ipListError) {
      errors.ipAccessList = ipListError;
    }
  }
  if (
    !roomRole.checked &&
    !qnaRole.checked &&
    !subTeam.checked &&
    !termsOfAccess.checked &&
    !activityReport.checked &&
    !status.checked &&
    !ipAccessList.checked
  ) {
    errors.properties = translate('Required');
  }
  if (selectedPeopleIds.length === 0) {
    errors.selectedPeopleIds = translate('Required');
  }
  return errors;
};

type FormDataWithPeople = FormType & {
  people: PeopleWithRole,
  teams: Teams,
};
export const buildPayloadData = (formData: FormDataWithPeople): People => {
  const {
    roomRole,
    qnaRole,
    subTeam,
    termsOfAccess,
    activityReport,
    status,
    ipAccessList,
    selectedPeopleIds,
    people,
    teams,
  } = formData;
  const extendProperties = {};

  if (roomRole.checked && roomRole.value) {
    extendProperties.userTypeCode = roomRole.value;
  }
  if (qnaRole.checked) {
    extendProperties.qnaRoleCode = qnaRole.value === '' ? null : qnaRole.value;
  }
  if (subTeam.checked && subTeam.value) {
    const selectedTeam =
      makeTeamsItems(teams).find(team => team.subteamId === Number(subTeam.value)) || {};
    extendProperties.teamId = Number(selectedTeam.teamId);
    extendProperties.subteamId = Number(selectedTeam.subteamId);
  }
  if (termsOfAccess.checked) {
    extendProperties.termsOfAccess = termsOfAccess.value;
  }
  if (activityReport.checked) {
    extendProperties.excludeFromReports = !activityReport.value;
  }
  if (status.checked) {
    extendProperties.disabled = status.value;
  }
  if (ipAccessList.checked) {
    const { ipAccessFrom, userIpAccessList } = ipAccessList.value;
    extendProperties.ipAccessList =
      ipAccessFrom === IP_FROM.SPECIFIED && userIpAccessList
        ? parseIpAccessListFromString(userIpAccessList)
        : [];
  }
  return people
    .filter(person => selectedPeopleIds.includes(person.personId))
    .map(person => {
      return {
        ...R.omit(['qnaRole', 'userType'], person),
        ...extendProperties,
      };
    });
};
