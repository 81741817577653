"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetSubscriptionConfigurationByAsset = void 0;
var react_query_1 = require("@tanstack/react-query");
var react_redux_1 = require("react-redux");
var sessionReducer_1 = require("app/reducers/sessionReducer");
var query_key_factory_1 = require("app/config/query-key-factory");
var subscriptions_1 = require("app/graphql/queries/subscriptions");
var useGraphQLClient_1 = require("./useGraphQLClient");
var useGetSubscriptionConfigurationByAsset = function () {
    var _a;
    var client = (0, useGraphQLClient_1.useGraphQLClient)();
    var session = (0, react_redux_1.useSelector)(sessionReducer_1.selectSession);
    var assetId = (_a = session === null || session === void 0 ? void 0 : session.dataroomId) === null || _a === void 0 ? void 0 : _a.toString();
    var assetType = 'DataRoom';
    return (0, react_query_1.useQuery)({
        queryKey: query_key_factory_1.assetKeys.subscriptionConfigurationByAsset,
        queryFn: function () {
            return client(subscriptions_1.GET_SUBSCRIPTION_CONFIGURATION_BY_ASSET, {
                assetId: assetId,
                assetType: assetType,
            });
        },
        enabled: !!assetId,
    });
};
exports.useGetSubscriptionConfigurationByAsset = useGetSubscriptionConfigurationByAsset;
