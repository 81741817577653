export function setAppcuesTeamVariables(useSod) {
  (async () => {
    try {
      const appcuesUser = await window.Appcues.user();
      window.Appcues.identify(appcuesUser.userId, { appcuesCurrentTeamUsesSod: useSod });
    } catch (error) {
      // ...
    }
  })();
}

export function showAppcuesFlow(disableDownloadWhenSavingDenied, useSod) {
  const appcuesFlowEditSecurityHelpDetails = '8d1af158-3519-4c73-9755-9d6bcfe18b8e';
  const appcuesFlowEditSecurityHelpDetailsNoDownload = 'cc9cc52a-7bc2-4ca4-8771-133b625b46aa';
  const appcuesFlowEditSecurityHelpDetailsSod = 'cd71a4f5-c063-46c4-aadb-24caf94a88b4';
  const appcuesFlowEditSecurityHelpDetailsSodNoDownload = 'e36f1593-2bf0-4c3b-8c8e-ac21506a1ee6';

  if (disableDownloadWhenSavingDenied) {
    window.Appcues.show(
      useSod
        ? appcuesFlowEditSecurityHelpDetailsSodNoDownload
        : appcuesFlowEditSecurityHelpDetailsNoDownload,
    );
  } else {
    window.Appcues.show(
      useSod ? appcuesFlowEditSecurityHelpDetailsSod : appcuesFlowEditSecurityHelpDetails,
    );
  }
}
