// @flow

import * as React from 'react';
import {
  Tree as ACETree,
  TreeItem as ACETreeItem,
  TreeHeader as ACETreeHeader,
  TreeHeaderGroup as ACETreeHeaderGroup,
  Icon,
  icon,
  Link as ACELink,
} from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { SECURITY_DIALOG_TYPES } from 'app/constants';
import { getFeature2Data } from 'app/utils/feature2';
import styles from './treeHeader.scss';

export const TreeHeader = (props: {
  documentSecurityEnabled: boolean,
  setDialogType: Function,
  toggleDialog: Function,
  showAppcuesFlow?: Function,
}) => {
  const { documentSecurityEnabled, setDialogType, toggleDialog, showAppcuesFlow } = props;
  const triggerAppcuesFlowForHelp = getFeature2Data(
    'dil-946-document-security-page-trigger-appcues-flow-for-help',
  );

  const onHeaderClicked = () => {
    if (showAppcuesFlow !== undefined && triggerAppcuesFlowForHelp) {
      showAppcuesFlow();
    } else {
      setDialogType(SECURITY_DIALOG_TYPES.SECURITY_HELPER);
      toggleDialog(translate('SecurityControls'));
    }
  };

  return (
    <ACETree>
      <ACETreeHeader>
        <ACETreeHeaderGroup>
          {[
            <ACETreeItem key={1} className={styles.headerCell} />,

            <ACETreeItem key={2} className={`${styles.headerCell} ${styles.headerAccess}`}>
              {translate('Access')}
            </ACETreeItem>,

            documentSecurityEnabled ? (
              <ACETreeItem
                key={3}
                className={`${styles.headerCell} ${styles.headerSecurityControls}`}
              >
                <ACELink variant="Subtle" onClick={onHeaderClicked}>
                  {translate('SecurityControls')}
                </ACELink>
                <Icon
                  className={styles.securityHelperIcon}
                  glyph={icon.Glyphs.StatusInformation}
                  text={translate('SecurityControls')}
                  onClick={onHeaderClicked}
                />
              </ACETreeItem>
            ) : (
              undefined
            ),
            <ACETreeItem key={4} className={`${styles.headerCell} ${styles.headerWatermark}`}>
              {translate('Watermark')}
            </ACETreeItem>,
          ]
            // This filters out the undefined item which is not accepted by ACETreeHeaderGroup
            .filter(item => !!item)}
        </ACETreeHeaderGroup>
      </ACETreeHeader>
    </ACETree>
  );
};

export default TreeHeader;
