// @flow

import * as React from 'react';
import { Asset as ACEAsset, asset, Icon, icon, keyTypes, TruncateText } from '@ansarada/ace-react';
import cx from 'classnames';
import type { FolderActionsType } from 'app/pages/security/securityTypes';
import styles from './styles.scss';

export const getGlyph = (expandable: boolean, expanded: boolean) => {
  if (!expandable) {
    return icon.Glyphs.ObjectsEmptyFolder;
  }
  return expanded ? icon.Glyphs.ControlExpanded : icon.Glyphs.ControlCollapsed;
};

export const Arrow = (props: {
  expanded: boolean,
  expandable: boolean,
  folderId: number,
  actions: FolderActionsType,
}) => (
  <Icon
    className="document-security-arrow"
    glyph={getGlyph(props.expandable, props.expanded)}
    text="Close"
    {...(props.expandable
      ? {
          onClick: () => props.actions.expandFolder(props.folderId),
        }
      : {})}
  />
);

type FolderProps = {
  expandable: boolean,
  expanded: boolean,
  name: string,
  folderId: number,
  actions: FolderActionsType,
  isDisabled: boolean,
};

class Folder extends React.Component<FolderProps> {
  shouldComponentUpdate(nextProps: FolderProps) {
    return nextProps.expanded !== this.props.expanded;
  }

  render() {
    return (
      <div className="dr-spa-tree-item ace-tree-item">
        <Arrow
          expanded={this.props.expanded}
          expandable={this.props.expandable}
          actions={this.props.actions}
          folderId={this.props.folderId}
        />
        <ACEAsset
          variant={asset.Variants.Directory}
          title={this.props.name}
          name={<TruncateText truncatePosition="Tail">{this.props.name}</TruncateText>}
          disabled={this.props.isDisabled}
          className={cx('transition-blur', styles.folderNameWrapper)}
          data-ansarada-ccd
          data-test-id="asset"
          tabIndex="0"
          onClick={() => {
            this.props.actions.expandFolder(this.props.folderId);
          }}
          onKeyPress={(event: SyntheticKeyboardEvent<*>) => {
            if (keyTypes.KeyEvent.isEnter(event)) {
              this.props.actions.expandFolder(this.props.folderId);
            }
          }}
        />
      </div>
    );
  }
}

export default Folder;
